
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import "../Servicescomponent/Services.css";

import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../Service/UserAuth";
import { database, functions } from "../../firebase";
import { formatNumber } from "../../Service/Utility";
import { useHistory } from "react-router-dom";
import { PaystackButton } from "react-paystack";
import firebase from "firebase";
import Inloader from "../../Inloader"
import { format } from "date-fns";
const walleturl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/getuserwallet";


function AnaData() {
    const [selectednetwork, setselectednetwork] = useState("mtn");
    const [image, setimage] = useState("/img/gotv.png");
    const [b, setb] = useState("");
    const [amount, setamount] =  useState(0)
    const [phoneea, setphone] = useState("");
    const { loginuser, userwallet } = useAuth();
    const [bundleprice, setbundleprice] = useState(0);
    const [loading, setloading] = useState(false);
    const [responspayload, setresponsepayload] = useState("");
    const [payload, setpayload] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [disable, setdisable]= useState(false)
    const history = useHistory();
    const [cableresponse, setcableresponse] = useState([]);
    const [cablerror, setcableerroresponse] = useState("");
    const [status, setstatus] = useState("");
    const [awaiting, setwaiting] = useState(false)
    const [showpayload, setShowpayload] = useState(false);
    const handleShowpayload = () => setShowpayload(true);
    const handleClosepayload = () => {
      setShowpayload(false);
      window.location.href ="/v1/airtime";
    };
 
    
  
 
    useEffect(() => {
      history.push('/login')
        if (selectednetwork === "mtn") {
          setimage("/img/mtn.png");
        } else if (selectednetwork === "glo") {
          setimage("/img/glo.png");
        } else if (selectednetwork === "airtel") {
          setimage("/img/airtel.png");
        } else if (selectednetwork === "etisalat") {
          setimage("/img/9mobile.png");
        } else {
          setimage("/img/mtn.png");
        }
      }, [image, selectednetwork, loginuser]);
    

    const handleparkage = (e) => {
        setb(e.target.value);
      };
    

    const ref = database.collection("_").doc().id;


  const date =   format(new Date(), "dd/MM/yyyy hh:mm a")
  const phone = phoneea 


const config = {
  reference: new Date().getTime().toString(),
  email: `mezuflex@gmail.com`,
  amount: amount * 100,
  publicKey: "pk_live_7c49d4df21fc6207a1ece0514092b34ad3f5bbee",
};

const handlePaystackSuccessAction = async (data) => {

  const verifyPaymentHandler = functions.httpsCallable(
    "verifyPaystackPayment"
  );

  if (!verifyPaymentHandler){
    console.log("nont run")
  }
  const value = {
    ref: data.reference,
    status: data.success,
    firstname: "Customer",
    lastname: "Mezuflex",
    email: "juliuskingsley1100@gmail.com",
    userId: "iu4WWdVD3GeRe9373RpDm25zN4M2",
    phone: "08165459398",
    amount: amount,
  };

    setwaiting(true)
  await verifyPaymentHandler(value).then(async (result) => {
 
    if (result === null || result === undefined) {
      return (
        <>
          <Inloader />
        </>
      );
    }
    if (result.data.data.status === "success") {

  
      const ref = database.collection("_").doc().id;
    
      const data1 = {
        id: ref,
        company: selectednetwork,
        customername: "no value",
        address: "no value",
        userID: "iu4WWdVD3GeRe9373RpDm25zN4M2",
        AccountNumber: phoneea,
        cablerror,
        walletbalance: 0,
        amount: amount,
        phone: phoneea,
        type: "Airtime",
        status: "Network Error",
        created: firebase.firestore.FieldValue.serverTimestamp(),
      };
      // make the payment for the user
      setresponsepayload("Connecting service ..");
      
      await axios
      .post(
        "https://us-central1-mazuflex-app.cloudfunctions.net/buyAirtim",
        {
          network: selectednetwork,
          amount: amount,
          number: phoneea,
          referrence: payload.referrence,
        }
      )
      .then(async (e) => {
      
        setdisable(true)
    
        if (e.data.status === "success") {
            setresponsepayload("finalizing connection. Please wait ")
          handleClose();
          setcableresponse(e.data.data);
          setstatus("success");
          handleShowpayload();
          const data = {
            id: ref,
            company: payload.network,
            customername: phoneea,
            address: "no value",
            userID: "iu4WWdVD3GeRe9373RpDm25zN4M2",
            AccountNumber: phoneea,
            walletbalance: 0,
            amount: amount,
            phone: phoneea,
            payloadres: e.data.data,
            isToday:true,
            type: "airtime",
            status: "success",
            created: firebase.firestore.FieldValue.serverTimestamp(),
          };
          await database
            .collection("Transaction")
            .doc(data.id)
            .set(data);
        } else {
          handleClose();
          setcableerroresponse(e.data.data);
          setstatus("error");
          handleShowpayload();
          await database
            .collection("Transaction")
            .doc(data1.id)
            .set(data1);
        }
        setdisable(false)
      })
      .catch((e) => {
       
        toast.error(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
      // Setpaymentpayload(result.data.data);
      // setshowvalue(true);
      handleClose();
    } else {
      // Setpayload(result.data.data);
      handleClose();
      handleShowpayload();
    }
  }).catch((e)=>{
    console.log(e)
    toast.error(e.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
  });

};

const handlePaystackCloseAction = () => {
  history.goBack();
};

const componentProps = {
  ...config,
 text: loading ? "Proccessing request" : "Pay with Paystack",
  onSuccess: (data) => handlePaystackSuccessAction(data),
  onClose: () => handlePaystackCloseAction(),
};

const handleverifier = async (e) => {
    e.preventDefault();
    if (isNaN(phone)){
        toast.error("Number Required", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    if (phoneea === "") {
      toast.error("Number Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const data = {
      network: selectednetwork,
      number: phone,
      amount:amount,
      referrence: ref,
    };

    setpayload(data);
    handleShow();
    setloading(false);
  };
  return (
    <div>
    <ToastContainer />
    <Modal
      centered
      backdrop="static"
      show={showpayload}
      onHide={handleClosepayload}
      animation={false}
    >
      <Modal.Body>
        {status === "error" ? (
          <>
            <div className="headersjdjd bg-danger">
              <h1>ERROR</h1>
            </div>
            <p>Transaction Details</p>
            <div className="infocontainer">
              <div className="inforcontenr">
                <div className="infotittle">Company :</div>
                <div className="infodetails">
                  {selectednetwork ? selectednetwork.toUpperCase() : ""}
                </div>
              </div>
             <div className="inforcontenr">
                <div className="infotittle">Number :</div>
                <div className="infodetails">{payload.number}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Amount :</div>
                <div className="infodetails">
                  &#8358; {formatNumber(payload.amount)}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Transaction Time :</div>
                <div className="infodetails">{date}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Message :</div>
                <div className="infodetails">
                  Transaction Failed, Please try again later
                </div>
              </div>
              <div className="d-flex">
                <Button
                  variant="danger"
                  className="w-100 m-1 "
                  type="submit"
                  disabled={disable}
                  onClick={handleClosepayload}
                >
                  back
                </Button>

                <Button className="w-100 m-1" type="submit" disabled={disable}>
                  Print
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="headersjdjd">
              <h1>SUCCESS</h1>
            </div>
            <p>Transaction Details</p>
            <div className="infocontainer">
              <div className="inforcontenr">
                <div className="infotittle">Company :</div>
                <div className="infodetails">
                  {selectednetwork ? selectednetwork.toUpperCase() : ""}
                </div>
              </div>

              

              <div className="inforcontenr">
                <div className="infotittle">Number :</div>
                <div className="infodetails">{payload.number}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Amount :</div>
                <div className="infodetails">
                  &#8358; {formatNumber(payload.amount)}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Transaction Time :</div>
                <div className="infodetails">{date}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Message :</div>
                <div className="infodetails">Transaction Successful</div>
              </div>
              <div className="d-flex">
                <Button
                  variant="danger"
                  className="w-100 m-1 "
                  type="submit"
                  disabled={disable}
                  onClick={handleClosepayload}
                >
                  back
                </Button>

                <Button className="w-100 m-1" disabled={disable} type="submit">
                  Print
                </Button>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>

    <Modal
      centered
      backdrop="static"
      show={show}
      onHide={handleClose}
      animation={false}
    >
      <Modal.Body>
        <div className="headersjdjd">
        <h1>In-Progress</h1>
          </div>
          <p>Kindly confirm the information below before you proceed</p>
        <div className="infocontainer">
          <div className="inforcontenr">
            <div className="infotittle">Company :</div>
            <div className="infodetails">
              {selectednetwork ? selectednetwork.toUpperCase() : ""}
            </div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Number :</div>
            <div className="infodetails">{payload.number}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Price :</div>
            <div className="infodetails">
              &#8358; {formatNumber(payload.amount)}
            </div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Transaction Time :</div>
            <div className="infodetails">{date}</div>
          </div>
        </div>
      </Modal.Body>
       <center> {awaiting ? <Spinner animation="border" variant="warning" /> : ""}</center>
      <center className="info">{responspayload}</center>
      <div className="d-flex ddd">
        <PaystackButton {...componentProps}  />
          <button
            variant="danger"
            onClick={handleClose}
            className=" ms-1 w-50 cnsnddd"
            type="submit"
          >
            Back
          </button>
        </div>
    </Modal>
    <div className="allcablesyns">
      <div className="allcableitems">
        <div className="cableallre">
          <div className="imagesetuib">
            <img src={image} alt="" />
          </div>
          <div className="productsed">
            <h2>
              <center className="text-dark">AIRTIME SUBSCRIPTION</center>
            </h2>
            <Form.Label className="text-dark my-3">Buy Airtime</Form.Label>
            <FloatingLabel
              controlId="floatingSelect"
              onChange={(event) => {
                setselectednetwork(event.target.value);
              }}
            >
              <Form.Select aria-label="Floating label select example">
                <option value="mtn">MTN</option>
                <option value="glo">GLO</option>
                <option value="airtel">AIRTEL</option>
                <option value="etisalat">9Mobile</option>
              </Form.Select>
            </FloatingLabel>
            <div className="optionshhd">
            <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-dark my-2">
                  Amount
                </Form.Label>
                <Form.Control
                  placeholder="Enter Amount"
                  type="tel"
                  onChange={(event) => {
                    setamount(parseFloat(event.target.value));
                  }}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-dark my-2">
                Recipient
                </Form.Label>
                <Form.Control
                  placeholder="e.g 01812345678"
                  type="email"
                  onChange={(event) => {
                    setphone(event.target.value);
                  }}
                />
              </Form.Group>
              <Button
                variant={loading ? "warning" : "primary"}
                disabled={loading}
                onClick={handleverifier}
                className="my-2 w-100"
                type="submit"
              >
                {loading ? (
                  <>
                    {" "}
                    <Spinner animation="border" size="sm" /> {responspayload}
                  </>
                ) : (
                  "Buy Airtime"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AnaData