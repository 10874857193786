import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { getuser } from "../Service/GetUser.service";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import { database } from "../firebase";

function Userverify() {
  const [loading, setloading] = useState(false);
  const [user, setuser] = useState([]);
  const [remessage, setremessage] = useState("");
  const history = useHistory();

  const id = useParams();

  const baseURL = `https://us-central1-mazuflex-app.cloudfunctions.net/getUser/users/${id.id}`;
  useEffect(() => {
    setremessage("Please Wait");
    axios.get(baseURL).then((response) => {
      setremessage("");
      const data = response.data;
      if (data.isverifyemail === true) {
        window.location.href = "/login";
        return;
      }
      setuser(data);
    });
  }, []);



  const handleVerify = async () => {
    setloading(true)
    try {
      const promisses = [
        axios.put(
          `https://us-central1-mazuflex-app.cloudfunctions.net/getUser/users/${id.id}`
        ),
        axios.post(
          `https://us-central1-mazuflex-app.cloudfunctions.net/CreateAccountKora`,
          {
            account_name: `${user.firstname} ${user.lastname}`,
            reference: user.id
          }
        ),
      axios
        .post(
          `https://us-central1-mazuflex-app.cloudfunctions.net/CreateAccountSquad/create/account`,
          {
            customer_identifier: user.id,
            business_name: `${user.firstname} ${user.lastname}`,
            mobile_num: user.number,
            bvn: "22169832012",
          }
        )
        // axios.post(`https://us-central1-mazuflex-app.cloudfunctions.net/getUser/users/${id.id}`)
      ];
      await Promise.all(promisses).then((res) => {
        setInterval(() => {
          history.push("/login")
         }, 2000);
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  if (!user) {
    return <>loading</>;
  }
  return (
    <div>
      <ToastContainer />
      <div className="verifycontainer">
        <div className="verifycontent">
          <div className="verfyvalue">
            <div>
              <div className="welcome1">
                <div className="welcomeman">
                  <div className="indv">
                    <img src="/img/v.png" alt="" />
                  </div>
                  Welcome <strong></strong> {user.lastname} {user.firstname}
                  <div className="welcomegrand">
                    <div className="welcomeparent">
                      <div className="welcomechil">
                        Your Account need To be Verified; <br />
                        Please click on the link to verify your account
                      </div>{" "}
                      <br />
                    </div>
                  </div>
                  <div className="restbuton">
                    <button onClick={handleVerify} disabled={loading}>
                      {" "}
                      {loading ? " Please Wait" : "Verify Account"}{" "}
                    </button>{" "}
                    <br />
                    {remessage}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Userverify;
