import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react'
import { Form, Modal, Tab, Tabs, Table, Button, Dropdown } from 'react-bootstrap';

import {FcExpand} from "react-icons/fc"
import { getAllAgent } from '../../../Service/GetUser.service';
import { useAuth } from '../../../Service/UserAuth';
import { database } from '../../../firebase';
import axios from "axios";
import "./Log.css"
import Navbar from '../Admincomponent/NavbarAdmin'

function PendingAgent() {
    const [search, setsearch] =  useState("")
    const [loading, setloading] = useState(false)
    const [userCollection, setuserCollection] = useState([]);
    const [lastDocs, setlastDocs] = useState();
    const [isEmpty, setisEmpty] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [pins, setpins] =  useState([])
    const [trxt, settransaction] =  useState([])
    const { loginuser } = useAuth();
    const topUrl = "https://us-central1-mazuflex-app.cloudfunctions.net/getTopUp";

   
    useEffect(() => { 
       axios.get(`https://us-central1-mazuflex-app.cloudfunctions.net/CreateAccountSquad/create/account`).then((res)=>{
        setuserCollection(res.data.data)
       })
    }, [])
    
 
    
  return (
    <div>
          <Navbar/>
         <div className="logcontainer">
        <div className="logcontent">
          <div className="logitems">
            <div className="logheader">Agent Register</div>
            <div className="logsearcharea">

              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="search"
                  onChange={(event) => {
                    setsearch(event.target.value);
                  }}
                />
              
              </Form.Group>
            </div>
            <div className="logotable">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th className='hide'>Date</th>
                    <th>Full Name</th>
                    <th>Account Number</th>
                    <th className='hide'>Beneficiary</th>                    
                  
                  </tr>
                </thead>
                <tbody>
                  {userCollection
                    .filter((val) => {
                      if (search === "") {
                        return val;
                      } else if (val.phone.includes(search)) {
                        return val;
                      } else if (
                        val.first_name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return val;
                      }
                      else if (
                        val.virtual_account_number
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    .map((value, id) => {
                      return (
                        <tr key={id}>
                          <td>{id + 1}</td>
                          <td className='hide'>
                            {value.created_at}
                          </td>
                          <td>
                            {value.first_name}    {value.last_name} 
                          </td>
                          <td>{value.virtual_account_number}</td>
                          <td>{value.beneficiary_account}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
  
               
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PendingAgent