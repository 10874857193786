import React from "react";
import "./Support.css";
import { FaFacebook, FaInstagramSquare, FaWhatsappSquare } from "react-icons/fa";
import { getRandomString } from "../../Service/GetRandomNumber";
import firebase from "firebase";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { database } from "../../firebase";

function ContactUS() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setsubjec] = useState("");
  const [text, settext] = useState("");

  const handlesend = async (e) => {
    e.preventDefault();

    if (name === "" || email === "" || phone === "" || text === "") {
      toast.error("Missing value required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    const messID = getRandomString(7, "1234567890");
    const ref = database.collection("_").doc().id;
    const data = {
      id: ref,
      message: text,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      isRead: true,
      Subject: phone,
      ReciverID: "Admin",
      isclose: false,
      SenderID: name,
      OrderID: email,
      messageid: messID,
    };
    await database
      .collection("message")
      .doc(data.id)
      .set(data)
      .then((result) => {
        toast.success("Message sent successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {});
  };

  return (
    <div>
      <ToastContainer />

      <div classNameName="formwraapper">
        <div className="container111">
          <div className="form-container">
            <div className="left-container">
              <div className="left-inner-container">
                <h2>Support Center</h2>
                <p>
                  Talk to us and we will get back to you within 24 hours, you
                  can also call us on +234 (0) 708 627 6054
                </p>
                <br />
                <p>
                  Vist our office: off Panaf Drive, Dawaki Express Road, Abuja
                  FCT
                </p>

                <div className="social-container">
                  <a href="#" className="social text-primary my-4">
                    <FaFacebook />
                  </a>
                  <a href="#" className="social text-danger">
                    <FaInstagramSquare />
                  </a>

                  <a
                    href="https://wa.me/2348076519831"
                    className="social text-success my-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsappSquare/>
                  </a>
                </div>
              </div>
            </div>
            <div className="right-container">
              <div className="right-inner-container">
                <form action="#">
                  <h2 className="lg-view text-dark">Contact</h2>
                  <h2 className="sm-view text-dark">Let's Talk
                  <div className="social-container">
                  <a href="https://web.facebook.com/profile.php?id=100087289175690&sk=about" className="social text-primary my-4">
                    <FaFacebook />
                  </a>
                  <a href="#" className="social text-danger">
                    <FaInstagramSquare />
                  </a>

                  <a
                    href="https://wa.me/2348076519831"
                    className="social text-success "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsappSquare/>
                  </a>
                </div>
                  </h2>
                  <p>* Required</p>

                  <input
                    type="text"
                    placeholder="Name *"
                    onChange={(event) => {
                      setname(event.target.value);
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Subject *"
                    onChange={(event) => {
                      setsubjec(event.target.value);
                    }}
                  />
                  <input
                    type="phone"
                    placeholder="Email"
                    onChange={(event) => {
                      setemail(event.target.value);
                    }}
                  />
                  <textarea
                    rows="4"
                    placeholder="Message"
                    onChange={(event) => {
                      settext(event.target.value);
                    }}
                  ></textarea>
                  <button onClick={handlesend}>Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUS;
