import React, { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../../Service/UserAuth";
import { formatNumber } from "../../../Service/Utility";
import "./AgentDash.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { storage } from "../../../firebase";

function AgentDash() {
  const [loading, setLoading] = useState(false);
  const { loginuser, userwallet } = useAuth();
  const [accouttype, setaccounttype] = useState("user");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [image, setimage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [message, setmessage] = useState("");

 
  useEffect(() => {
    if (loginuser.permissions === "user") {
      setaccounttype("user");
    } else if (loginuser.permissions === "agent") {
      setaccounttype("agent");
    } else if (loginuser.permissions === "super agent")
      setaccounttype("super agent");
  }, []);

  const handleupload = (e) => {
    if (e.target.files[0]) {
      setimage(e.target.files[0]);
    }
  };

  function handleUpload() {
    return new Promise((resolve) => {
      const uploadTask = storage.ref(`customerImage/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("customerImage")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  const handlecommission = async (e) => {
    e.preventDefault();

    if (parseFloat(userwallet.coin) < 500) {
      toast.error("Minimum amount 500", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setLoading(true);
    const topUrl =
      "https://us-central1-mazuflex-app.cloudfunctions.net/withdrawcommission";
    await axios
      .post(topUrl, {
        userID: loginuser.id,
        amount: parseFloat(userwallet.coin),
      })
      .then((e) => {
       
        toast.success(
          "Commission transferred to your main wallet successfully",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
    setLoading(false);
  };

  const handlesuperagent = async (e) => {
    e.preventDefault();
    const image = await handleUpload();
    if (!image) {
      toast.error("Input missing", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setLoading(true);
    const data = {
      file: image,
      userID: loginuser.id,
      fullname: `${loginuser.firstname} ${loginuser.lastname}`,
      image: loginuser.image,
      phone: loginuser.number,
    };

    try {
      setmessage("Connecting...");
      await axios
        .post(
          "https://us-central1-mazuflex-app.cloudfunctions.net/CreateSuperAgent",
          { data: data }
        )
        .then((e) => {
          setmessage("Updating request...");
          toast.success("Request accepted", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div>
      <ToastContainer />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Become A Super Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Mezuflex will always make you smile,</p>
          Become a mezuflex super agent and earn commission when your referral
          funds wallet. You will earn 0.5% of total funding amount made by your
          referral. Amazing!! T&C Applied...
          <p>Kindly provide the following supporting document </p>
          <p>Upload any Government approved means of Identification</p>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control type="file" onChange={handleupload} />
            <Form.Text className="text-muted">
              Please be informed that using fake document or unapporved
              documement for this request, your application will be rejected.
              let all smile together. <br /> max file size 200MB.
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" dis onClick={handlesuperagent}>
            Procceed
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="accountsdashaoddn">
        <div className="accountamjdjhd">
          <div className="toldoashuser">
            <div className="inforsedcc">
              <img src={loginuser.image} alt="" />
            </div>
            <div className="inforsedcc">
              <p>
                Full Name : {loginuser.firstname} {loginuser.lastname}
              </p>
              <hr />
              <p className="d-flex">
                Status :{" "}
                {loginuser.isBlock === false ? (
                  <div className="text-success">Active</div>
                ) : (
                  <div className="text-danger">Suspended</div>
                )}
              </p>
              <hr />
              <p>
                Wallet Balance :{" "}
                {userwallet.wallet ? (
                  <>&#x20A6; {formatNumber(userwallet.wallet)}</>
                ) : (
                  0
                )}
              </p>
              <hr />
              <p>
                Commission Balance :{" "}
                {userwallet.wallet ? (
                  <>&#x20A6; {formatNumber(userwallet.coin)}</>
                ) : (
                  0
                )}
              </p>
              <hr />
              <button disabled={loading} onClick={handlecommission}>
                {" "}
                {loading ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Withdraw Commision"
                )}
              </button>
            </div>
          </div>
          <div className="otherinformationuserdash">
            <div className="oderinformationconrebnt">
              <div className="infortittleorder">referral code</div>
              <div className="orderinfotcontenr">{loginuser.uniquinum}</div>
            </div>

            <div className="oderinformationconrebnt">
              <div className="infortittleorder">Account Type</div>
              <div className="orderinfotcontenr">{accouttype}</div>
            </div>

            <div className="oderinformationconrebnt">
              <div className="infortittleorder">Mobile Number</div>
              <div className="orderinfotcontenr">{loginuser.number}</div>
            </div>

            <div className="oderinformationconrebnt">
              <div className="infortittleorder">Date of Birth</div>
              <div className="orderinfotcontenr">{loginuser.dob}</div>
            </div>

            <div className="oderinformationconrebnt">
              <div className="infortittleorder">Address</div>
              <div className="orderinfotcontenr">
                {loginuser.address ? loginuser.address : ""}
              </div>
            </div>

            <div
              className="businessinformations"
              style={{
                display: loginuser.permissions !== "user" ? "block" : "none",
              }}
            >
              <h1>Business Details</h1>

              <div className="oderinformationconrebnt">
                <div className="infortittleorder">Buinsess name</div>
                <div className="orderinfotcontenr">
                  {loginuser.businessname}
                </div>
              </div>

              <div className="oderinformationconrebnt">
                <div className="infortittleorder">Business Address</div>
                <div className="orderinfotcontenr">
                  {loginuser.businessAddress}
                </div>
              </div>

              <div className="oderinformationconrebnt">
                <div className="infortittleorder">Business Mobile Number</div>
                <div className="orderinfotcontenr">
                  {loginuser.buinsessPhone}
                </div>
              </div>
              {loginuser.permissions === "super agent" || loginuser.permissions === "Partner" ? (
                ""
              ) : (
                <center>
                  <Button variant="success" onClick={handleShow}>
                    Become a Super Agent
                  </Button>
                </center>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentDash;
