import React from 'react'
import Navbar from '../Admincomponent/NavbarAdmin'
import PendingAgent from '../Admincomponent/PendingAgent'


function AdminDashboard() {
  return (
    <div>
      <Navbar/>
      <PendingAgent/>
    </div>
  )
}

export default AdminDashboard