
import React, { useEffect } from 'react'
import HomeIndex from '../Component/ClientComponent/HomeIndex';
import Sidebar from '../Component/ClientComponent/Sidemenu';
import '../App.css'
import axios from "axios";
import { Link } from 'react-router-dom';


function Home() {





  

  return (
    <div  className='pageindexcloser' style={{backgroundImage: "url(/img/comic4.jpg)"}}>
       <div className="allwraper neaals">
       <div className="allitems">
      <Sidebar/>
      
      </div>
      <div className="allitemsksks1111">
      <HomeIndex/>
     
      </div>
       </div>
      
    </div>
  )
}

export default Home