
import React, { useState } from "react";
import { Form, Button, Spinner} from "react-bootstrap";
import "./CreateUser.css"
import { useAuth } from "../../Service/UserAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";

function Logincomponent() {
  const [password, setpassword] = useState("");
  const [email, setemail] = useState("");
  const [loading, setloading] = useState(false);
    const history =  useHistory()
    const { login } = useAuth();
    

  const handlelogin = async (e) => {
    e.preventDefault();
    setloading(true);
    try {
      await login(email, password);
      toast.success("Please wait while fetching data");
      window.location.href ="/check_user";
    } catch (e) {
      toast.error(e.message);
    }

    setloading(false)
  };

  return (
    <div>
      <ToastContainer />
      <div className="registrationrapper">
        <div className="rgistercontern">
          <div className="registerheass">
              <center>
                <img src="/img/logo.png" alt="" />
                <h1>Welcome Back!!</h1>
                <span>Let get you started</span>
              </center>
          </div>
          
      <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email"  onChange={(event) => {
                    setemail(event.target.value);
                  }} required />

      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password"  onChange={(event) => {
                    setpassword(event.target.value);
                  }} required />
      </Form.Group>
        <Button
          variant={loading ? "warning" : "primary"}
          disabled={loading}
          onClick={handlelogin}
          className="w-100"
          type="submit"
        >
          {loading ? <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        /> : "Login"}
        </Button>
        <center ><p>I don't have an account.   <Link to="/register" className="text-danger jsjsiaiaia">Register</Link></p></center>
        <center ><p>I forget my password. <Link to="/forget_password" className="text-danger jsjsiaiaia">Reset password Here</Link></p></center>
       
      </Form>
        </div>
      </div>
    </div>
  );
}

export default Logincomponent;
