import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link, useHistory } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './AdminNavbar.css';
import { IconContext } from 'react-icons';
import { useAuth } from '../../../Service/UserAuth';
import { useEffect } from 'react';
import {MdOutlineNotificationsActive} from "react-icons/md"

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const history =  useHistory()
  const showSidebar = () => setSidebar(!sidebar);
  const {loginuser} =  useAuth()

  useEffect(() => {
    if (loginuser.pin === ""){
      window.location.href=`/auth/${loginuser.id}`
      return
    }

    if (loginuser.permissions === "user"  || loginuser.permissions === "agent" || loginuser.permissions === "super agent"){
      history.push(`/login`)
      return
    }
  }, [])
  

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar'>
          <Link to='#' className='menu-bars'>
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
          <div className="customerinfolr">
            <div className="logiytinf soance">
                <MdOutlineNotificationsActive/>
            </div>
            <div className="iconis soance">
              {loginuser.firstname} {loginuser.lastname}
            </div>
            <div className="imagesider soance">
            <img src={loginuser.image ? loginuser.image : "/img/logo.png"} alt="" />
            </div>
           
          </div>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (

                <li key={index} className={item.cName}>
                  <Link to={item.path} className="linkiconspam">
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
