import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { getuser, getuserwallet } from "./GetUser.service";
import axios from "axios";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [loginuser, setloginuser] = useState([]);
  const [userwallet, setuserwallet] = useState([]);
  const [unknow, setunknow] = useState([]);
  const [userAccount, setuserAccount] = useState([])
  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  auth.onAuthStateChanged((user) => {
    setCurrentUser(user);
  });

  function logout() {
    setloginuser(null);
    return auth.signOut();
  }

  async function getuserAccount(id) {
    axios
      .get(
        `https://us-central1-mazuflex-app.cloudfunctions.net/CreateAccountKora/${id}`
      )
      .then((result) => {
       
        if (result.data.message === "success"){
          setuserAccount(JSON.parse(result.data.payload))
          return
        }
        setuserAccount([])
        
      })
      .catch((error) => {
    
      });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const id = user.uid;
        getuser(id, (e) => {
          setloginuser(e);
        });

        getuser("iu4WWdVD3GeRe9373RpDm25zN4M2", (e) => {
          setunknow(e);
        });
        getuserwallet(id, (e) => {
          setuserwallet(e);
        });

        getuserAccount(id);
      }

      getuser("iu4WWdVD3GeRe9373RpDm25zN4M2", (e) => {
        setunknow(e);
      });
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const commission = {
    aedc: 0.005,
    Enugu: 0.008,
    airtel: 0.02,
    mtn: 0.0175,
    glo: 0.02,
    mobile: 0.02,
    gotv: 0.01,
    dstv: 0.01,
    startimes: 0.01,
    neco: 0.02,
    wace: 0.02,
  };

  const value = {
    currentUser,
    signup,
    unknow,
    loginuser,
    commission,
    userwallet,
    resetPassword,
    login,
    logout,
    userAccount
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
