import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import "../Servicescomponent/Services.css";
import Gotv from "../../Gotv.json";
import Dstv from "../../dstv.json";
import Startimes from "../../Startimes.json";
import { PaystackButton } from "react-paystack";
import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../Service/UserAuth";
import { database } from "../../firebase";
import { formatNumber } from "../../Service/Utility";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import { functions } from "../../firebase";
import { format } from "date-fns";
import Inloader from "../../Inloader"
const walleturl =
"https://us-central1-mazuflex-app.cloudfunctions.net/getuserwallet";


const finderurl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/Getcablenamefinder";
const curl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/CustomerInformation";
const url =
  "https://us-central1-mazuflex-app.cloudfunctions.net/GetcableTVProvider";

function Cabletvsubescription() {
  const [selectedcable, setcableselected] = useState("gotv");
  const [image, setimage] = useState("/img/gotv.png");
  const [startimes] = useState(Startimes);
  const [gotv] = useState(Gotv);
  const [dstv] = useState(Dstv);
  const [b, setb] = useState("");
  const [datatable, setdatacable] = useState([]);
  const [phoneea, setphone] = useState("");
  const {unknow} = useAuth();
  const [cableboundle, setboundle] = useState([]);
  const [bundleprice, setbundleprice] = useState(0);
  const [loading, setloading] = useState(false);
  const [iuc, setiuc] = useState("");
  const [responspayload, setresponsepayload] = useState("");
  const [payload, setpayload] =  useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [v , setv] =  useState(false)

  const history = useHistory()
  
  const [result, setresult] =  useState("")
  const [cableresponse, setcableresponse] = useState([])
  const [cablerror, setcableerroresponse] =  useState("")
  const [ status, setstatus] = useState("")
  const [code , setboundconde] = useState("")
  const [awaiting, setwaiting] = useState(false)
  const [showpayload, setShowpayload] = useState(false);
  const [disable, setdisable]= useState(false)
  const handleClosepayload = () => {
    setShowpayload(false);
    window.location.href ="/v0/cable";
  };
  const handleShowpayload = () => setShowpayload(true);
  const date =   format(new Date(), "dd/MM/yyyy hh:mm a")
  const g = gotv.data;
  const d = dstv.data;
  const s = startimes.data;


  useEffect(() => {

    history.push('/login')
    const result = cableboundle.find((name) => name.name === b);
    if (result) {
      const boundle = result.availablePricingOptions[0].price;
      setbundleprice(boundle);
      const boundlecode = result.code
      setboundconde(boundlecode)
    }
    setdatacable(result);

    if (selectedcable === "gotv") {
      setboundle(g);
     
    } else if (selectedcable === "dstv") {
      setboundle(d);
      
    } else if (selectedcable === "startimes") {
      setboundle(s);
    } else {
      setboundle("");
    }

    if (selectedcable === "gotv") {
      setimage("/img/gotv.png");
    } else if (selectedcable === "startimes") {
      setimage("/img/star.jpg");
    } else if (selectedcable === "dstv") {
      setimage("/img/dstv.png");
    } else {
      setimage("/img/gotv.png");
    }
  }, [image, selectedcable, b , unknow, bundleprice]);


  const handleparkage = (e) => {
    setb(e.target.value);
  };

  const amount = parseFloat(bundleprice + 100);
  const config = {
    reference: new Date().getTime().toString(),
    email: `mezuflex@gmail.com`,
    amount: amount * 100,
    publicKey: "pk_live_7c49d4df21fc6207a1ece0514092b34ad3f5bbee",
  };
  
  const handlePaystackSuccessAction = async (data) => {
    const verifyPaymentHandler = functions.httpsCallable(
      "verifyPaystackPayment"
    );
    const value = {
      ref: data.reference,
      status: data.success,
      firstname: "Customer",
      lastname: "Mezuflex",
      email: "juliuskingsley1100@gmail.com",
      userId: "iu4WWdVD3GeRe9373RpDm25zN4M2",
      phone: "08165459398",
      amount: bundleprice,
    };
    setwaiting(true)
    await verifyPaymentHandler(value).then(async (result) => {
      
      setresult(result);
      if (result === null || result === undefined) {
        return (
          <>
            <Inloader />
          </>
        );
      }
      if (result.data.data.status === "success") {
        // make the payment for the user
        setresponsepayload("Connecting service ..")
        const ref = database.collection("_").doc().id;

         const data1 = {
            id : ref,
            company : selectedcable, 
            customername: payload.name,
            address: "no value",
            userID : "iu4WWdVD3GeRe9373RpDm25zN4M2",
            AccountNumber : iuc,
            cablerror,
            walletbalance : 0,
            amount:amount, 
            phone: phoneea,
            type : "Cable", 
            status : "Network Error",
            created: firebase.firestore.FieldValue.serverTimestamp()
          }
          await axios
          .post("https://us-central1-mazuflex-app.cloudfunctions.net/buycable", {
            smartcard_number: iuc,
            total_amount: bundleprice,
            product_code: code,
            product_monthsPaidFor: payload.rawOutput.invoicePeriod,
            service_type: selectedcable,
            agentReference:ref,
            agentId: "205",
            
          })
          .then(async (e) => { 
            setdisable(true)
            setresponsepayload("finalizing connection. Please wait ")           
            if (e.data.status === "success") {
              handleClose();
              setcableresponse(e.data.data);
              setstatus("success")
              handleShowpayload();
              const data = {
                id : ref,
                company : selectedcable, 
                customername: payload.name,
                address: "no value",
                userID : "iu4WWdVD3GeRe9373RpDm25zN4M2",
                AccountNumber : iuc,
                walletbalance : 0,
                amount: amount, 
                isToday:true,
                phone: phoneea,
                payloadres: e.data.data,
                
                type : "Cable", 
                created: firebase.firestore.FieldValue.serverTimestamp(),
                status : "success",
              }   
              await database.collection("Transaction").doc(data.id).set(data)
            }else{
              handleClose();
              setcableerroresponse(e.data.data);
              setstatus("error")
              handleShowpayload();
              await database.collection("Transaction").doc(data1.id).set(data1)
            }
            setdisable(false)
          })
          .catch((e) => {
            toast.error(e.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        // Setpaymentpayload(result.data.data);
        // setshowvalue(true);
        handleClose();
      } else {
        // Setpayload(result.data.data);
        handleClose();
        handleShowpayload();
      }
    }).catch((e)=>{
      toast.error(e.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    });

  };

  const handlePaystackCloseAction = () => {
    history.goBack();
  };

  const componentProps = {
    ...config,
    text: loading ? "Proccessing request" : "Pay with Paystack",
    onSuccess: (data) => handlePaystackSuccessAction(data),
    onClose: () => handlePaystackCloseAction(),
  };


  const handleverifier = async (e) => {
    e.preventDefault();

    if (b === "") {
      toast.error("Bad format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setloading(true);
    try {
      setresponsepayload("fetching information")
      await axios.post(finderurl, {
        service_type: selectedcable,
        account_number: iuc,
      }).then((e)=>{
        
         if (e.data.message === "Successful"){
          setpayload(e.data.data.user)
          setv(true)
          handleShow()
        setresponsepayload("")
         }else{
          toast.error("😔 Invalide customer information", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return
         }
      }).catch((e)=>{
       
      });
    } catch (error) {
      console.log(error)
    }
    setloading(false);
  };


  // const handlepayment = async (e) => {
  //   e.preventDefault();
  //   const com = 100
  //   const payable =  bundleprice + com 

  //   setloading(true);
  //   try {
  //     await axios
  //       .post(walleturl, { id: userwallet.id, amount: payable })
  //       .then(async (e) => {
  //         setresponsepayload("validating");
       
  //         const ref = database.collection("_").doc().id;
    
  //        const data1 = {
  //           id : ref,
  //           company : selectedcable, 
  //           customername: payload.name,
  //           address: "no value",
  //           userID : loginuser.id,
  //           AccountNumber : iuc,
  //           cablerror,
  //           walletbalance : userwallet.wallet,
  //           amount:payable, 
  //           phone: phone,
  //           type : "Power", 
  //           status : "Network Error",
  //           created: firebase.firestore.FieldValue.serverTimestamp()
  //         }
         
  //         if (e.data.message === "success") {
  //           setresponsepayload("Connecting service ..");
  //           await axios
  //             .post("https://us-central1-mazuflex-app.cloudfunctions.net/buycable", {
  //               smartcard_number: iuc,
  //               total_amount: bundleprice,
  //               product_code: code,
  //               product_monthsPaidFor: payload.rawOutput.invoicePeriod,
  //               service_type: selectedcable,
  //               agentReference:ref,
  //               agentId: "205",
                
  //             })
  //             .then(async (e) => { 
                
  //               setresponsepayload("finalizing connection. Please wait ")           
  //               if (e.data.status === "success") {
  //                 handleClose();
  //                 setcableresponse(e.data.data);
  //                 setstatus("success")
  //                 handleShowpayload();
  //                 const data = {
  //                   id : ref,
  //                   company : selectedcable, 
  //                   customername: payload.name,
  //                   address: "no value",
  //                   userID : loginuser.id,
  //                   AccountNumber : iuc,
  //                   walletbalance : userwallet.wallet,
  //                   amount: payable, 
  //                   phone: phone,
  //                   payloadres: e.data.data,
                    
  //                   type : "Power", 
  //                   created: firebase.firestore.FieldValue.serverTimestamp(),
  //                   status : "success",
  //                 }   
  //                 await database.collection("Transaction").doc(data.id).set(data)
  //               }else{
  //                 handleClose();
  //                 setcableerroresponse(e.data.data);
  //                 setstatus("error")
  //                 handleShowpayload();
  //                 await database.collection("Transaction").doc(data1.id).set(data1)
  //               }
  //             })
  //             .catch((e) => {
  //               toast.error(e.message, {
  //                 position: toast.POSITION.TOP_RIGHT,
  //               });
  //             });
  //         } else {
  //           toast.error("Insufficent wallet balance, Please Top", {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //           setresponsepayload("");
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e)
  //         toast.error(e.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       });
  //   } catch (e) {
  //     console.log(e)
  //     toast.error(e.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }

  //   setloading(false);
  // };

  return (
    <div>
      <ToastContainer />
      <Modal
        centered
        backdrop="static"
        show={showpayload}
        onHide={handleClosepayload}
        animation={false}
      >
        <Modal.Body>
          {status === "error" ?  <>

            <div className="headersjdjd bg-danger">
            <h1>ERROR</h1>
          </div>
          <p>Transaction Details</p>
          <div className="infocontainer">
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">
                {selectedcable ? selectedcable : ""}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">IUC :</div>
              <div className="infodetails">{iuc}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">{payload.name}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Amount :</div>
              <div className="infodetails">&#8358; {formatNumber(bundleprice + 100)} <br/>
              Charge Inclusive
              </div>
            </div>

            
            <div className="inforcontenr">
            <div className="infotittle">Transaction Time :</div>
            <div className="infodetails">{date}</div>
          </div>

            <div className="inforcontenr">
              <div className="infotittle">Message :</div>
              <div className="infodetails">
                Transaction Error, Please contact support
              </div>
            </div>
            <div className="d-flex">
       <Button variant="danger" className="w-100 m-1 " disabled={disable} type="submit" onClick={handleClosepayload}>
          back
        </Button>

        <Button className="w-100 m-1" disabled={disable} type="submit">
          Print
        </Button>
       </div>
          </div>
          </>  : <>
          <div className="headersjdjd bg-success">
            <h1>SUCCESS</h1>
          </div>
          <p>Transaction Details</p>
          <div className="infocontainer">
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">
                {selectedcable ? selectedcable : ""}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">IUC :</div>
              <div className="infodetails">{iuc}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">{payload.name}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Amount :</div>
              <div className="infodetails">&#8358; {formatNumber(bundleprice + 100)}
              Charge Inclusive
              </div>
            </div>

            
            <div className="inforcontenr">
            <div className="infotittle">Transaction Time :</div>
            <div className="infodetails">{date}</div>
          </div>

            <div className="inforcontenr">
              <div className="infotittle">Message :</div>
              <div className="infodetails">
                Transaction Successful, Please contact support
              </div>
            </div>
            <div className="d-flex">
       <Button variant="danger" className="w-100 m-1 " type="submit" onClick={handleClosepayload}>
          back
        </Button>

        <Button className="w-100 m-1" type="submit">
          Print
        </Button>
       </div>
          </div>
          </>
}
        </Modal.Body>
      
      </Modal>

      <Modal
        centered
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div className="headersjdjd">
          <h1>In-Progress</h1>
          </div>
          <p>Kindly confirm the information below before you proceed</p>
          <div className="infocontainer">
          <div className="inforcontenr">
            <div className="infotittle">Company :</div>
            <div className="infodetails">
              {selectedcable ? selectedcable.toUpperCase() : ""}
            </div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">IUC :</div>
            <div className="infodetails">{iuc}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Customer Name :</div>
            <div className="infodetails">{payload.name}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Package :</div>
            <div className="infodetails">&#8358; {b}</div>
          </div>

           <div className="inforcontenr">
            <div className="infotittle">Amount :</div>
            <div className="infodetails">&#8358; {bundleprice}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Charge :</div>
            <div className="infodetails">&#8358; {formatNumber(100)}</div>
          </div>

          <div className="inforcontenr bg-info">
            <div className="infotittle"> Total Amount :</div>
            <div className="infodetails">&#8358; {formatNumber(bundleprice+ 100)} </div>
          </div>

          <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">{date}</div>
            </div>
            
        </div> 
        </Modal.Body>
        <center> {awaiting ? <Spinner animation="border" variant="warning" /> : ""}</center>
        <center className="info">{responspayload}</center>
        <div className="d-flex ddd">
        <PaystackButton {...componentProps} />

          <button
            variant="danger"
            onClick={handleClose}
            className=" ms-1 w-50 cnsnddd"
            type="submit"
          >
            Back
          </button>
        </div>
      </Modal>
      <div className="allcablesyns">
        <div className="allcableitems">
          <div className="cableallre">
            <div className="imagesetuib">
              <img src={image} alt="" />
            </div>
            <div className="productsed">
              <h2>
                <center className="text-dark">CABLE SUBSCRIPTION</center>
              </h2>
              <Form.Label className="text-dark my-3">Cable TV</Form.Label>
              <FloatingLabel
                controlId="floatingSelect"
                onChange={(event) => {
                  setcableselected(event.target.value);
                }}
              >
                <Form.Select aria-label="Floating label select example">
                  <option value="gotv">Gotv</option>
                  <option value="dstv">DSTV</option>
                  <option value="startimes">STARTIMES</option>
                </Form.Select>
              </FloatingLabel>
              <div className="optionshhd">
                <Form.Label className="text-dark my-3">
                  Select your preferred Package
                </Form.Label>
                <Form.Select
                  value={b}
                  required
                  aria-label="Floating label select example"
                  onChange={handleparkage}
                >
                  <option key="">Please Select Parkage</option>
                  {cableboundle.map((b) => (
                    <option key={b.code}>
                      {b.name}
                    </option>
                  ))}
                </Form.Select>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-dark my-3">Enter IUC</Form.Label>
                  <Form.Control
                    type="text"
                    
                    onChange={(event) => setiuc(event.target.value)}
                    placeholder="Enter IUC"
                  />
                  <Form.Text className="text-muted">
                    Verification Required.
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-dark my-2">
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter mobile number"
                    type="email"
                    onChange={(event) => {
                      setphone(event.target.value);
                    }}
                  />
                </Form.Group>
                <Button
                  variant={loading ? "warning" : "primary"}
                  disabled={loading}
                  onClick={handleverifier}
                  className="my-2 w-100"
                  type="submit"
                >
                  {loading ? (
                    <>
                      {" "}
                      <Spinner animation="border" size="sm" /> {responspayload}
                    </>
                  ) : (
                    "Verify IUC"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cabletvsubescription;
