import React, { useEffect, useState } from "react";
import { FaBox, FaBars, FaUserAlt } from "react-icons/fa";
import {
  MdNetworkWifi,
  MdOutlineCall,
  Md6FtApart,
  MdContactMail,
  MdPlaylistAddCheck,
  MdLogout,
  MdSchool,
  MdMoney
} from "react-icons/md";
import { GiElectric } from "react-icons/gi";
import {AiFillSetting} from "react-icons/ai"
import { IoMdTv } from "react-icons/io";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./Sidemenu.css";
import axios from "axios";
import { FcApproval, FcHighPriority } from "react-icons/fc";
import Inloader from "../../Inloader";
import { functions } from "../../firebase";
import { PaystackButton } from "react-paystack";
import { useAuth } from "../../Service/UserAuth";
import { getuser } from "../../Service/GetUser.service";
import { formatNumber } from "../../Service/Utility";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
// const Producturl = "https://api.staging.baxibap.com/services/multichoice/list"

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const history = useHistory();
  const { uid } = useAuth().currentUser;
  const [user, setuser] = useState([]);
  const [payload, Setpayload] = useState(null);
  const { userwallet, loginuser, userAccount} = useAuth();
  const [show, setShow] = useState(false);
  const [amountaa, setamount] = useState(0);
  const [loading, setloading] = useState(false);
  const handleClose = () => setShow(false);
  const [awaiting, setwaiting] = useState(false)
  const handleShow = () => setShow(true);
  const [result, setresult] = useState(null);
  const [showpayload, setShowpayload] = useState(false);
  const [setvalue, setshowvalue] = useState(false);

  
  const handleClosepayload = () => {
    window.location.href="/dashboard"
    setShowpayload(false)
  } ;
  const handleShowpayload = () => {
    setloading(false)
    setShowpayload(true)
  };

  useEffect(() => {
    getuser(uid, (users) => {
      if (users) {
        setuser(users);
      }

      if (users.isSuspended === true){
        return<>Account suspended please contact admin</>
      }

    });

    // if (userwallet.wallet < 50) {
    //   handleShow();
    // }



  
  }, [userwallet]);
  const paymentnotify = () => {
    history.push("/payment/notification");
  };

  const amount = parseFloat(amountaa);

  const config = {
    reference: new Date().getTime().toString(),
    email: `${user.email}`, 
    amount: amount * 100,
    //publicKey: "pk_test_c717a8b3a3a262679ce586e08af30caf3c72f455",
    publicKey: "pk_live_7c49d4df21fc6207a1ece0514092b34ad3f5bbee",
  };

  const handlePaystackSuccessAction = async (data) => {
 
    const verifyPaymentHandler = functions.httpsCallable(
      "verifyPaystackPayment"
    );

    const value = {
      ref: data.reference,
      status: data.success,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      userId: user.id,
      phone: user.number,
      amount: amount,
    };
    setwaiting(true);
    await verifyPaymentHandler(value).then((result) => {
      setresult(result);
      if (result === null || result === undefined) {
        return (
          <>
            <Inloader />
          </>
        );
      }
      if (result.data.data.status === "success") {
        Setpayload(result.data.data);
        setshowvalue(true);
        handleClose();
        handleShowpayload();
      } else {
        Setpayload(result.data.data);
        handleClose();
        handleShowpayload();
      }
    });
    setloading(false)
  };

  const handlePaystackCloseAction = () => {
    window.location.href="/dashboard";
  };

  const handleagent = ()=>{
    history.push("/Partner-with-us")
  }
  const componentProps = {
    ...config,
    text: "Fund Wallet",
    onSuccess: (data) => handlePaystackSuccessAction(data),
    onClose: () => handlePaystackCloseAction(),
  };

  // const getbvn = async (e) => {
  //   e.preventDefault()
  //   setres("BVN is required for verification Purpose");

  //   if (bvn.length === 11) {
  //     setlaoding(true);
  //     const url = `https://us-central1-mazuflex-app.cloudfunctions.net/getBvnUser/users/${bvn}`;
  //     axios.get(url).then(async (e) => {
  //       const user = e.data[0];
  //       // if (user) {
  //       //   toast.error("Record use by another user", {
  //       //     position: toast.POSITION.TOP_RIGHT,
  //       //   });
  //       //   return;
  //       // }
  //       const url = `https://us-central1-mazuflex-app.cloudfunctions.net/verifyUser`;
  //       setbvnres("connecting ...");
  //       axios
  //         .post(url, {
  //           bvn: bvn,
  //         })
  //         .then(async (e) => {
  //           console.log();
  //           if (!e.data.session_id) {
  //             setbvnres("Error initiating request");
  //             setlaoding(false);
  //             return;
  //           }
  //           setsessionID(e.data.session_id);
  //           setbvnres("Please Enter OTP set to your Mobile number");
  //           handleShowotp();
  //           setlaoding(false);
  //         });
  //     });
  //   }
  // };

  // const handleGetdata = async (e) => {
  //   e.preventDefault();

  //   if (otp.length > 6) {
  //     setbvnres("invalid Otp");
  //     return;
  //   }

  //   if (otp === "" || session === "") {
  //     setbvnres("invalid request");
  //     return;
  //   }
  //   setbvnres("Please wait...");
  //   const token = session;
    
  //   try {
  //     setlaoding(true);
  //    await axios
  //       .post(
  //         "https://us-central1-mazuflex-app.cloudfunctions.net/getBvnUser/users",
  //         {
  //           otp,
  //         },
  //         {
  //           headers: {
  //             session_id: token,
  //           },
  //         }
  //       )
  //       .then(async (result) => {
  //         const dp = result.data.payload;
  //         if (result.data.status === "success") {
  //           setbvnres("Finalizing request please wait");
  //           await handlecreaeaccount(bvn)
  //           await axios
  //             .post(
  //               ` https://us-central1-mazuflex-app.cloudfunctions.net/UpdateuserRecord/user/record`,
  //               {
  //                 lastname: dp.last_name,
  //                 firstname: dp.first_name,
  //                 dob: dp.dob,
  //                 number: dp.phone ? dp.phone : loginuser.number,
  //                 address: dp.address_line_1,
  //                 isvalidUser: true,
  //                 bvn: bvn,
  //                 id: loginuser.id,
  //               }
  //             )
  //             .then((e) => {
  //               handleCloseotp();
  //               setbvnres("Verification completed");
               
  //             });
  //         }
  //         setbvnres(result.data.message);
  //       })
  //       .catch((err) => {
  //         toast.error(err.message);
  //       });
        
  //   } catch (error) {}
  //   setlaoding(false);
   
  // };
  // const handlecreaeaccount = async (bvn) => {
  //   console.log(bvn)
  //   if (bvn === "") {
  //     toast.error("Error occured", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     return;
  //   }

  //   // if (verifiedBvn !== true) {
  //   //   toast.error("Operation fail", {
  //   //     position: toast.POSITION.TOP_RIGHT,
  //   //   });
  //   //   return;
  //   // }

  //   const bussinessanme = businessname
  //     ? businessname
  //     : `${loginuser.firstname} ${loginuser.lastname}`;

  //   try {
  //     setlaoding(true);
  //     await axios
  //       .post(
  //         `https://us-central1-mazuflex-app.cloudfunctions.net/CreateAccountSquad/create/account`,
  //         {
  //           customer_identifier: loginuser.id,
  //           business_name: bussinessanme,
  //           mobile_num: loginuser.number,
  //           bvn: bvn,
  //         }
  //       )
  //       .then(async(res) => {
  //         if (res.data.message !== "Success"){
  //           setshowvalue(false);
  //           handleShowpayload();
  //         }
  //         Setpayload(res.data);
  //         setshowvalue(true);
  //         handleShowpayload();
  //       })
  //       .catch((error) => {
  //         toast.error(error.message);
  //       });
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  //   setlaoding(false);
  // };

  const menuItem = [
    {
      path: "/dashboard",
      name: "My Account",
      icon: <FaUserAlt />,
      Tittle: "Account",
    },
    {
      path: "/transaction",
      name: "Transaction",
      icon: <MdPlaylistAddCheck />,
      Tittle: "Transaction",
    },

    {
      path: "/data",
      name: "Get Cheap Data",
      icon: <MdNetworkWifi />,
      Tittle: "Buy Data",
    },
    {
      path: "/airtime",
      name: "Get Airtime",
      icon: <MdOutlineCall />,
      Tittle: "Buy Airtime",
    },
    {
      path: "/electricity",
      name: "Vend Electricity",
      icon: <GiElectric />,
      Tittle: "Electricity",
    },

    {
      path: "/cable",
      name: "Cable Subscription",
      icon: <IoMdTv />,
      Tittle: "Cable",
    },

    {
      path: "/fund/wallet/betting",
      name: "Betting",
      icon: <MdMoney />,
      Tittle: "Betting",
    },
   
    {
      path: "/more",
      name: "Result Checker",
      icon: <MdSchool />,
      Tittle: "Result Checker",
    },
    {
      path: "/settings",
      name: "Account Settings",
      icon: <AiFillSetting />,
      Tittle: "Settings",
    },
    {
      path: "/Partner-with-us",
      name: "Partners",
      icon: <Md6FtApart />,
      Tittle: "Partners",
    },

    {
      path: "/support",
      name: "Contact",
      icon: <MdContactMail />,
      Tittle: "Contact",
    },
    {
      path: "/logout",
      name: "Logout",
      icon: <MdLogout />,
      Tittle: "Logout",
    },
  ];

 
  return (
    <>
    {/* <Modal
        show={showotp}
        onHide={handleCloseotp}
        backdrop="static"
        keyboard={false}
        centered
        className="newhsjahsbas"
      >
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formPassword">
            <Form.Label>Enter OTP</Form.Label>
            <Form.Control
              type="password"
              onChange={(event) => setotp(event.target.value)}
            />
          </Form.Group>

          <Form.Text className="text-success">
            {bvnres ? bvnres : "Please dont share any information with anyone."}
          </Form.Text>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant={loading ? "warning" : "primary"}
            onClick={handleGetdata}
            disabled={loading}
          >
            {loading ? "processing ..." : "Validate"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showaccount}
        onHide={handleCloseaccount}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Funding Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>BVN </Form.Label>
            <Form.Control type="text" onChange={(event)=>{
              setbvn(event.target.value)
            }} />
            <Form.Text className="text-muted"></Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Business Name (Optional)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Optional"
              onChange={(event) => {
                setbuinsessname(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Text className="text-success">{bvnres}</Form.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseaccount}>
            Close
          </Button>

          <Button
            variant={loading ? "warning" : "success"}
            onClick={getbvn}
            style={{ display: !verifiedBvn ? "block" : "none" }}
            disabled={loading}
          >
            {loading ? "Please wait" : "Create Fund Account"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showpayload} onHide={handleClosepayload}>
        <Modal.Body>
          <div className="payloadconiner">
            <center>
              <div className="paylaod">
                {setvalue ? (
                  <>
                    {payload.message === "Success" ? (
                      <div>
                        <h1>
                          <FcApproval />
                        </h1>
                        <p>Account Created Successfully</p>
                      </div>
                    ) : (
                      <div>
                        <h1>
                          <FcHighPriority />
                        </h1>
                        <p>Request Failed. Please try again later</p>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </center>
          </div>
        </Modal.Body>

        <Button
          variant={payload.message === "Success" ? "success" : "danger"}
          onClick={handleClosepayload}
        >
          Close
        </Button>
      </Modal>
      <Modal show={showfund} onHide={handleClosefund} size="lg">
        <Modal.Body>
          <div className="fundwalletside">
            <div className="walttebdhd">
              <div className="wallwtheadd">Fund Your wallet</div>
              <div className="fundwalletmethod">
                <div className="backdeposut">
                  <div className="inforcontenr">
                    <div className="infotittle">BANK :</div>
                    <div className="infodetails">Guaranty Trust Bank Plc </div>
                  </div>

                  <div className="inforcontenr">
                    <div className="infotittle">ACCOUNT NAME:</div>
                    <div className="infodetails">COLETKING CONCEPT</div>
                  </div>

                  <div className="inforcontenr">
                    <div className="infotittle">ACCOUNT NUMBER :</div>
                    <div className="infodetails">0771679118</div>
                  </div>

                  <Button
                    variant={loading ? "warning" : "primary"}
                    disabled={loading}
                    onClick={paymentnotify}
                    className="w-100"
                    type="submit"
                  >
                    Create Payment Notification
                  </Button>
                </div>
                <center>
                  <div className="paystackmethof">
                    <div className="wappconashowreac">
                      {loginuser.SquardAccount ? (
                        <>
                          <div className="showaccountinput">
                            <div>
                              <strong>FUND ACCOUNT</strong>
                              <div className="showeaccojdconetn">
                                <h2>{loginuser.SquardAccount}</h2>
                                <span className="p-0 fs-5 jsaccount">
                                  {loginuser.firstname} {loginuser.lastname}
                                </span>{" "}
                                <br />
                                <span className="p-0 fs-6 jsaccount">
                                  GTB Bank
                                </span>{" "}
                                <br />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="showaccountinput">
                            <Button
                              variant={loading ? "warning" : "light"}
                              disabled={loading}
                              onClick={handleShowaccount}
                            >
                              Create Account
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      <div className="containerside">
        <div style={{ width: isOpen ? "250px" : "159px" }} className="sidebar">
          <div className="besy">
            <FaBars onClick={toggle} />
          </div>
          <div className="top_section">
            <center>
              {" "}
              <Link to="/dashboard">
                <img
                  src="/img/logo.png"
                  alt=""
                  style={{ height: isOpen ? "20vh" : "10vh" }}
                  className="logo"
                />
              </Link>
            </center>
            <div
              style={{ marginLeft: isOpen ? "50px" : "0px" }}
              className="bars"
            ></div>
          </div>

          <div
            className="allitemshw"
            style={{ display: isOpen ? "block" : "none" }}
          >
            <div className="walleatside">
              <div className="supermenuemega">
                <div className="suoermenrmega">
                  <span className="aksj">Earning : {userwallet.coin ? <>&#8358; {formatNumber(userwallet.coin)}</>  : 0} </span>
                </div>
                <div className="suoermenrmega" >
                  <span className="aksj">
                    Wallet : {userwallet.wallet ? <> &#8358; {formatNumber(userwallet.wallet)} </> : <>0</> }
                    <br />
                    <span className="hallwjjdhd">
                      Click here to fund wallet
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className={isOpen ? "link" : "islink"}
              activeclassName="activec"
            >
              <div className="icon">
                <div className="iconsimage">{item.icon} </div>

                <div
                  className="iconsimage"
                  style={{ display: isOpen ? "none" : "block" }}
                >
                  {item.Tittle}
                  <br />
                </div>
              </div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>

        <main>{children}</main>

        <div className="toparseiddt">
          <div className="supermenue">
            {/* <div className="suoermenr hide" onClick={handleRegister}>
              <p className="aksj">Join Us</p>
            </div>
            <div className="suoermenr hide" onClick={handlelogin}>
              <p className="aksj">Login</p>
            </div> */}
            <div className="suoermenr hide" onClick={handleagent} style={{display: user.isAgent ===  true ? "none" : "block"}}>
              <p className="aksj">Become an Agent</p>
            </div>
            <div
              className="suoermenr hide"
              style={{ display: isOpen ? "none" : "block" }}
              onClick={handleShow}
            >
              <p className="aksj">
                Wallet : {userwallet.wallet ? <> &#8358; {formatNumber(userwallet.wallet)} </> : <>0</>}
              </p>
            </div>
            <div
              className="suoermenr hide"
              style={{ display: isOpen ? "none" : "block" }}
            >
              <p className="aksj">
                Earning : &#8358; {userwallet.coin ? formatNumber(userwallet.coin)  : 0}
              </p>
            </div>
            <div className="suoermenr mmmmhhh ">
              <Link to="/agent/dashboard">
              <div
                className="ianmehssj"
                style={{ display: isOpen ? "none" : "block" }}
              >
                <img src={loginuser.image? loginuser.image : "/img/logo1.png"}  alt="" />
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="containerside2">
        <div
          style={{ display: isOpen ? "block" : "none" }}
          className="sidebar1"
        >
          <div className="top_section">
            <center>
              {" "}
              <Link to="/dashboard">
                <img
                  src="/img/logo.png"
                  alt=""
                  style={{ height: isOpen ? "13vh" : "10vh" }}
                  className="logo"
                />
              </Link>
            </center>
            <div
              style={{ marginLeft: isOpen ? "50px" : "0px" }}
              className="bars"
            ></div>
          </div>

          <div className="allitemshw" >
            <div className="walleatside">
              <div className="supermenuemega">
          
                  {/* <div className="suoermenrmega" onClick={handleRegister}>
                    <span className="aksj">Join Us</span>
                  </div>
                  <div className="suoermenrmega " onClick={handlelogin}>
                    <span className="aksj">Login</span>
                  </div> */}
                
                  <div className="suoermenrmega">
                  <p className="aksj">
                Earning : &#8358; {userwallet.coin ? formatNumber(userwallet.coin)  : 0}
              </p>
                  </div>
                
                  <div className="suoermenrmega" onClick={handleShow}>
                  <p className="aksj">
                    Wallet : {userwallet.wallet ? <> &#8358; {formatNumber(userwallet.wallet)} </> : <>0</> }
                    <br />
                    <span className="hallwjjdhd">
                      Click here to fund wallet
                    </span>
                  </p>
                  </div>
                </div>
              </div>
          
          </div> 

          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className={isOpen ? "link" : "islink"}
              activeclassName="activec"
            >
              <div className="icon">
                <div className="iconsimage">{item.icon} </div>
              </div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
        <main>{children}</main>

        <div className="toparseiddt111">
          <div className="supermenue1111">
            <div className="suoermenr111 ">
              <div className="besy111">
                <FaBars onClick={toggle} />
              </div>
              <Link to="/agent/dashboard">

              <div className="newconhdhe">
              <div
                className="ianmehssj cowalteshow"
                style={{ display: isOpen ? "none" : "block" }}
              >
               Wallet : {userwallet.wallet ? <> &#8358; {formatNumber(userwallet.wallet)} </> : <>0</> }
             </div>
              <div
                className="ianmehssj"
                style={{ display: isOpen ? "block" : "block" }}
              >
                <img src={loginuser.image? loginuser.image : "/img/logo1.png"}  alt="" />
             </div>
              </div>
              </Link> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
