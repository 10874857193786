import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Page/Home";
import Registercomponent from "./Page/Register";
import ForgetPassword from "./Page/Forgetpassword";
import LoginComponent from "./Page/Login";
import { AuthProvider } from "./Service/UserAuth";
import Validator from "./Page/Validator";
import Userverify from "./Page/Userverify";
import Dashboard from "./Page/Dashboard";
import Loading from "./Loading";
import Logout from "./Logout";
import AdminLogout from "./AdminLogout";
import CableTV from "./Page/CableTV";
import PrivateRoute from "./PrivateRoute";
import ElectricityPayment from "./Page/Electricity";
import Datavending from "./Page/Datavending";
import TransactionPage from "./Page/TransactionLogPage";
import Airtime from "./Page/Airtime";
import Wacepage from "./Page/WacePage";
import Necopage from "./Page/Neco";
import MoreServices from "./Page/Moreservices";
import AdminDashboard from "./AdminComponent/Admincontainer.js/AdminPage/AdminDashboard";
import AnaCable from "./AdminComponent/Annanimus/Annanymous/Acable";
import Aairtime from "./AdminComponent/Annanimus/Annanymous/Aairtime";
import Aelectricity from "./AdminComponent/Annanimus/Annanymous/Aelectricity";
import Adata from "./AdminComponent/Annanimus/Annanymous/Adata";
import Amore from "./AdminComponent/Annanimus/Annanymous/Amore";
import Anwacepage from "./AdminComponent/Annanimus/Annanymous/AnaWeace";
import AdminLogin from "./AdminComponent/Admincontainer.js/Admincomponent/CreateStaff/AdminLogin";
import Contactuspag from "./Page/Contactuspag";
import AboutUpage from "./Page/AboutUpage";
import AdminRegister from "./AdminComponent/Admincontainer.js/Admincomponent/CreateStaff/AdminRegister";
import PinSetup from "./AdminComponent/Admincontainer.js/Admincomponent/PinSetup";
import paymentnotify from "./Page/Paymentnotification";
import UserLog from "./AdminComponent/Admincontainer.js/Admincomponent/UserLog";
import AdminTransactions from "./AdminComponent/Admincontainer.js/Admincomponent/AdminTransactions";
import Managementpage from "./AdminComponent/Admincontainer.js/Admincomponent/Managementpage";
import CreateNeco from "./AdminComponent/Admincontainer.js/Admincomponent/CreateNeco";
import AnNeco from "./AdminComponent/Annanimus/AnNeco";
import BecomeAgent from "./AdminComponent/Admincontainer.js/Admincomponent/CreateStaff/BecomeAgent";
import UseConatc from "./Page/UseConatc";
import Settings from "./Page/Settings";
import Agentindexpage from "./AdminComponent/Admincontainer.js/AdminPage/Agentindexpage";
import PendingAgent from "./AdminComponent/Admincontainer.js/AdminPage/PendingAgentpage";
import IssueReportPage from "./AdminComponent/Admincontainer.js/AdminPage/IssueReportPage";
import { getversion } from "./Service/GetUser.service";
import { Button, Modal } from "react-bootstrap";
import SettingPage from "./AdminComponent/Admincontainer.js/AdminPage/SettingPage";
import Transactionsdetails from "./AdminComponent/Servicescomponent/Transactionsdetails";
import Terms from "./Terms";
import WalletHistory from "./Page/WalletHistory";
import FundUser from "./AdminComponent/Admincontainer.js/AdminPage/FundUser";
import ApiComponet from "./AdminComponent/Admincontainer.js/Admincomponent/ApiComponet";
import axios from "axios";
import GetimcomeValue from "./AdminComponent/Admincontainer.js/AdminPage/GetimcomeValue";
import Betting from "./Page/Betting";
import Abetting from "./AdminComponent/Annanimus/Annanymous/Abetting";
import SanQuard from "./AdminComponent/Admincontainer.js/AdminPage/SanQuard";
import SquardContent from "./AdminComponent/Admincontainer.js/Admincomponent/SquardContent";
import ApiTransaction from './AdminComponent/Admincontainer.js/Admincomponent/ApiTransaction';

// import datafile from "./DATAF.json"

function App() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);

    getversion((e) => {
      if (e) {
        const vers = e[0].version;
        if (vers !== "1.0.8") {
          handleShow();
          return;
        }
      }

     
    });

    // {
    //   "access_token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FwaS5zYW5kYm94LnNhZmVoYXZlbm1mYi5jb20iLCJzdWIiOiJlOTRlODIyYTljZjZhZDc3ZWQzMzE4YTczZTYwYWIyOSIsImF1ZCI6Imh0dHBzOi8vbWV6dWZsZXguY29tLyIsImp0aSI6IjQ0YTYyMmM0YTFjYjdlYjk0NTg4N2E5MjVhMDM1OTQ0IiwiZ3JhbnRfdHlwZSI6ImFjY2Vzc190b2tlbiIsInNjb3BlcyI6WyJSRUFEIl0sImlic19jbGllbnRfaWQiOiI2NDNiYWVlNmFjNDNiNzAwMjQzZTYxOWQiLCJpYnNfdXNlcl9pZCI6IjY0M2JhZWU2YWM0M2I3MDAyNDNlNjFhNiIsImlhdCI6MTY4MTY0Mzk0MCwiZXhwIjoxNjgxNjQ2MzQwfQ.frvqeYk129P3CPubIzTTE_yLbN9vdpBc3-dz40cVOv42x9bHb-OAup5gRO1fnElbSdl9iANG3kff5tZt20_x6567lcPw-2G1p2hKPnmc1OU-lsFdzcGX5cHpWoV8GtqJ9zzXPQq2ab0LFBmHz_ld1j2_0tVvcRH95yeZv6SQGdw",
    //   "client_id": "e94e822a9cf6ad77ed3318a73e60ab29",
    //   "token_type": "Bearer",
    //   "expires_in": 2399,
    //   "refresh_token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FwaS5zYW5kYm94LnNhZmVoYXZlbm1mYi5jb20iLCJzdWIiOiJlOTRlODIyYTljZjZhZDc3ZWQzMzE4YTczZTYwYWIyOSIsImF1ZCI6Imh0dHBzOi8vbWV6dWZsZXguY29tLyIsImp0aSI6IjJlNjU3MjM0YjNjZGI0MTZmMGU3N2Q2ZWY5Y2ZmOWNkIiwiZ3JhbnRfdHlwZSI6InJlZnJlc2hfdG9rZW4iLCJzY29wZXMiOlsiUkVBRCJdLCJpYnNfY2xpZW50X2lkIjoiNjQzYmFlZTZhYzQzYjcwMDI0M2U2MTlkIiwiaWJzX3VzZXJfaWQiOiI2NDNiYWVlNmFjNDNiNzAwMjQzZTYxYTYiLCJpYXQiOjE2ODE2NDM5NDAsImV4cCI6MTY4OTQxOTk0MH0.akW_AkNC_9v0ygfYWMAURnUxk_Q6URs9bHaTsze5vBHi6Hb5ifCEYIiBmdDkOeUNRoRe1GJnZDmWmUT_vNsUViYrm10Oqh88x9STGPAuTvFYdSIdv86FzgRYFT9VIvY9tcZHwBxXfQJZsjyhl7h5CSYd7KhvAuFgFzJo5OnGWs4",
    //   "ibs_client_id": "643baee6ac43b700243e619d",
    //   "ibs_user_id": "643baee6ac43b700243e61a6"
    // }
  //   console.log(JSON.stringify({
  //     "grant_type":"client_credentials",
  //     "client_assertion_type" : "urn:ietf:params:oauth:client-assertion-type:jwt-bearer",
  //     "client_assertio":"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9eyJpc3MiOiJodHRwczovL21henVmbGV4LWFwcC5maXJlYmFzZWFwcC5jb20vIiwic3ViIjoiYzljM2Q4OGU2ZWQzMTljNzQzYzMyNzBkMzAwZmUzYTUiLCJhdWQiOiJodHRwczovL2FwaS5zYWZlaGF2ZW5tZmIuY29tIiwiaWF0IjoxNjgxNjQwNjIyfQ.ju31Gs9qgtSBSCJschDQEwFKbMmHGkiAn-l3T0ZiVbO1mgoBALhopd5ORXNZ7izARG_78JvdDNt_zdlTHOILtqI7CMihAlwRGdNVSx0PihOl3HZEYXhcg6o3pskHYSK4RHHPAUeYgLOAuNGbmol_x-_cIwSFNZ6izMM1glJQYwU",
  //   "client_id":"c9c3d88e6ed319c743c3270d300fe3a5",
  //   "refresh_token":"string"
  // }))

    // const v = datafile.filter((e)=>e.network === "mtn")

    // console.log(v)

    //   var config = {
    //     method: "patch",
    //     url: "https://api-d.squadco.com/virtual-account/update/beneficiary/account",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer sk_66150730ac370ee72b3e5763264a9e162e8290e4",
    //     },
        
    //     data: {
    //       "beneficiary_account" : "0771679118",
    //       "virtual_account_number" : "0771679118"
    //     },
    //   };
      
    //  axios(config)
    //     .then(async function (response) {
    //       console.log("this the response data", response);
         
    //     })

    // const getJson = (str, obj = {}) => {
    //   const marker = 'form-data; name="';
    //   const index = str.indexOf(marker);

    //   if (index >= 0) {
    //     const part = str.substring(index + marker.length);
    //     const prop = part.substring(0, part.indexOf('"'));
    //     const val = part.substring(prop.length + 2, part.indexOf(" --"));

    //     obj[prop] = val;
    //     return getJson(part.substring(prop.length+val.length+5), obj);
    //   }

    //   return obj;
    // };

    // const data =
    //   '"----------------------------105191190942001288470255 Content-Disposition: form-data; name="name" 1100029538 ----------------------------105191190942001288470255 Content-Disposition: form-data; name="county" 25 ----------------------------105191190942001288470255 Content-Disposition: form-data; name="phone" 123456789 ----------------------------105191190942001288470255-- "';
    // const json = getJson(data);
    // console.log(JSON.stringify(json));
    
  }, []);

  const handlerefesh = () => {
    window.location.reload();
  };

  return (
    <div className="App">
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Modal show={show} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Update Application</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Your Application is OUTDATED, kindly click on the update button to
              update your application..
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handlerefesh}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>
          <Router>
            <AuthProvider>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/login" exact component={LoginComponent} />
                <Route path="/register" exact component={Registercomponent} />
                <Route
                  path="/forget_password"
                  exact
                  component={ForgetPassword}
                />
                <Route path="/check_user" exact component={Validator} />
                <Route path="/privacy" exact component={Terms} />
                <Route path="/users/:id" exact component={Userverify} />
                <Route path="/contact" exact component={Contactuspag} />
                <Route path="/about" exact component={AboutUpage} />
                <PrivateRoute path="/v0/cable" component={AnaCable} />
                <PrivateRoute path="/v1/airtime" component={Aairtime} />
                <PrivateRoute path="/v2/electricity" component={Aelectricity} />
                <PrivateRoute path="/v3/data" component={Adata} />
                <PrivateRoute path="/v4/more" component={Amore} />
                <PrivateRoute path="/v5/betting" component={Abetting} />
                <PrivateRoute path="/more/service/neco" component={AnNeco} />
                <PrivateRoute path="/more/service/waec" component={Anwacepage} />
                <PrivateRoute path="/dashboard" exact component={Dashboard} />
                <Route path="/logout" exact component={Logout} />
                <Route path="/admin/logout" exact component={AdminLogout} />
                <PrivateRoute path="/cable" exact component={CableTV} />
                <PrivateRoute
                  path="/electricity"
                  exact
                  component={ElectricityPayment}
                />
                <PrivateRoute path="/more" exact component={MoreServices} />
                <PrivateRoute
                  path="/admin/auth/:uid"
                  exact
                  component={AdminDashboard}
                />
                <PrivateRoute
                  path="/transaction"
                  exact
                  component={TransactionPage}
                />
                <PrivateRoute path="/data" exact component={Datavending} />
                <PrivateRoute path="/wallet/history" exact component={WalletHistory} />
                <PrivateRoute path="/transaction/:id" exact component={Transactionsdetails} />
                <PrivateRoute path="/airtime" exact component={Airtime} />
                <PrivateRoute path="/buy/wace" exact component={Wacepage} />
                <PrivateRoute path="/buy/neco" exact component={Necopage} />
                <PrivateRoute path="/fund/wallet/betting" exact component={Betting} />
                <PrivateRoute path="/getallaccountGTb" exact component={SquardContent} />
            
                <PrivateRoute
                  path="/payment/notification"
                  exact
                  component={paymentnotify}
                />
                <PrivateRoute path="/admin/users" exact component={UserLog} />
                <PrivateRoute
                  path="/admin/Transaction"
                  exact
                  component={AdminTransactions}
                />
                <PrivateRoute
                  path="/admin/management"
                  exact
                  component={Managementpage}
                />
                <PrivateRoute
                  path="/neco/create"
                  exact
                  component={CreateNeco}
                />
                <PrivateRoute
                  path="/Partner-with-us"
                  exact
                  component={BecomeAgent}
                />
                <PrivateRoute path="/get/income" exact component={GetimcomeValue} />
                <PrivateRoute path="/support" exact component={UseConatc} />
                <PrivateRoute path="/settings" exact component={Settings} />
                <PrivateRoute path="/api/management" exact component={ApiComponet} />
                <PrivateRoute path="/api/transaction" exact component={ApiTransaction} />
                <PrivateRoute path="/squard/management" exact component={SanQuard} />
                <PrivateRoute
                  path="/agent/dashboard"
                  exact
                  component={Agentindexpage}
                />
                <PrivateRoute
                  path="/register/agent"
                  exact
                  component={PendingAgent}
                />
                <PrivateRoute
                  path="/get/issue"
                  exact
                  component={IssueReportPage}
                />

                <Route path="/mezuflex/login" exact component={AdminLogin} />
                <Route
                  path="/mezuflex/register"
                  exact
                  component={AdminRegister}
                />
                <Route path="/auth/:id" exact component={PinSetup} />
                <Route path="/fund/request" exact component={FundUser} />
              </Switch>
            </AuthProvider>
          </Router>
        </>
      )}
    </div>
  );
}

export default App;
