import React from 'react'
import Sidebar from '../Component/ClientComponent/Sidemenu';
import '../App.css'
import Logincomponent from '../Component/ClientComponent/LoginComponent';

function Home() {

  return (
    <div  className='pageindexcloser' style={{backgroundImage: "url(/img/comic4.jpg)"}}>
     <div className="allwraper neaals">
     <div className="allitems">
      <Sidebar/>
      </div>
      <div className="allitemsksks">
      <Logincomponent/>
      </div>
     </div>
    </div>
  )
}

export default Home