import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from './Service/UserAuth';

function Logout() {
    const { logout } = useAuth();
    const history =  useHistory()
   
   
    useEffect(() => {
        (async () => {
            await logout();
            history.push("/mezuflex/login");
          })();
    }, [])
    
  return (
    <div>Logout</div>
  )
}

export default Logout