import React from 'react'
import Sidebar from '../../../Component/ClientComponent/Sidemenuuser';
import '../../../App.css'
import AgentDash from '../Admincomponent/AgentDash';


function Agentindexpage() {

  return (
    <div  className='pageindexcloser' >
     <div className="allwraper">
     <div className="allitems" style={{backgroundImage: "url(/img/comic4.jpg)"}}>
     <div className="allsjuijd">
     <Sidebar/>
     </div>
      </div>
      <div className="allitemsksksnew">
      <AgentDash/>
      </div>
     </div>
    </div>
  )
}

export default Agentindexpage