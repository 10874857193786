import React, { useState } from "react";
import { FaBox, FaBars } from "react-icons/fa";
import {
  MdNetworkWifi,
  MdOutlineCall,
  MdMoney,
  MdContactMail,
  MdSchool
  
} from "react-icons/md";
import { GiElectric } from "react-icons/gi";
import { IoMdTv } from "react-icons/io";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./Sidemenu.css";
import { getuser } from "../../Service/GetUser.service";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const history  =  useHistory()
  const [user, setuser]= useState([])

  getuser("iu4WWdVD3GeRe9373RpDm25zN4M2", (users) => {
    if (users) {
      setuser(users);
    }
  });

  const handleagent = ()=>{
    history.push("/Partner-with-us")
  }

  const menuItem = [
    {
      path: "/v3/data",
      name: "Get Cheap Data",
      icon: <MdNetworkWifi />,
      Tittle: "Buy Data",
    },
    {
      path: "/v1/airtime",
      name: "Get Airtime",
      icon: <MdOutlineCall />,
      Tittle: "Buy Airtime",
    },
    {
      path: "/v2/electricity",
      name: "Vend Electricity",
      icon: <GiElectric />,
      Tittle: "Electricity",
    },

    {
      path: "/v0/cable",
      name: "Cable Subscription",
      icon: <IoMdTv />,
      Tittle: "Cable",
    },

    {
      path: "/v5/betting",
      name: "Betting",
      icon: <MdMoney />,
      Tittle: "Betting",
    },

    {
      path: "/v4/more",
      name: "Result Checker",
      icon: <MdSchool />,
      Tittle: "Result Checker",
    },

    {
      path: "/contact",
      name: "Contact",
      icon: <MdContactMail />,
      Tittle: "Contact",
    },
    {
      path:"/about",
      name: "About",
      icon: <FaBox />,
      Tittle: "About",
    },

  ];

const handleRegister = () =>{
 history.push("/register")
  }

  const handlelogin =  ()=>{
    history.push("/login")
  }

  const handleProlic = ()=>{
  history.push("/privacy")
  }
  return (
    <>
      <div className="containerside">
        <div style={{ width: isOpen ? "250px" : "159px" }} className="sidebar">
          <div className="besy">
            <FaBars onClick={toggle} />
          </div>
          <div className="top_section">
            <center>
              {" "}
            <Link to="/">
            <img
                src="/img/logo.png"
                alt=""
                style={{ height: isOpen ? "15vh" : "10vh" }}
                className="logo"
              /></Link>
            </center>
            <div
              style={{ marginLeft: isOpen ? "50px" : "0px" }}
              className="bars"
            ></div>
          </div>
          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className={isOpen ? "link" : "islink"}
              activeclassName="activec"
            >
              <div className="icon">
                <div className="iconsimage">{item.icon} </div>

                <div
                  className="iconsimage"
                  style={{ display: isOpen ? "none" : "block" }}
                >
                  {item.Tittle}
                  <br />
                </div>
              </div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
        
        <main>{children}</main>

        <div className="toparseiddt" >
          <div className="supermenue">
            <div className="suoermenr hide" onClick={handleRegister}>
              <p className="aksj">Join Us</p>
            </div>
            <div className="suoermenr hide" onClick={handlelogin}>
              <p className="aksj">Login</p>
            </div>
            <div className="suoermenr hide" onClick={handleagent}>
              <p className="aksj">Become an Agent</p>
            </div>
            <div className="suoermenr hide" onClick={handleProlic}>
              <p className="aksj">Privacy Policy</p>
            </div>
            {/* <div className="suoermenr hide">
              <p className="aksj">Coin Earning</p>
            </div>
            <div className="suoermenr hide">
              <p className="aksj"> Transactions</p>
            </div>
            <div className="suoermenr hide">
              <p className="aksj">Wallet</p>
            </div>
            <div className="suoermenr mmmmhhh ">
              <div className="ianmehssj"  style={{ display: isOpen ? "none" : "block" }}>
                <img src="/img/logo1.png" alt="" />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="containerside2">
        <div style={{ display: isOpen ? "block" : "none" }} className="sidebar1">
          <div className="top_section">
            <center>
              {" "}
            <Link to="/">
            <img
                src="/img/logo.png"
                alt=""
                style={{ height: isOpen ? "15vh" : "10vh" }}
                className="logo"
              /></Link>
            </center>
            <div
              style={{ marginLeft: isOpen ? "50px" : "0px" }}
              className="bars"
            ></div>
          </div>

          <div className="allitemshw" >
            <div className="walleatside">
              <div className="supermenuemega">
                  <div className="suoermenrmega " onClick={handlelogin}>
                    <span className="aksj">Login</span>
                  </div>
                </div>
              </div>
          
          </div>

          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className={isOpen ? "link" : "islink"}
              activeclassName="activec"
            >
              <div className="icon">
                <div className="iconsimage">{item.icon} </div>

              </div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
        <main>{children}</main>

        <div className="toparseiddt111" >
          <div className="supermenue1111">
            <div className="suoermenr111 ">
            <div className="besy111">
            <FaBars onClick={toggle} />
          </div>
          
              <div className="ianmehssj"  style={{ display: isOpen ? "block" : "block" }}>
                <img src="/img/logo1.png" alt="" />
              </div>
            </div>
          </div>
          
        </div>
       
      </div>
      
      
    </>
  );
};

export default Sidebar;
