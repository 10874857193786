import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react'
import { Form, Modal, Tab, Tabs, Table, Button, Dropdown } from 'react-bootstrap';

import {FcExpand} from "react-icons/fc"
import { getAllAgent } from '../../../Service/GetUser.service';
import { useAuth } from '../../../Service/UserAuth';
import { database } from '../../../firebase';
import axios from "axios";
import "./Log.css"
import { Link } from 'react-router-dom';

function PendingAgent() {
    const [search, setsearch] =  useState("")
    const [loading, setloading] = useState(false)
    const [userCollection, setuserCollection] = useState([]);
    const [lastDocs, setlastDocs] = useState();
    const [isEmpty, setisEmpty] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [pins, setpins] =  useState([])
    const [trxt, settransaction] =  useState([])
    const { loginuser } = useAuth();
    const topUrl = "https://us-central1-mazuflex-app.cloudfunctions.net/getTopUp";

   
    useEffect(() => { 
        getAllAgent().then((collections) => {
          const agents = collections.filter((e)=>e.isAgent ===  true)
         
            updateState(agents);
          });
    }, [])
    
    const updateState = (users) => {
        const isItemEmpty = users.length === 0;
        if (!isItemEmpty) {
          const lastDoc = users[users.length - 1];
          setuserCollection(users);
          setlastDocs(lastDoc);
        } else {
          setisEmpty(true);
        }
        setloading(false);
      };
      const fetchMore = () => {
        setloading(true);
        getAllAgent(lastDocs).then((collections) => {
          updateState(collections);
        });
      };

    const Togglemodal = useCallback(
        (item) => () => {
           
          handleShow();
          settransaction(item)
        },
        []
      );


  
      const handlresolve = async(e)=>{
        e.preventDefault()

        if (trxt.isTreated ===  true){
            window.alert("Already treated")
            return
        }

        const prompt = window.prompt("Enter Pin")
        if (prompt !== loginuser.pin){
            window.alert("Invalid pin, try again")
            return
        }
      setloading(true)
     try {
        await  database
        .doc(`/PendingApproval/${trxt.id}`)
        .update({
            isTreated: true,
        })
        const data = {
            userID : loginuser.id,
            id :trxt.userID
        }
        await axios.put("https://us-central1-mazuflex-app.cloudfunctions.net/CreateSuperAgent", {data :data}).then((e)=>{
        
            window.alert("Request Accepted")
            // window.location.href="/admin/Transaction"
        })
     } catch (error) {
        console.log(error.message)
     }

       
      }


    
  return (
    <div>
         <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* {item.firstname} {item.lastname} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="home"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Request Information">
            <div className="tabrwapper">
         
            <div className="userqwrapper">
              <div className="infologcontent">
                <div className="intitlelog">Transaction ID:</div>
                <div className="ingolog">{trxt.id}</div>
              </div>

              <div className="infologcontent">
                <div className="intitlelog">Full Name:</div>
                <div className="ingolog"> {trxt.fullname}</div>
              </div>

              <div className="infologcontent">
                <div className="intitlelog">Mobile No:</div>
                <div className="ingolog">{trxt.phone}</div>
              </div>

            

              <div className="infologcontent">
                <div className="intitlelog">Status:</div>
                <div className="ingolog">{trxt.isTreated ===  false ? " Pending" : "Approved"}</div>
              </div>

 
              <div className="infologcontent">
                <div className="intitlelog">Image:</div>
                <div className="ingolog">
                    <img src={trxt.image} alt="" />
                </div>
              </div>
             
            </div>
          </div>
          </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {trxt.status  !== "success" ? 
          <Dropdown>
            <Dropdown.Toggle variant="info" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={handlresolve} disabled={loading}>
                Accept
              </Dropdown.Item>
              <Dropdown.Item className="text-danger my-2" onClick={handleClose} disabled={loading}>
                Reject 
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          : ""}
       
        </Modal.Footer>
      </Modal>
         <div className="logcontainer">
        <div className="logcontent">
          <div className="logitems">
            <div className="logheader">Agent Register</div>
            <div className="logsearcharea">

              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="search"
                  onChange={(event) => {
                    setsearch(event.target.value);
                  }}
                />
              
              </Form.Group>

<Link to="/getallaccountGTb">See User Account Details</Link>
             
            </div>
            <div className="logotable">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th className='hide'>Date</th>
                    <th>Full Name</th>
                    <th className='hide'>Phone</th>                    
                    <th className='hide'>Status</th>
                    <th>View More</th>
                  </tr>
                </thead>
                <tbody>
                  {userCollection
                    .filter((val) => {
                      if (search === "") {
                        return val;
                      } else if (val.phone.includes(search)) {
                        return val;
                      } else if (
                        val.firstname
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return val;
                      }
                      else if (
                        val.number
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return val;
                      }
                      else if (
                        val.email
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return val;
                      }
                       
                    })
                    .map((value, id) => {
                    
                      return (
                        <tr key={id}>
                          <td>{id + 1}</td>
                          <td className='hide'>
                            {value.created
                              ? format(value.created, "dd/MM/yyyy hh:mm a")
                              : ""}
                          </td>
                          <td>
                            {value.firstname}  {value.lastname} 
                          </td>
                          <td>{value.number}</td>
                          <td className='hide'>{value.isTreated ===  false ? "Pending" : "Approved"}</td>
                          <td onClick={Togglemodal(value)}>
                            <FcExpand />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {loading && <div className="text-center"> loading ... </div>}
                  {!loading && !isEmpty && (
                    <div className="text-center newlogbuttin">
                      <button onClick={fetchMore} >see more</button>
                    </div>
                  )}
                  {isEmpty && <h1 className="hshhs">no more data to fetch</h1>}
               
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PendingAgent