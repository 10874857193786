import React from 'react'
import Sidebar from '../../../../Component/ClientComponent/Sidemenuuser';
import '../../../../App.css'
import Becomecomponent from './Becomecomponent';


function BecomeAgent() {

  return (
    <div  className='pageindexcloser' >
     <div className="allwraper">
     <div className="allitems" style={{backgroundImage: "url(/img/comic4.jpg)"}}>
     <div className="allsjuijd">
     <Sidebar/>
     </div>
      </div>
      <div className="allitemsksksnew">
      <Becomecomponent/>
      </div>
     </div>
    </div>
  )
}

export default BecomeAgent