import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal, Table, Tabs } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import { getRandomString } from "../../Service/GetRandomNumber";
import { useAuth } from "../../Service/UserAuth";
import { ToastContainer, toast } from "react-toastify";
import "./Setting.css";
import { FaSpinner } from "react-icons/fa";
import firebase from "firebase";
import { Link, useHistory } from "react-router-dom";
import { format } from "date-fns";
import { getuser } from "../../Service/GetUser.service";
import { getApiTransaction } from "../../Service/Admin.services";
import { formatNumber } from "../../Service/Utility";

function UserAccountSettings() {
  const { loginuser } = useAuth();
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentpassword, setcurrentpassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  var user = firebase.auth().currentUser;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const { uid } = useAuth().currentUser;
  const [users, setuser] = useState([]);
  const [userCollection, setuserCollection] = useState([]);
  const [lastDocs, setlastDocs] = useState();
  const [isEmpty, setisEmpty] = useState(false);

  //  firebase.auth.EmailAuthProvider.credential()

  useEffect(() => {
    setToken(loginuser.Token);
  }, []);

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      toast.success("Copied!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      toast.success("Failed to copy!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleGenrate = async (e) => {
    e.preventDefault();
    const gen = getRandomString(
      35,
      "zxcvbnmasdfghjklqwertyuiopZXCVBNMASDFGHJKQWERTYUIOP1234567890"
    );
    const Token = `${loginuser.id}${gen}`;
    setToken(Token);
    if (loginuser.permissions !== "super agent") {
      toast.success(
        "Only super agent can access this services at the moment.please contact support",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return;
    }
    const data = {
      userID: loginuser.id,
      token: Token,
    };
    setLoading(true);
    await axios
      .put("https://us-central1-mazuflex-app.cloudfunctions.net/getsetAgent", {
        data: data,
      })
      .then((e) => {
        toast.success(e.data.mess, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    setLoading(false);
  };

  const handleChane = (e) => {
    e.preventDefault();

    if (newpassword !== confirmpassword) {
      toast.error("Password Mismatch", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const cred = firebase.auth.EmailAuthProvider.credential(
      loginuser.email,
      currentpassword
    );
    user
      .reauthenticateWithCredential(cred)
      .then((e) => {
        // console.log(e)
        return user.updatePassword(newpassword);
      })
      .then(() => {
        toast.success("Password updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        toast.success(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    getuser(uid, (users) => {
      setuser(users);
      getApiTransaction(users.id).then((collections) => {
        updateState(collections);
      });
    });
  }, []);

  const updateState = (users) => {
    const isItemEmpty = users.length === 0;
    if (!isItemEmpty) {
      const lastDoc = users[users.length - 1];
      setuserCollection(users);
      setlastDocs(lastDoc);
    } else {
      setisEmpty(true);
    }
    setLoading(false);
  };
  const fetchMore = () => {
    setLoading(true);
    getApiTransaction(user.id, lastDocs).then((collections) => {
      updateState(collections);
    });
  };

  return (
    <div>
      <ToastContainer />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>Contact support to get available API's</Modal.Body>
      </Modal>
      <div className="settingconatienr">
        <div className="settingconetbmr">
          <Tabs
            defaultActiveKey="profile"
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="home" title="API">
              {loginuser.permissions === "user" ? (
                <>
                  <p className="text-dark">
                    Service available to verified users
                  </p>
                  <Link to="/Partner-with-us">
                    <Button variant="info">Become an agent</Button>
                  </Link>
                </>
              ) : (
                <div className="contianeref">
                  <div className="intoablepe text-dark">
                    <h4>Mezuflex APIs</h4>
                    <p>
                      Mezuflex authenticates your API requests using your
                      account’s Mezuflex Token. See our documentation to learn
                      more about your Mezuflex Token.
                    </p>
                  </div>
                  <div className="settinfmaindite text-black">
                    Token
                    <InputGroup className="mb-3">
                      <Form.Control
                        value={token ? token : "Click to generate token"}
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <InputGroup.Text
                        id="basic-addon2"
                        className="handlebuttontoken"
                        onClick={() => copyToClipBoard(token)}
                      >
                        Copie
                      </InputGroup.Text>
                    </InputGroup>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleGenrate}
                      disabled={loading}
                    >
                      {loading ? (
                        <FaSpinner animation="grow" variant="light" />
                      ) : (
                        "Generate Mezuflex_Token"
                      )}
                    </Button>
                    <Button
                      variant="success"
                      type="submit"
                      className="ms-2"
                      onClick={handleShow}
                      disabled={loading}
                    >
                      Go to Documemntation
                    </Button>
                  </div>
                </div>
              )}
            </Tab>
            <Tab eventKey="profile" title="Security">
              <div className="serritycontener">
                <div className="securitdondej">
                  <div className="intoablepe text-dark">
                    <h4>Reset Password</h4>
                    <p>
                      Keep your password safe. We recommend you change your
                      password periodically.
                    </p>
                  </div>

                  <div className="servicesprodictyaond">
                    <div className="prodictond">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          onChange={(event) => {
                            setcurrentpassword(event.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          onChange={(event) => {
                            setnewpassword(event.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label> Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          onChange={(event) => {
                            setconfirmpassword(event.target.value);
                          }}
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        onClick={handleChane}
                      >
                        Change Password
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Transaction" title="Transaction">
              <div className="serritycontener">
                <div className="securitdondej">
                  <div className="showtransactions">
                    <div className="transactionprijhse">
                      {userCollection.length === 0 ? (
                        <>
                          <center> No record found</center>
                        </>
                      ) : (
                        <Table striped="columns">
                          <thead>
                            <tr className="bg-white">
                              <th>S/N</th>
                              <th className="hide">Data/Time</th>
                              <th>Service</th>
                              <th className="hide">Amount</th>
                              <th>Balance</th>
                              <th className="hide">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userCollection.map((item, id) => {
                              return (
                                <tr key={id} className="bg-light cuspointer">
                                  <td>{id + 1}</td>
                                  <td className="hide">
                                    {item.created
                                      ? format(
                                          item.created,
                                          "dd/MM/yyyy hh:mm a"
                                        )
                                      : ""}
                                  </td>

                                  <td className="hide">{item.company}</td>
                                  <td>
                                    &#8358; {formatNumber(item.amount)} <br />
                                    <span className="ksjdhdmobid">
                                      {item.company}
                                    </span>
                                  </td>

                                  <td className="hide">
                                    {item.postBalance ? (
                                      <>
                                        &#8358; {formatNumber(item.postBalance)}
                                      </>
                                    ) : (
                                      0
                                    )}{" "}
                                  </td>
                                  <td className="hide">{item.status}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      )}
                      {loading && (
                        <div className="text-center"> loading ... </div>
                      )}
                      {!loading && !isEmpty && (
                        <div className="text-center textsss">
                          <button onClick={fetchMore}>see more</button>
                        </div>
                      )}
                      {isEmpty && (
                        <h1 className="hshhs">no more data to fetch</h1>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="longer-tab" title="General">
              <div className="generalcontainresult">
                <div className="genralldldconetainer">
                  <div className="intoablepe text-dark">
                    <h4>General Information</h4>
                    <p>
                      These are the API products on Mezuflex to which you have
                      access. Your product offerings are determined by the
                      compliance check that was done on your account. Please,
                      contact your support to learn more.
                    </p>
                  </div>

                  <div className="servicesprodictyaond">
                    <div className="prodictond">
                      <p>SEND SMS API</p>

                      <p>SEND EMAIL API</p>

                      <p>SME DATA API</p>

                      <p>BVN VERIFICATION API</p>

                      <p>AIRTIME API</p>

                      <p>CABLE TV SUBSCRIPTION API</p>

                      <p>ELECTRICITY VENDING API</p>
                    </div>
                  </div>

                  <div className="intoablepe text-dark">
                    <h4>Currency</h4>
                    <p>
                      These are all the currencies with which you are allowed to
                      transact on Mezuflex. Your currencies are determined by
                      the Verification check done on your account. Please,
                      contact your account manager to find out more.
                    </p>
                  </div>

                  <div className="servicesprodictyaond">
                    <div className="prodictond">
                      <span>NAIRA</span>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default UserAccountSettings;
