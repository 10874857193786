
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import "../Servicescomponent/Services.css";
import Mtn from "../../Dataprice.json";
import Glo from "../../glo.json";
import Airtel from "../../airtel.json";
import Mobile from "../../9mobile.json";
import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { PaystackButton } from "react-paystack";
import Inloader from "../../Inloader"
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../Service/UserAuth";
import { database } from "../../firebase";
import { formatNumber } from "../../Service/Utility";
import { functions } from "../../firebase";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import { format } from "date-fns";
const walleturl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/getuserwallet";


function AnaData() {
    const [selectednetwork, setselectednetwork] = useState("mtn");
    const [image, setimage] = useState("/img/gotv.png");
    const [mtn] = useState(Mtn);
    const [airtel] = useState(Airtel);
    const [glo] = useState(Glo);
    const [mobil] = useState(Mobile);
    const [b, setb] = useState("");
    const [datatable, setdatacable] = useState([]);
    const [phoneea, setphone] = useState("");
    const {unknow} = useAuth();
    const [networkboundle, setboundle] = useState([]);
    const [bundleprice, setbundleprice] = useState(0);
    const [loading, setloading] = useState(false);
    const [cablerror, setcableerroresponse] = useState("");
    const [cableresponse, setcableresponse] = useState([]);
    const [responspayload, setresponsepayload] = useState("");
    const [payload, setpayload] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [v, setv] = useState(false);
    const history = useHistory();
    const [status, setstatus] = useState("");
    const [awaiting, setwaiting] = useState(false)
    const [code, setboundconde] = useState("");
    const [showpayload, setShowpayload] = useState(false);
    const [disable, setdisable]= useState(false)
    const handleClosepayload = () => {
      setShowpayload(false);
      window.location.href ="/v3/data";
    };
    const handleShowpayload = () => setShowpayload(true);

    
  
    const m = mtn.data;
    const g = glo.data;
    const a = airtel.data;
    const e = mobil.data;
  
    useEffect(() => {
      history.push('/login')
      const result = networkboundle.find((name) => name.bundle === b);
      if (result) {
        const price = result.price;
        setbundleprice(price);
        const boundlecode = result.code;
        setboundconde(boundlecode);
      }
      setdatacable(result);
  
      if (selectednetwork === "mtn") {
        setboundle(m);
      } else if (selectednetwork === "glo") {
        setboundle(g);
      } else if (selectednetwork === "airtel") {
        setboundle(a);
      } else if (selectednetwork === "etisalat") {
        setboundle(e);
      } else {
        setboundle("");
      }
  
      if (selectednetwork === "mtn") {
        setimage("/img/mtn.png");
      } else if (selectednetwork === "glo") {
        setimage("/img/glo.png");
      } else if (selectednetwork === "airtel") {
        setimage("/img/airtel.png");
      } else if (selectednetwork === "etisalat") {
        setimage("/img/9mobile.png");
      } else {
        setimage("/img/mtn.png");
      }
    }, [image, selectednetwork, b, unknow, bundleprice]);
  
    

    const handleparkage = (e) => {
        setb(e.target.value);
      };
    

    const ref = database.collection("_").doc().id;


  const date =   format(new Date(), "dd/MM/yyyy hh:mm a")
  const phone = phoneea 


const config = {
  reference: new Date().getTime().toString(),
  email: `mezuflex@gmail.com`,
  amount: bundleprice * 100,
  publicKey: "pk_live_7c49d4df21fc6207a1ece0514092b34ad3f5bbee",
};

const handlePaystackSuccessAction = async (data) => {

 
  const verifyPaymentHandler = functions.httpsCallable(
    "verifyPaystackPayment"
  );

 
  const value = {
    ref: data.reference,
    status: data.success,
    firstname: "Customer",
    lastname: "Mezuflex",
    email: "juliuskingsley1100@gmail.com",
    userId: "iu4WWdVD3GeRe9373RpDm25zN4M2",
    phone: "08165459398",
    amount: bundleprice,
  };

  setwaiting(true)
  await verifyPaymentHandler(value).then(async (result) => {
   
    if (result === null || result === undefined) {
      return (
        <>
          <Inloader />
        </>
      );
    }
    if (result.data.data.status === "success") {

  
      const ref = database.collection("_").doc().id;
    
     const data1 = {
        id : ref,
        company : selectednetwork, 
        customername: payload.name,
        address: "no value",
        userID : "iu4WWdVD3GeRe9373RpDm25zN4M2",
        AccountNumber : phoneea,
        walletbalance : 0,
        token : "no value", 
        debt : "no value", 
        amount: bundleprice, 
        phone: phone,
        type : "Power", 
        cablerror,
        status : "Network Error",
        created: firebase.firestore.FieldValue.serverTimestamp()
      }
      // make the payment for the user
      setresponsepayload("Connecting service ..");
      await axios
        .post(
          "https://us-central1-mazuflex-app.cloudfunctions.net/buyData",
          {
            network: payload.network,
            bundle: payload.bundle,
            number: payload.number,
            referrence: payload.referrence,
          }
        )
        .then(async (e) => {
          setdisable(true)
          setresponsepayload("finalizing connection. Please wait ");
          if (e.data.status === "success") {
            handleClose();
            setcableresponse(e.data.data);
            setstatus("success");
            handleShowpayload();
            const data = {
              id: ref,
              company: payload.network,
              customername: phone,
              address: "no value",
              userID: "iu4WWdVD3GeRe9373RpDm25zN4M2",
              AccountNumber: phone,
              walletbalance: 0,
              amount: bundleprice,
              phone: phone,
              payloadres: e.data.data,
              isToday:true,
              type: "DATA",
              status: "success",
              created: firebase.firestore.FieldValue.serverTimestamp(),
            };
            await database
              .collection("Transaction")
              .doc(data.id)
              .set(data);
          } else {
            handleClose();
            setcableerroresponse(e.data.data);
            setstatus("error");
            handleShowpayload();
            await database
              .collection("Transaction")
              .doc(data1.id)
              .set(data1);
          }
          setdisable(false)
        })
        .catch((e) => {
    
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      // Setpaymentpayload(result.data.data);
      // setshowvalue(true);
      handleClose();
    } else {
      // Setpayload(result.data.data);
      handleClose();
      handleShowpayload();
    }
  }).catch((e)=>{

    toast.error(e.message, {
      position: toast.POSITION.TOP_RIGHT,
    })
  });

};

const handlePaystackCloseAction = () => {
  history.goBack();
};

const componentProps = {
  ...config,
 text: loading ? "Proccessing request" : "Pay with Paystack",
  onSuccess: (data) => handlePaystackSuccessAction(data),
  onClose: () => handlePaystackCloseAction(),
};

  const handleverifier = async (e) => {
    e.preventDefault();

    if (isNaN(phone)){
      toast.error("Number Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (phoneea === "") {
      toast.error("Number Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (b === "") {
      toast.error("Bad format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const data = {
      network: selectednetwork,
      bundle: b,
      number: phone,
      referrence: ref,
    };

    setpayload(data);
    handleShow();
    setloading(false);
  };
  return (
    <div>
      <ToastContainer />
      <Modal
        centered
        backdrop="static"
        show={showpayload}
        onHide={handleClosepayload}
        animation={false}
      >
        <Modal.Body>
          {status === "error" ? (
            <>
              <div className="headersjdjd bg-danger">
                <h1>ERROR</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectednetwork ? selectednetwork.toUpperCase() : ""}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Bundle :</div>
                  <div className="infodetails">{payload.bundle}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Number :</div>
                  <div className="infodetails">{payload.number}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Price :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(bundleprice)}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Message :</div>
                  <div className="infodetails">
                    Transaction Failed, Please try again later
                  </div>
                </div>
                <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    disabled={disable}
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button className="w-100 m-1" disabled={disable} type="submit">
                    Print
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="headersjdjd bg-success">
                <h1>SUCCESS</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectednetwork ? selectednetwork.toUpperCase() : ""}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Bundle :</div>
                  <div className="infodetails">{payload.bundle}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Number :</div>
                  <div className="infodetails">{payload.number}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Price :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(bundleprice)}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Message :</div>
                  <div className="infodetails">Transaction Successful</div>
                </div>
                <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button className="w-100 m-1" type="submit">
                    Print
                  </Button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        centered
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div className="headersjdjd">
          <h1>In-Progress</h1>
          </div>
          <p>Kindly confirm the information below before you proceed</p>
          <div className="infocontainer">
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">
                {selectednetwork ? selectednetwork.toUpperCase() : ""}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Bundle :</div>
              <div className="infodetails">{payload.bundle}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Number :</div>
              <div className="infodetails">{payload.number}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Price :</div>
              <div className="infodetails">
                &#8358; {formatNumber(bundleprice)}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">{date}</div>
            </div>
          </div>
        </Modal.Body>

        <center className="info">{responspayload}</center>
        <center> {awaiting ? <Spinner animation="border" variant="warning" /> : ""}</center>
        <div className="d-flex ddd">
        <PaystackButton {...componentProps} />

          <button
            variant="danger"
            onClick={handleClose}
            className=" ms-1 w-50 cnsnddd"
            type="submit"
          >
            Back
          </button>
        </div>
      </Modal>
      <div className="allcablesyns">
        <div className="allcableitems">
          <div className="cableallre">
            <div className="imagesetuib">
              <img src={image} alt="" />
            </div>
            <div className="productsed">
              <h2>
                <center className="text-dark">DATA SUBSCRIPTION</center>
              </h2>
              <Form.Label className="text-dark my-3">Buy Data</Form.Label>
              <FloatingLabel
                controlId="floatingSelect"
                onChange={(event) => {
                  setselectednetwork(event.target.value);
                }}
              >
                <Form.Select aria-label="Floating label select example">
                  <option value="mtn">MTN</option>
                  <option value="glo">GLO</option>
                  <option value="airtel">AIRTEL</option>
                  <option value="etisalat">9Mobile</option>
                </Form.Select>
              </FloatingLabel>
              <div className="optionshhd">
                <Form.Label className="text-dark my-3">
                  Select your preferred Package
                </Form.Label>
                <Form.Select
                  value={b}
                  required
                  aria-label="Floating label select example"
                  onChange={handleparkage}
                >
                  <option key="">Please Select Parkage</option>
                  {networkboundle.map((b) => (
                    <option key={b.code}>{b.bundle}</option>
                  ))}
                </Form.Select>
                <Form.Label className="text-dark my-1">
                  Price : &nbsp; &#8358;{formatNumber(bundleprice)}
                </Form.Label>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-dark my-2">
                  Recipient
                  </Form.Label>
                  <Form.Control
                    
                    type="email"
                    onChange={(event) => {
                      setphone(event.target.value);
                    }}
                  />
                </Form.Group>
                <Button
                  variant={loading ? "warning" : "primary"}
                  disabled={loading}
                  onClick={handleverifier}
                  className="my-2 w-100"
                  type="submit"
                >
                  {loading ? (
                    <>
                      {" "}
                      <Spinner animation="border" size="sm" /> {responspayload}
                    </>
                  ) : (
                    "Buy Data"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnaData