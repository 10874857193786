

import React, { useCallback, useEffect, useState } from "react";
import "../../../Component/ClientComponent/Dashoard.css";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../../Service/UserAuth";
import { formatNumber } from "../../../Service/Utility";
import { Dropdown, Form, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { format } from "date-fns";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import firebase from "firebase";
import { database } from "../../../firebase";
import { getAllTransaction, getApiTransaction, getuser, getuserwallet } from "../../../Service/GetUser.service";
import Navbar from "./NavbarAdmin";
import axios from "axios";
import { FcExpand } from "react-icons/fc";
function TransactionLog() {
  const history = useHistory();
  const [userCollection, setuserCollection] = useState([]);
  const [show, setShow] = useState(false);
  const [search, setsearch] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  const [loading, setloading] = useState(false);
  const [pins, setpins] = useState([]);
  const [trxt, settransaction] = useState([]);
  const [userinfo, setuserinfo] = useState([]);
  const [userwallet, setuserwallet] = useState([]);
  const { loginuser } = useAuth();

  const [currentPage, setcurrentpage] = useState(1);
  const topUrl = "https://us-central1-mazuflex-app.cloudfunctions.net/getTopUp";
  const recordpage = 10;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = userCollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(userCollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  useEffect(() => {
    getApiTransaction((collections) => {
        setuserCollection(collections);
      });

  }, []);

  
  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };


  // const date = format(new Date(), "dd/MM/yyyy hh:mm a");
  // const Togglemodal = useCallback(
  //   (item) => () => {
  //     handleShow(item.id);
  //     setitem(item);
  //     const pins =
  //       item.type === "Wace e-PIN" || item.type === "exam e-PIN"
  //         ? item.payloadres.pins
  //         : [];
  //     setpin(pins);
  //   },
  //   []
  // );
  const Togglemodal = useCallback(
        (item) => () => {
          handleShow();
          const pin = item.payloadres ? item.payloadres : [];
          setpins(pin);
          if (pin) {
            setpins(pin.pins);
          }
          settransaction(item);
    
          getuser(item.userID, (result) => {
           
            setuserinfo(result);
          });
    
          getuserwallet(item.userID, (result) => {
           
            setuserwallet(result);
          });
        },
        []
      );
    
      const handlresolve = async (e) => {
        e.preventDefault();
    
        const prompt = window.prompt("Enter Pin");
        if (prompt !== loginuser.pin) {
          window.alert("Invalid pin, try again");
          return;
        }
        setloading(true);
        await database
          .doc(`/Transaction/${trxt.id}`)
          .update({
            status: "success",
          })
          .then((e) => {
            window.alert("reversed successfully");
          });
      };
      const handlereverse = async () => {
        const prompt = window.prompt("Enter Pin");
        if (prompt !== loginuser.pin) {
          window.alert("Invalid pin, try again");
          return;
        }
        setloading(true);
        await axios
          .post(topUrl, {
            userID: loginuser.id,
            id: trxt.userID,
            amount: parseFloat(trxt.amount),
          })
          .then(async (e) => {
          await  database
              .doc(`/Transaction/${trxt.id}`)
              .update({
                status: "Reverse",
              })
            window.alert(e.data.message);
            setloading(false);
          });
        setloading(false);
      };
  // exam e-PIN"
  return (
    <div>
        <Navbar/>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{/* {item.firstname} {item.lastname} */}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="home"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Transaction Details">
              <div className="tabrwapper">
                <div className="userqwrapper">
                  <div className="infologcontent">
                    <div className="intitlelog">Transaction ID:</div>
                    <div className="ingolog">{trxt.id}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Service Type :</div>
                    <div className="ingolog"> {trxt.company}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Cutsomer Name:</div>
                    <div className="ingolog">{trxt.customername}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Account Number:</div>
                    <div className="ingolog">{trxt.AccountNumber}</div>
                  </div>

                  {trxt.token ? (
                    <div className="infologcontent">
                      <div className="intitlelog">Token:</div>
                      <div className="ingolog">{trxt.token}</div>
                    </div>
                  ) : (
                    ""
                  )}

                  {trxt.token ? (
                    <div className="infologcontent">
                      <div className="intitlelog">Unit:</div>
                      <div className="ingolog">{trxt.unit}</div>
                    </div>
                  ) : (
                    ""
                  )}

                  {trxt.token ? (
                    <div className="infologcontent">
                      <div className="intitlelog">Debt:</div>
                      <div className="ingolog">{trxt.debt}</div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="infologcontent">
                    <div className="intitlelog">Address:</div>
                    <div className="ingolog">{trxt.address}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Mobile No.:</div>
                    <div className="ingolog">{trxt.phone}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Amount:</div>
                    <div className="ingolog">
                      &#8358; {formatNumber(trxt.amount)}
                    </div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Status:</div>
                    <div className="ingolog">{trxt.status}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Date :</div>
                    <div className="ingolog">
                      {" "}
                      {trxt.created
                        ? format(trxt.created, "dd/MM/yyyy hh:mm a")
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="profile" title="UserProfile">
              <div className="userprofilesose">
                <div className="userprofileicon">
                  <img
                    src={userinfo.image ? userinfo.image : "/img/logo3.png"}
                    alt="user"
                  />
                </div>
                <div className="userinformations">
                  <span className="useenamecin">
                    {userinfo.firstname} {userinfo.lastname}
                  </span>
                  <br />
                  <span>{userinfo.email}</span>
                </div>
              </div>
              <div className="userqwrapper">
                <div className="infologcontent">
                  <div className="intitlelog">Email:</div>
                  <div className="ingolog">{userinfo.email}</div>
                </div>

                <div className="infologcontent">
                  <div className="intitlelog">Mobile Number :</div>
                  <div className="ingolog"> {userinfo.number}</div>
                </div>

                <div className="infologcontent">
                  <div className="intitlelog">Account Status:</div>
                  <div className="ingolog">
                    {userinfo.isBlock === false ? "Active" : "Block"}{" "}
                  </div>
                </div>

                <div className="infologcontent">
                  <div className="intitlelog">Account Type:</div>
                  <div className="ingolog">{userinfo.permissions}</div>
                </div>

                <div className="infologcontent">
                  <div className="intitlelog">Wallet Balance.:</div>
                  <div className="ingolog">{userwallet.wallet}</div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {trxt.status !== "success" ? (
            <Dropdown>
              <Dropdown.Toggle variant="info" id="dropdown-basic">
                Actions
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handlresolve} disabled={loading}>
                  Resolve
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger my-2"
                  onClick={handlereverse}
                  disabled={loading}
                >
                  Reverse
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
      <div className="buid">
        <div className="quicktoservices">
          <Link to="/api/transaction">
            <button >
              Main API Log
            </button>
          </Link>
          <div className="logsearcharea">
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="search"
                  onChange={(event) => {
                    setsearch(event.target.value);
                  }}
                />
              </Form.Group>
            </div>
        </div>
        <div className="showtransactions">
          <div className="transactionprijhse">
            {userCollection.length === 0 ? (
              <>
                <center> No record found</center>
              </>
            ) : (
              <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th className="hide">Date</th>
                  <th>Service</th>
                  <th classNamej="hide">Amount</th>
                  <th>Post Balance</th>
                  <th className="hide">Pre Balance</th>
                  <th>View More</th>
                </tr>
              </thead>
              <tbody>
                {records
                  .filter((val) => {
                    if (search === "") {
                      return val;
                    } else if (val.AccountNumber.includes(search)) {
                      return val;
                    } else if (
                      val.type.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.status.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((value, id) => {
                    return (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td className="hide">
                          {value.created
                            ? format(value.created, "dd/MM/yyyy hh:mm a")
                            : ""}
                        </td>
                        <td>{value.type}</td>
                        <td className="hide">{formatNumber(value.amount)}</td>
                        <td>{value.postBalance}</td>
                        <td className="hide">{value.PreBalanc}</td>
                        <td onClick={Togglemodal(value)}>
                    <FcExpand />
                   </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            )}
            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>
                {numbers.slice(0, 10).map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span
                        className="page-link"
                        onClick={() => changecurrentPage(n)}
                      >
                        {n < 11 ? n : n + 1}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionLog;


