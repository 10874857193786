import React, { useCallback, useEffect, useState } from "react";
import "./Dashoard.css";
import {
  MdOutlineAccountBalanceWallet,
  MdOutlineStackedBarChart,
} from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import {
  getuser,
  getusertransaction,
} from "../../Service/GetUser.service";
import { useAuth } from "../../Service/UserAuth";
import { formatNumber } from "../../Service/Utility";
import { Form,  Table } from "react-bootstrap";
import { format } from "date-fns";
import { FcApproval, FcHighPriority } from "react-icons/fc";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { functions } from "../../firebase";
import Inloader from "../../Inloader";
import firebase from "firebase";
import { database } from "../../firebase";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";
import { is } from "date-fns/locale";
function Dashboardindex() {
  const history = useHistory();
  const { uid } = useAuth().currentUser;
  const { loginuser} = useAuth();
  const [user, setuser] = useState([]);
  const { userwallet } = useAuth();
  const [userCollection, setuserCollection] = useState([]);
  const [loading, setlaoding] = useState(false);
  const [show, setShow] = useState(false);
  const [showfund, setShowfund] = useState(false);
  const [item, setitem] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    history.push(`/transaction/${id}`);
  };
  const handleClosefund = () => setShowfund(false);
  const handleShowfund = () => setShowfund(true);
  const [pins, setpin] = useState([]);
  const [fullscreen, setFullscreen] = useState(true);
  const [awaiting, setwaiting] = useState(false);
  const [amountaa, setamount] = useState(0);
  const [result, setresult] = useState(null);
  const [payload, Setpayload] = useState([]);
  const [setvalue, setshowvalue] = useState(false);
  // const Baseurl =
  //   "https://us-central1-mazuflex-app.cloudfunctions.net/verifyBvnUfitpay";
  const handleClosepayload = () => {
    window.location.href = "/dashboard";
    setShowpayload(false);
  };
  const handleShowpayload = () => {
    handleCloseaccount();
    handleClosefund();
    setShowpayload(true);
  };
  const [showotp, setShowotp] = useState(false);

  const handleCloseotp = () => setShowotp(false);
  const handleShowotp = () => setShowotp(true);
  const [showpayload, setShowpayload] = useState(false);
  const [isToday, setisToday] = useState(0);
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [totaltoday, setTotalToday] = useState(0);
  const [total, setTotal] = useState(0);
  const [report, setreport] = useState([]);
  const [showreport, setShowreport] = useState(false);
  const handleClosereport = () => setShowreport(false);
  const handleShowreport = () => setShowreport(true);
  const [message, setmessage] = useState("");
  const [showaccount, setShowaccount] = useState(false);
  const [userinformation, setaccountinformation] = useState([]);
  const handleCloseaccount = () => {
    setresult([]);
    setShowaccount(false);
  };
  const [otp, setotp] = useState("");
  const handleShowaccount = () => setShowaccount(true);
  const [res, setres] = useState("");
  const [bvn, setbvn] = useState("");
  // const [loadingaccount, setloading] = useState(false);
  const [businessname, setbuinsessname] = useState("");
  const [bvnres, setbvnres] = useState("");
  const [session, setsessionID] = useState("");
  const [verifiedBvn, setverified] = useState(false);
  const [currentPage, setcurrentpage] = useState(1);

  const recordpage = 10;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = userCollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(userCollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);


  useEffect(() => {
    getuser(uid, (users) => {
      setuser(users);
      getusertransaction(users.id, (collections) => {
        setuserCollection(collections);
        const mytrantoday = collections.filter((item) => item.isToday === true);
        const isPending = collections.map((item) => !item.isToday);
        const isAPproved = collections.map((item) => item.isToday);
        const count1 = isPending.filter((value) => value === true).length;
        setisToday(count1);
        const count2 = isAPproved.filter((value) => value === true).length;
        setTotalTransaction(count2);

        const TotalisTOday = mytrantoday.reduce((c, item) => {
          return c + parseFloat(item.amount);
        }, 0);

        setTotalToday(TotalisTOday);

        const total = collections.reduce((c, item) => {
          return c + parseFloat(item.amount);
        }, 0);

        setTotal(total);
      });
    });
  }, []);

  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };

  useEffect(() => {}, []);

  const accountdetails = userwallet.account ? userwallet.account : "";
  const paymentnotify = () => {
    history.push("/payment/notification");
  };

  const Togglemodal = useCallback(
    (item) => () => {
      handleShow(item.id);
      setitem(item);
      const pins = item.type === "Wace e-PIN" ? item.payloadres.pins : [];
      setpin(pins);
    },
    []
  );

  const amount = parseFloat(amountaa);
  const config = {
    reference: new Date().getTime().toString(),
    email: `${user.email}`,
    amount: amount * 100,
    publicKey: "pk_live_7c49d4df21fc6207a1ece0514092b34ad3f5bbee",
  };

  const handlePaystackSuccessAction = async (data) => {
    const verifyPaymentHandler = functions.httpsCallable(
      "verifyPaystackPayment"
    );

    const value = {
      ref: data.reference,
      status: data.success,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      userId: user.id,
      phone: user.number,
      amount: amount,
    };
    setwaiting(true);
    await verifyPaymentHandler(value).then((result) => {
      if (result === null || result === undefined) {
        return (
          <>
            <Inloader />
          </>
        );
      }
      if (result.data.data.status === "success") {
        Setpayload(result.data.data);
        setshowvalue(true);
        handleClose();
        handleShowpayload();
      } else {
        Setpayload(result.data.data);
        handleClose();
        handleShowpayload();
      }
    });
  };

  const handlePaystackCloseAction = () => {
    window.location.href = "/dashboard";
  };
  const componentProps = {
    ...config,
    text: "Fund Wallet",
    onSuccess: (data) => handlePaystackSuccessAction(data),
    onClose: () => handlePaystackCloseAction(),
  };

  const handletransaction = () => {
    history.push("/transaction");
  };

  const handlesend = async (e) => {
    e.preventDefault();
    const id = database.collection("_").doc().id;
    const data = {
      id,
      report,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      service: item.company,
      amount: item.amount,
      customername: `${user.fullname} ${user.lastname}`,
      Trxt: item.id,
      userID: user.id,
      isTreated: false,
    };

    if (report === "") {
      toast.error("Give details of your complain", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      await database
        .collection("Report")
        .doc(data.id)
        .set(data)
        .then(() => {
          toast.success(
            "Sorry for the inconvenience experience while making this transaction, we will treat and give feeback shortly. Thanks",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setmessage(
            "Sorry for the inconvenience experience while making this transaction, we will treat and give feeback shortly. Thanks"
          );
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const getbvn = async (e) => {
    e.preventDefault();
    setres("BVN is required for verification Purpose");

    if (bvn.length === 11) {
      setlaoding(true);
      handlecreaeaccount(bvn);
    } else {
      setres("Enter a valid BVN");
    }
  };

  const handleGetdata = async (e) => {
    e.preventDefault();

    if (otp.length !== 6) {
      setbvnres("invalid Otp");
      return;
    }

    if (otp === "" || session === "") {
      setbvnres("invalid request");
      return;
    }
    setbvnres("Please wait...");
    const token = session;

    try {
      setlaoding(true);
      await axios
        .post(
          "https://us-central1-mazuflex-app.cloudfunctions.net/getBvnUser/users",
          {
            otp,
          },
          {
            headers: {
              session_id: token,
            },
          }
        )
        .then(async (result) => {
          const dp = result.data.payload;
          if (result.data.status === "success") {
            setbvnres("Finalizing request please wait");
            await handlecreaeaccount(bvn);
            await axios
              .post(
                ` https://us-central1-mazuflex-app.cloudfunctions.net/UpdateuserRecord/user/record`,
                {
                  lastname: dp.last_name,
                  firstname: dp.first_name,
                  dob: dp.dob,
                  number: dp.phone ? dp.phone : loginuser.number,
                  address: dp.address_line_1,
                  isvalidUser: true,
                  bvn: bvn,
                  id: loginuser.id,
                }
              )
              .then((e) => {
                handleCloseotp();
                setbvnres("Verification completed");
              });
          }
          setbvnres(result.data.message);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (error) {}
    setlaoding(false);
  };
  const handlecreaeaccount = async (bvn) => {
    if (bvn === "") {
      toast.error("Error occured", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const bussinessanme = businessname
      ? businessname
      : `${loginuser.firstname} ${loginuser.lastname}`;

    try {
      setlaoding(true);
      await axios
        .post(
          `https://us-central1-mazuflex-app.cloudfunctions.net/CreateAccountSquad/create/account`,
          {
            customer_identifier: loginuser.id,
            business_name: bussinessanme,
            mobile_num: loginuser.number,
            bvn: "22169832012",
          }
        )
        .then(async (res) => {
          if (res.data.message !== "Success") {
            setshowvalue(false);
            await axios.get(
              `https://us-central1-mazuflex-app.cloudfunctions.net/UpdateuserRecord/user/record/${loginuser.id}`
            );
            handleShowpayload();
          }
          Setpayload(res.data);
          setshowvalue(true);
          handleShowpayload();
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } catch (error) {
      toast.error(error.message);
    }
    setlaoding(false);
  };
  const createAccount = async (e) => {
    //   const lastname = ? result.last_name.toLowerCase() : ""
    // const firstname =  ? result.first_name.toLowerCase() : ""

    const bvn = e;
    const data = {
      bank: "ENRICH",
      bvn: bvn,
      first_name: result.first_name,
      last_name: result.last_name,
      business_name: businessname,
      userID: loginuser.id,
    };

    try {
      axios
        .post(
          "https://us-central1-mazuflex-app.cloudfunctions.net/createAccount",
          {
            data: data,
          }
        )
        .then((e) => {
          toast.success("Account Create Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleCloseaccount();
        })
        .catch((e) => {
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  return (
    <>
      <Modal
        show={showotp}
        onHide={handleCloseotp}
        backdrop="static"
        keyboard={false}
        centered
        className="newhsjahsbas"
      >
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formPassword">
            <Form.Label>Enter OTP</Form.Label>
            <Form.Control
              type="password"
              onChange={(event) => setotp(event.target.value)}
              maxLength={6}
            />
          </Form.Group>

          <Form.Text className="text-success">
            {bvnres ? bvnres : "Please dont share any information with anyone."}
          </Form.Text>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant={loading ? "warning" : "primary"}
            onClick={handleGetdata}
            disabled={loading}
          >
            {loading ? "processing ..." : "Validate"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showaccount}
        onHide={handleCloseaccount}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Funding Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Group className="mb-3">
            <Form.Label>BVN </Form.Label>
            <Form.Control
              type="text"
              onChange={(event) => {
                setbvn(event.target.value);
              }}
            />
            <Form.Text className="text-muted"></Form.Text>
          </Form.Group> */}

          <Form.Group className="mb-3">
            <Form.Label>Business Name (Optional)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Optional"
              onChange={(event) => {
                setbuinsessname(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Text className="text-success">{bvnres}</Form.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseaccount}>
            Close
          </Button>

          <Button
            variant={loading ? "warning" : "success"}
            onClick={getbvn}
            style={{ display: !verifiedBvn ? "block" : "none" }}
            disabled={loading}
          >
            {loading ? "Please wait" : "Create Fund Account"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showpayload} onHide={handleClosepayload}>
        <Modal.Body>
          <div className="payloadconiner">
            <center>
              <div className="paylaod">
                {setvalue ? (
                  <>
                    {payload.message === "Success" ? (
                      <div>
                        <h1>
                          <FcApproval />
                        </h1>
                        <p>Account Created Successfully</p>
                      </div>
                    ) : (
                      <div>
                        <h1>
                          <FcHighPriority />
                        </h1>
                        <p>Request Failed. Please try again later</p>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </center>
          </div>
        </Modal.Body>

        <Button
          variant={payload.message === "Success" ? "success" : "danger"}
          onClick={handleClosepayload}
        >
          Close
        </Button>
      </Modal>
      <Modal show={showfund} onHide={handleClosefund} size="lg">
        <Modal.Body>
          <div className="fundwalletside">
            <div className="walttebdhd">
              <div className="wallwtheadd">Fund Your wallet</div>
              <div className="fundwalletmethod">
                <div className="backdeposut">
                  <div className="inforcontenr">
                    <div className="infotittle">BANK :</div>
                    <div className="infodetails">Guaranty Trust Bank Plc </div>
                  </div>

                  <div className="inforcontenr">
                    <div className="infotittle">ACCOUNT NAME:</div>
                    <div className="infodetails">COLETKING CONCEPT</div>
                  </div>

                  <div className="inforcontenr">
                    <div className="infotittle">ACCOUNT NUMBER :</div>
                    <div className="infodetails">0771679118</div>
                  </div>

                  <Button
                    variant={loading ? "warning" : "primary"}
                    disabled={loading}
                    onClick={paymentnotify}
                    className="w-100"
                    type="submit"
                  >
                    Create Payment Notification
                  </Button>
                </div>
                <center>
                  <div className="paystackmethof">
                    <div className="wappconashowreac">
                      {userinformation ? (
                        <>
                          <div className="showaccountinput">
                            <div>
                              <strong>FUND ACCOUNT</strong>
                              <div className="showeaccojdconetn">
                                <h2>
                                  {userinformation.virtual_account_number
                                    ? userinformation.virtual_account_number
                                    : []}
                                </h2>
                                <span className="p-0 fs-5 jsaccount">
                                  {userinformation.first_name
                                    ? userinformation.first_name
                                    : []}{" "}
                                  {userinformation.last_name
                                    ? userinformation.last_name
                                    : []}
                                </span>{" "}
                                <br />
                                <span className="p-0 fs-6 jsaccount">
                                  GTB Bank
                                </span>{" "}
                                <br />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="showaccountinput">
                            <Button
                              variant={loading ? "warning" : "light"}
                              disabled={loading}
                              onClick={handleShowaccount}
                            >
                              Create Account
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showreport}
        onHide={handleClosereport}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Report Issue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Service Type</Form.Label>
            <Form.Control type="text" value={item.company} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control type="text" value={item.amount} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              type="text"
              value={`${user.firstname} ${user.lastname}`}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Transaction ID</Form.Label>
            <Form.Control type="text" value={item.id} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Give Report Here</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={(event) => {
                setreport(event.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <center>{message}</center>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosereport}>
            Close
          </Button>
          <Button variant="success" onClick={handlesend}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen={fullscreen}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transaction Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {item.type === "Wace e-PIN" || item.type === "exam e-PIN" ? (
            <div className="infocontainer">
              <div className="inforcontenr">
                <div className="infotittle">Company :</div>
                <div className="infodetails">
                  {item.company ? item.company.toUpperCase() : ""}
                </div>
              </div>

              <div className="carconatienr">
                <div className="cordrawwaper">
                  {pins.map((item, id) => {
                    return (
                      <div key={id}>
                        <div className="cardcontenr">
                          <div className="cardinma">
                            <img src="/img/waec.png" alt="" />
                          </div>
                          <div className="caedtejnss">
                            <div className="inforcontenr">
                              <div className="infotittle"> Pin:</div>
                              <div className="infodetails">{item.pin}</div>
                            </div>

                            <div className="inforcontenr">
                              <div className="infotittle"> Serial Number:</div>
                              <div className="infodetails">
                                {item.serialNumber}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Amount :</div>
                <div className="infodetails">
                  &#8358; {formatNumber(item.amount)}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Number of Pin :</div>
                <div className="infodetails">{pins.length}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Transaction Time :</div>
                <div className="infodetails">
                  {item.created
                    ? format(item.created, "dd/MM/yyyy hh:mm a")
                    : ""}
                </div>
              </div>
              <div className="d-flex">
                <Button
                  variant="secondary"
                  className="w-100 m-1"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  variant="danger"
                  className="w-100 m-1"
                  onClick={handleShowreport}
                >
                  Report Issue
                </Button>
                <Button
                  className="w-100 m-1"
                  type="submit"
                  onClick={() => window.print()}
                >
                  Print
                </Button>
              </div>
            </div>
          ) : (
            <div className="infocontainer">
              <img src="/img/" alt="" />
              <div className="inforcontenr">
                <div className="infotittle">Company :</div>
                <div className="infodetails">{item.company}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Account Number :</div>
                <div className="infodetails">
                  {item.AccountNumber ? item.AccountNumber : item.meter}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Customer Name :</div>
                <div className="infodetails">
                  {item.customername === "no value" ? "" : item.customername}
                </div>
              </div>

              <div
                className="inforcontenr"
                style={{ display: item.token ? "block" : "none" }}
              >
                <div className="infotittle">Unit :</div>
                <div className="infodetails">
                  {item.unit === "no value" ? "" : item.unit}
                </div>
              </div>

              <div
                className="inforcontenr"
                style={{ display: item.token ? "block" : "none" }}
              >
                <div className="infotittle">Token :</div>
                <div className="infodetails">
                  {item.token === "no value" ? "" : item.token}
                </div>
              </div>

              <div
                className="inforcontenr"
                style={{ display: item.token ? "block" : "none" }}
              >
                <div className="infotittle">Address :</div>
                <div className="infodetails">
                  {item.address === "no value" ? "" : item.address}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Amount :</div>
                <div className="infodetails">
                  &#8358; {formatNumber(item.amount)} <br />
                  Charge Inclusive
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Transaction Time :</div>
                <div className="infodetails">
                  {item.created
                    ? format(item.created, "dd/MM/yyyy hh:mm a")
                    : ""}
                </div>
              </div>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="danger" onClick={handleShowreport}>
                  Report Issue
                </Button>
                <Button variant="primary" onClick={() => window.print()}>
                  Print Reciept
                </Button>
              </Modal.Footer>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <ToastContainer />
      <div className="text-white">
        <div className="indeconayeiner">
          <div className="alldjnd">
            <div className="inforuwere">
              <div className="infordetails">
                <div className="walletinformation">
                  <span className="p-0">Balance</span> <br />
                  <h5>
                    {" "}
                    {userwallet.wallet ? (
                      <>&#x20A6; {formatNumber(userwallet.wallet)} </>
                    ) : (
                      <>0</>
                    )}
                  </h5>
                  <button onClick={handleShowfund}>Fund Wallet</button>
                </div>
                <div className="imageside">
                  <MdOutlineAccountBalanceWallet />
                </div>
              </div>
            </div>

            <div className="inforuwere">
              <div className="infordetails">
                <div className="walletinformation">
                  <span className="p-0">Today Transaction</span> <br />
                  <h5>&#x20A6; {formatNumber(totaltoday)}</h5>
                  <p>{totalTransaction}</p>
                  <button onClick={handletransaction}>View Transaction</button>
                </div>
                <div className="imageside">
                  <MdOutlineStackedBarChart />
                </div>
              </div>
            </div>

            {/* <div className="inforuwere1" style={{display: accountdetails ? "none" : "flex"}}>
              <div className="infordetails1">
                    <strong>FUND ACCOUNT</strong>
                    <div className="showeaccojdconetn">
                      <Button variant="light" onClick={handleShowaccount} type="submit">
                        Create FUND ACCOUNT
                      </Button>
                    </div>                
              </div>
            </div> */}
            {/* 
            <div
              className="inforuwere1"
              style={{ display: userAccount.length !== 0 ? "none" : "block" }}
            >
              <div className="infordetails1">
                <strong>FUND ACCOUNT</strong>
                <div className="showeaccojdconetn">
                  <Button
                    variant="light"
                    // onClick={handlecreaeaccount}
                    type="submit"
                  >
                    Create FUND ACCOUNT
                  </Button>
                </div>
              </div>
            </div> */}

            <div className="inforuwere1">
              <div className="infordetails1">
                <center>
                  <div className="paystackmethof">
                    <div className="wappconashowreac">
                      {user.SquardAccount ? (
                        <>
                          <div className="showaccountinput">
                            <div>
                              <strong>FUND ACCOUNT</strong>
                              <div className="showeaccojdconetn">
                                <h2>
                                  {user.SquardAccount}
                                </h2>
                                <span className="p-0 fs-5 jsaccount">
                                  {userinformation.businessname}{" "}
                                </span>{" "}
                                <br />
                                <span className="p-0 fs-6 jsaccount">
                                  GTB Bank
                                </span>{" "}
                                <br />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="showaccountinput">
                            <Button
                              variant={loading ? "warning" : "light"}
                              disabled={loading}
                              onClick={handleShowaccount}
                            >
                              Create Account
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>

        <div className="accountletave">
          <div className="accountLevecontainer">
            <div className="showaiofaent">
        
            </div>
       
            <div className="showaiofaent">
                  
            </div>

            <div className="showaiofaent">
                <p>Transaction Limit</p>
                
            </div>
          </div>
        </div>

        <div className="buid">
          <div className="quicktoservices">
            <Link to="/transaction">
              <button style={{ background: "green", color: "white" }}>
                Transaction History
              </button>
            </Link>
            <Link to="/wallet/history">
              <button>Wallet History</button>
            </Link>
          </div>
          <div className="showtransactions">
            <div className="transactionprijhse">
              {records.length === 0 ? (
                <>
                  <center> No record found</center>
                </>
              ) : (
                <Table striped="columns">
                  <thead>
                    <tr className="bg-white">
                      <th>S/N</th>
                      <th className="hide">Data/Time</th>
                      <th>Status</th>
                      <th className="hide">Service Type</th>
                      <th>Account No.</th>
                      <th>Amount</th>
                      <th className="hide">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((item, id) => {
                      return (
                        <tr
                          key={id}
                          className="bg-light cuspointer"
                          onClick={Togglemodal(item)}
                        >
                          <td>{id + 1}</td>
                          <td className="hide">
                            {item.created
                              ? format(item.created, "dd/MM/yyyy hh:mm a")
                              : ""}
                          </td>
                          <td className="iconesdes">
                            {item.status === "success" ? (
                              <>
                                <FcApproval />
                              </>
                            ) : (
                              <>
                                <FcHighPriority />
                              </>
                            )}{" "}
                          </td>
                          <td className="hide">{item.type}</td>
                          <td>
                            {item.AccountNumber} <br />
                            <span className="ksjdhdmobid">{item.type}</span>
                          </td>
                          <td>{item.amount}</td>
                          <td className="hide">{item.status}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}

              <nav>
                <ul className="pageination">
                  <li className="page-item">
                    <span className="page-link" onClick={prepage}>
                      Prev
                    </span>
                  </li>

                  {numbers.slice(0, 10).map((n, i) => {
                    return (
                      <li
                        key={i}
                        className={
                          currentPage === n ? "activenumber" : "page-item"
                        }
                      >
                        <span
                          className="page-link"
                          onClick={() => changecurrentPage(n)}
                        >
                          {n < 11 ? n : n + 1}
                        </span>
                      </li>
                    );
                  })}

                  <li className="page-item">
                    <span className="page-link" onClick={Nextpage}>
                      Next
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboardindex;
