import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getAdminlogin,
  getAdminnotification,
  getAdminTrfansaction,
} from "../../../Service/GetUser.service";
import { useAuth } from "../../../Service/UserAuth";
import "./Admin.css";
import { FcExpand, FcOk, FcHighPriority, FcExpired } from "react-icons/fc";
import {BiTransferAlt} from "react-icons/bi"
import {FcMoneyTransfer} from "react-icons/fc"
import {FaUserAlt}from "react-icons/fa"
import { formatNumber } from "../../../Service/Utility";
import { format } from "date-fns";
import axios from "axios";
import { database } from "../../../firebase";
import { getuserwaletBalance } from "../../../Service/Admin.services";

function Dashboardindex() {
  const { loginuser } = useAuth();
  const [s, sets] = useState([]);
  const [users, setusers] = useState([]);
  const [collection, settransactioncollection] = useState([]);
  const [paymentnotification, setpaymentnotification] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [amount, setamount] = useState(0);
  const [loading, setloading] = useState(false);
  const [respons, setresposne] = useState("");
  const [walletbalance, setwalletbalance]= useState(0)
  const topUrl = "https://us-central1-mazuflex-app.cloudfunctions.net/getTopUp";
  useEffect(() => {
    sets(loginuser);

    getAdminlogin()
      .then((result) => {
        setusers(result);
      })
      .catch((err) => {});

    getAdminTrfansaction().then((result) => {
      settransactioncollection(result);
    });

    getAdminnotification().then((result) => {
      setpaymentnotification(result);
    });


    axios.get("https://us-central1-mazuflex-app.cloudfunctions.net/getuserwallet").then((result)=>{
    
    if (result){

      const fdat = result.data
      const totalpenin = fdat.reduce((c, item) => {
        return c + item.wallet;
       }, 0);

       setwalletbalance(totalpenin)
    }
    
    })
  }, []);

  const Togglemodal = useCallback(
    (item) => () => {
      if (item.isApproved === true) {
        setresposne("already treated");
        return;
      }
      setamount(item);
      handleShow();
    },
    []
  );

  const handleTop = async (e) => {
    e.preventDefault();
    setloading(true);
    setresposne("Authentication");

    await axios
      .post(topUrl, {
        userID: loginuser.id,
        id: amount.userId,
        amount: parseFloat(amount.amount),
      })
      .then(async (e) => {
        setresposne(e.data.message);
        database
          .doc(`/PaymentNotification/${amount.id}`)
          .update({
            isApproved: true,
          })
          .then((e) => {
            console.log(e);
          });
        setloading(false);
      });
    setloading(false);
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Fund Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Enter Amount</Form.Label>
            <Form.Control type="tel" disabled value={amount.amount} />
            <Form.Text className="text-muted">
              Enter amount you want to credit user with. Enter negative value if
              you want to debit
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <center>
          <p className="text-success"> {respons}</p>
        </center>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={loading || amount.isApproved}
            onClick={handleTop}
          >
            {loading ? (
              <Spinner animation="grow" variant="warning" />
            ) : (
              "Fund Wallet"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="headeradmin">Dashboard</div>

      <div className="introdsidee">
          <div className="dasallheaderss">
          <div className="introdisd">
          <p>Welcome {loginuser.firstname}!</p>
          <span>
            Welcome to your dashboard. See a quick summary of your transactions
            below.
          </span>
        </div>
        <div className="introdisd aksalsjsdhdf">
          <div className="iconseofe">
            <FaUserAlt/>
          </div>
         <p>Do you want to see users</p>
        <Link to="/admin/users"><button>Click Me!</button></Link>
        </div>

        <div className="introdisd">
          <div className="iconseofe">
            <BiTransferAlt/>
          </div>
         <p>Do you want to see Transaction</p>
         <Link to="/admin/Transaction"><button>Click Me!</button></Link>
        </div>

        <div className="introdisd nedhd">
          <div className="iconseofe">
            <FcMoneyTransfer/>
          </div>
         <p>Total Amount in user Wallet</p>
         <h2>{walletbalance ? formatNumber(walletbalance.toFixed(2)) : 0}</h2>
        </div>
          </div>
      </div>




      <div className="reactconetn newcontienr">
      <div className="summarysection">
          <div className="warrheasderadmin">
            <h6>Recent Funding Request</h6>
            <Link to="/fund/request">
              <p>View More</p>
            </Link>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Date</th>
                <th>Depositor</th>
                <th>Amount &#8358;</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paymentnotification.map((value, id) => {
                return (
                  <tr key={id}>
                    <td>{id + 1}</td>
                    <td className="hide">
                      {value.created
                        ? format(value.created, "dd/MM/yyyy hh:mm a")
                        : ""}
                    </td>
                    <td>{value.depositor}</td>
                    <td>{formatNumber(value.amount)}</td>
                    <td>
                      {value.isApproved === false ? "Pending" : "Approved"}
                    </td>
                    <td onClick={Togglemodal(value)}>
                      <FcExpand />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
      </div>
      <div className="summarysection">
          <div className="warrheasderadmin">
            <h6>Recent Transactions</h6>
            <Link to="/admin/Transaction">
              <p>View More</p>
            </Link>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Date</th>
                <th>Service</th>
                <th>Amount</th>
                <th>Bal</th>
                <th>Status</th>
          
              </tr>
            </thead>
            <tbody>
              {collection.map((value, id) => {
                return (
                  <tr key={id}>
                    <td>{id + 1}</td>
                    <td>
                      {value.created
                        ? format(value.created, "dd/MM/yyyy hh:mm a")
                        : ""}
                    </td>
                    <td>{value.type}</td>
                    <td>{formatNumber(value.amount)}</td>
                    <td>
                      {value.walletbalance}
                    </td>
                    <td>
                      {value.status === "success" ? (
                        <FcOk />
                      ) : (
                        <>
                        {value.status === "Network Error" ? 
                        <FcHighPriority/> :    <FcExpired/> }
                        </>               
                      )}
                    </td>
                   
                  </tr>
                );
              })}
            </tbody>
          </Table>
      </div>
      </div>

    </div>
  );
}

export default Dashboardindex;
