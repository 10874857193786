import React from 'react'
import Dashboardindex from '../Admincomponent/Dashboardindex'
import Navbar from '../Admincomponent/NavbarAdmin'


function AdminDashboard() {
  return (
    <div>
      <Navbar/>
      <Dashboardindex/>
    </div>
  )
}

export default AdminDashboard