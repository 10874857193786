import React from 'react'
import axios from "axios";
import { useState } from "react";
import "./Services.css";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../Service/UserAuth";
import { database } from "../../firebase";
import { formatNumber } from "../../Service/Utility";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import { format } from "date-fns";
const walleturl ="https://us-central1-mazuflex-app.cloudfunctions.net/getuserwallet";
const topUrl = "https://us-central1-mazuflex-app.cloudfunctions.net/commissioncredit";
function Cabletvsubescription() {
  const [selectednetwork, setselectednetwork] = useState("NECO");
  const [image, setimage] = useState("/img/neco.jpg");
  const [b, setb] = useState("");
  const [amountaaa, setamount] = useState(0);
  const [phoneea, setphone] = useState("");
  const { loginuser, userwallet } = useAuth();
  const [loading, setloading] = useState(false);
  const [responspayload, setresponsepayload] = useState("");
  const [payload, setpayload] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [number, setnumber] = useState(0);
  const [datapayload, setdatapayload]= useState([])
  const history = useHistory();
  const [cableresponse, setcableresponse] = useState([]);
  const [cablerror, setcableerroresponse] = useState("");
  const [status, setstatus] = useState("");
  const [showprint, setShowprint] = useState(false);
  const handleClosesetShowprint = () => setShowprint(false);
  const handleShowsetShowprint = () => setShowprint(true);
  const [fullscreen, setFullscreen] = useState(true);
  const [showpayload, setShowpayload] = useState(false);
  const [disable, setdisable]= useState(false)
  const handleClosepayload = () => {
    setShowpayload(false);
    window.location.href = "/buy/neco";
  };
  const handleShowpayload = () => setShowpayload(true);

  const date = format(new Date(), "dd/MM/yyyy hh:mm a");
  const amount = amountaaa;

  const handleamout = (e) => {
    const price = 800;
    const am = parseFloat(e.target.value);
    setnumber(am);
    const amount = am * price;
    setamount(amount);
  };


  const com2 = 150

  const pins = cableresponse.pins ? cableresponse.pins : [];
  

  const ref = database.collection("_").doc().id;
  const phone = phoneea ? phoneea : loginuser.number;

  const handlePring = (e)=>{
    e.preventDefault()
    history.push(`/transaction/${datapayload.id}`)
  }

  const handleverifier = async (e) => {
    e.preventDefault();
    if (isNaN(phone)) {
      toast.error("Number Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (phoneea === "") {
      toast.error("Number Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const data = {
      network: selectednetwork,
      number: phone,
      amount: amount,
      referrence: ref,
    };

    setpayload(data);
    handleShow();
    setloading(false);
  };

  const handlepayment = async (e) => {
    e.preventDefault();
    const com = 0;
    const payable = loginuser.permissions === "user" ? amount + com : amount;

    if (userwallet.wallet < payable) {
      toast.error("Insufficent wallet balance, Please Top", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setloading(false);
      return;
    }

    if (payable < 0){
      toast.error("Insufficent wallet balance, Please Top", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setloading(false);
      return;
    }
    setloading(true);
    try {
      await axios
        .post(walleturl, { id: userwallet.id, amount: payable })
        .then(async (e) => {
          if (e.data.message !=="success"){
            toast.error("Insufficent wallet balance, Please Top", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return
          }
          setresponsepayload("validating");
          const data1 = {
            id: ref,
            company: selectednetwork,
            customername: "no value",
            address: "no value",
            userID: loginuser.id,
            AccountNumber: phone,
            cablerror,
            walletbalance: userwallet.wallet,
            amount: amount,
            phone: phone,
            type: "exam e-PIN",
            status: "Network Error",
            created: firebase.firestore.FieldValue.serverTimestamp(),
          };
          const vamount = parseFloat(e.data.amount)
          if (e.data.message === "success") {
            setresponsepayload("Connecting service ..");
            await axios
              .post(
                "https://us-central1-mazuflex-app.cloudfunctions.net/buyNeco",
                { numberOfPins: number, amount:amount,
                  isApproved: e.data.isApproved,
                  verifyamount:vamount
                }
              )
              .then(async (e) => {
                setresponsepayload("finalizing connection. Please wait ");
                setdisable(true)
                if (e.data.status === "success") {
                  handleClose();
                  setcableresponse(e.data);
                  setstatus("success");
                  
                  handleShowpayload();
                  const data = {
                    id: ref,
                    company: selectednetwork,
                    customername: phone,
                    address: "no value",
                    userID: loginuser.id,
                    AccountNumber: phone,
                    pins:e.data.pins,
                    walletbalance: userwallet.wallet,
                    amount: parseFloat(amount),
                    phone: phone,
                    payloadres: e.data,
                    isToday:true,
                    type: "exam e-PIN",
                    status: "success",
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                  };
                 setdatapayload(data)
                 const promises = [
                  database
                    .collection("Transaction")
                    .doc(data.id)
                    .set(data),
                    axios.post(topUrl, {
                      userID: loginuser.id,
                      amount: loginuser.permissions === "user" ? 0 : parseFloat(com),
                      price:  parseFloat(amount),
                      admincom: com2,
                    }),
                    database.doc(`/income/66DyYlsUXUHQdRUIkDrs`).update({
                      necoincome: firebase.firestore.FieldValue.increment(com2),
                      totalincome: firebase.firestore.FieldValue.increment(com2),
                      })
                 ]
                 await Promise.all(promises)
                } else {
                  handleClose();
                  setcableerroresponse(e.data.data);
                  setstatus("error");
                  handleShowpayload();
                  setpayload(data1)
                  await database
                    .collection("Transaction")
                    .doc(data1.id)
                    .set(data1);
                }
                setdisable(false)
              })
              .catch((e) => {
                
                toast.error(e.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          } else {
            toast.error("Insufficent wallet balance, Please Top", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setresponsepayload("");
          }
        })
        .catch((e) => {
         ;
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (e) {
      
      toast.error(e.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setloading(false);
  };

  const handleshowprint = ()=>{
    handleShowsetShowprint()
  }
  return (
    <div>
      <ToastContainer />
      <Modal
        show={showprint} 
        onHide={handleClosesetShowprint }
        backdrop="static"
        fullscreen={fullscreen}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transaction Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="infocontainer">
        <center> <img src="/img/logo3.png" alt="" /></center>
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectednetwork ? selectednetwork.toUpperCase() : ""}
                  </div>
                </div>


                <div className="carconatienr">
                  <div className="cordrawwaper">
                    {pins.map((item, id) => {
                      return(
                        <div key={id}>
                        <div className="cardcontenrnew">
                          <div className="cardinma">
                            <img src="/img/waec.png" alt="" />
                          </div>
                          <div className="caedtejnss">
                            <div className="inforcontenr">
                              <div className="infotittle"> Pin:</div>
                              <div className="infodetails">{item.pin}</div>
                            </div>

                            <div className="inforcontenr">
                              <div className="infotittle"> Serial Number:</div>
                              <div className="infodetails">{item.serialNumber}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      )
                    })}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(payload.amount)}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Number of Pin :</div>
                  <div className="infodetails">{number}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>
                <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    onClick={handleClosepayload}
                  >
                    Back
                  </Button>
                  <Button className="w-100 m-1" type="submit"  onClick={() => window.print()}>
                    Print
                  </Button>
                </div>
              </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        backdrop="static"
        show={showpayload}
        size="lg"
        onHide={handleClosepayload}
        animation={false}
      >
        <Modal.Body>
          {status === "error" ? (
            <>
              <div className="headersjdjd bg-danger">
                <h1>ERROR</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectednetwork ? selectednetwork.toUpperCase() : ""}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Number :</div>
                  <div className="infodetails">{payload.number}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(payload.amount)}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Message :</div>
                  <div className="infodetails">
                    Transaction Failed, Please try again later
                  </div>
                </div>
                <center className="text-danger">{responspayload}</center> 
                <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    disabled={disable}
                    type="submit"
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button className="w-100 m-1" type="submit" disabled={disable} onClick={handlePring}>
                    Print
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="headersjdjd bg-success">
                <h1>SUCCESS</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectednetwork ? selectednetwork.toUpperCase() : ""}
                  </div>
                </div>


                <div className="carconatienr">
                  <div className="cordrawwaper">
                    {pins.map((item, id) => {
                      
                      return(
                        <div key={id}>
                        <div className="cardcontenr">
                          <div className="cardinma">
                            <img src="/img/neco.jpg" alt="" />
                          </div>
                          <div className="caedtejnss">
                            <div className="inforcontenr">
                              <div className="infotittle"> Token:</div>
                              <div className="infodetails">{item}</div>
                            </div>

                            <div className="inforcontenr">
                              <div className="infotittle"> Transaction ID:</div>
                              <div className="infodetails">{cableresponse.ref}</div>
                            </div>

                          </div>
                        </div>
                      </div>
                      )
                    })}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(payload.amount)}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Number of Pin :</div>
                  <div className="infodetails">{number}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>
                <center className="text-danger">{responspayload}</center> 
                <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    disabled={disable}
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button className="w-100 m-1" type="submit" disabled={disable} onClick={handlePring}>
                    Print
                  </Button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        centered
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div className="headersjdjd">
          <h1>In-Progress</h1>
          </div>
          <p>Kindly confirm the information below before you proceed</p>
          <div className="infocontainer">
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">
                {selectednetwork ? selectednetwork.toUpperCase() : ""}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Number :</div>
              <div className="infodetails">{payload.number}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Price :</div>
              <div className="infodetails">
                &#8358; {formatNumber(payload.amount)}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Number of Pin :</div>
              <div className="infodetails">{number}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">{date}</div>
            </div>
          </div>
        </Modal.Body>

        <center className="info">{responspayload}</center>
        <div className="d-flex">
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlepayment}
            className="m-1 w-50"
            type="submit"
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Pay with Wallet"
            )}
          </Button>

          <Button
            variant="danger"
            onClick={handleClose}
            className="m-1 w-50"
            type="submit"
          >
            Back
          </Button>
        </div>
      </Modal>
      <div className="allcablesyns">
        <div className="allcableitems">
          <div className="cableallre">
            <div className="imagesetuib">
              <img src={image} alt="" />
            </div>
            <div className="productsed">
              <h2>
                <center className="text-dark">NECO RESULT CHECKER PIN</center>
              </h2>
              <Form.Label className="text-dark my-3">Buy E-pin</Form.Label>
              <FloatingLabel
                controlId="floatingSelect"
                onChange={(event) => {
                  setselectednetwork(event.target.value);
                }}
              >
                <Form.Select aria-label="Floating label select example">
                  <option value="neco">NECO</option>
                </Form.Select>
              </FloatingLabel>
              <div className="optionshhd">
                <Form.Group>
                  <Form.Label className="text-dark my-2">
                    Number of Pin
                  </Form.Label>
                  <Form.Control
                    placeholder="e.g 1"
                    type="tel"
                    onChange={handleamout}
                  />
                </Form.Group>
                <Form.Label className="text-dark my-2">Amount</Form.Label>

                <Form.Control placeholder={amount} disabled />

                <Form.Group>
                  <Form.Label className="text-dark my-2">
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    placeholder={loginuser.number}
                    type="tel"
                    onChange={(event) => {
                      setphone(event.target.value);
                    }}
                  />
                </Form.Group>
                <Button
                  variant={loading ? "warning" : "primary"}
                  disabled={loading}
                  onClick={handleverifier}
                  className="my-2 w-100"
                  type="submit"
                >
                  {loading ? (
                    <>
                      {" "}
                      <Spinner animation="border" size="sm" /> {responspayload}
                    </>
                  ) : (
                    "Buy E-pin"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cabletvsubescription;
