import React from 'react'

function Footer() {
  return (
    <div>
        <div className="footercoternr">
            <div className="foottercontenr">
                <div className="somcoaemdnd">

                </div>
                <div className="termsancd">
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer