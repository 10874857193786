import React from 'react'
import IncomeSIde from '../Admincomponent/IncomeSIde'
import Navbar from '../Admincomponent/NavbarAdmin'
import PaymentNotification from '../Admincomponent/PaymentNotification'


function AdminDashboard() {
  return (
    <div>
      <Navbar/>
      <IncomeSIde/>
    </div>
  )
}

export default AdminDashboard