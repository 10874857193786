import React, { useCallback, useEffect, useState } from "react";

import { FcExpand} from "react-icons/fc";
import { format } from "date-fns";
import {
  getAdminlogin,
  getusertransaction,
  getuserwallet,
  getgetallUSers,
  getuserwalletTransaction,
  getuserwallettransaction,
  getuserwallettransactionall,
  getallusertransaction
} from "../../../Service/GetUser.service";
import { formatNumber } from "../../../Service/Utility";
import {
  Badge,
  Button,
  Form,
  Modal,
  Table,
  Tabs,
  Tab,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import NavbarAdmin from "./NavbarAdmin";
import "./Log.css";
import axios from "axios";
import { useAuth } from "../../../Service/UserAuth";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import e from "cors";

function UserLog() {
  const [users, setusers] = useState([]);
  const { loginuser } = useAuth();
  const [search, setsearch] = useState("");
  const [item, setitem] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [wallet, setwallet] = useState([]);
  const [showtop, setShowtop] = useState(false);
  const [amount, setamount] = useState(0);
  const [trans, settransactions] = useState([]);
  const [transsearch, setTranssearch] = useState("");
  const [lastDocs, setlastDocs] = useState();
  const [isEmpty, setisEmpty] = useState(false);
  const [itemselected, setitemselected] = useState([])
  const [alluserTransaction, setallusertransaction]=  useState([])
    const handleClosetop = () => {
    setShowtop(false);
  };
  const [showtran, setShowtran] = useState(false);
  const handleClosetran = () => setShowtran(false);
  const handleShowtran = () => setShowtran(true);
  const [trxt, settrxt] = useState([]);
  const handleShowtop = () => setShowtop(true);
  const [loading, setloading] = useState(false);
  const [respons, setresposne] = useState("");
  const [pins, setpins] = useState([]);
  const [wallethistory, setwallethistory] = useState([])
  
  const [currentPage, setcurrentpage] = useState(1);
  const recordpage = 10;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = users.slice(firstIndex, lastIndex);
  const npage = Math.ceil(users.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const topUrl = "https://us-central1-mazuflex-app.cloudfunctions.net/getTopUp";
   
  useEffect(() => {
    getgetallUSers((collections) => {
        setusers(collections);
      });

  }, []);


  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };

  const Togglemodal = useCallback(
    (item) => () => {
      setitemselected(item)
      getuserwallet(item.id, (wallet) => {
        setwallet(wallet);
      });
      getusertransaction(item.id,(e) => {
        updateState(e);
      });
      getuserwallettransaction(item.id).then((result)=>{
        updateStatwallet(result)
      })  

      getallusertransaction(item.id).then((result)=>{
       

        result.map((item)=>{
        
        })
       
       setallusertransaction(result)
      })
      setitem(item);
      handleShow();

      
    },
    
    
    []
  );


  const getInFlow = alluserTransaction.filter((e)=>e.type === "Credit")
  const outFlow = alluserTransaction.filter((e)=>e.type !== "Credit")
  
  // let sumtusercomm = alluserTransaction.reduce(function(a, b){
  //   return a + b.amount;
  // },0);



  let sumInflow = getInFlow.reduce(function(a, b){
    return a + b.amount;
  },0);

  let sumOutflow = outFlow.reduce(function(a, b){
    return a + parseFloat(b.amount);
  },0);


 
  const updateState = (users) => {
    const isItemEmpty = users.length === 0;
    if (!isItemEmpty) {
      const lastDoc = users[users.length - 1];
      settransactions(users);
      setlastDocs(lastDoc);
    } else {
      setisEmpty(true);
    }
    setloading(false);
  };
  const fetchMore = () => {
    setloading(true);
    getAdminlogin(trxt.id, lastDocs).then((collections) => {
      updateState(collections);
    });
  };


  const updateStatwallet = (users) => {
    const isItemEmpty = users.length === 0;
    if (!isItemEmpty) {
      const lastDoc = users[users.length - 1];
      setwallethistory(users);
      setlastDocs(lastDoc);
    } else {
      setisEmpty(true);
    }
    setloading(false);
  };
  const fetchMorewallet = () => {
    setloading(true);
    getuserwallettransaction(itemselected.id, lastDocs).then((collections) => {
      setwallethistory(collections);
    });
  
    setloading(false);
  };


  const Toggletransactionmodal = useCallback(
    (item) => () => {
      settrxt(item);
      const pin = item.payloadres ? item.payloadres : [];
      setpins(pin);
      if (pin) {
        setpins(pin.pins);
      }
      handleShowtran();
    },
    []
  );

  const handleTop = async (e) => {
    e.preventDefault();
    setloading(true);
    setresposne("Authentication");
    await axios
      .post(topUrl, {
        userID: loginuser.id,
        id: item.id,
        amount: parseFloat(amount),
      })
      .then((e) => {
        setresposne(e.data.message);
      });
    setloading(false);
  };

  const handlesustenusers = async(e)=>{
    e.preventDefault()
    try {
      await axios.post(` https://us-central1-mazuflex-app.cloudfunctions.net/Userparse`, {
        id:item.id,
        role:loginuser.role,
        type: item.isSuspended ===  true ? "activate" : "notActive"
      }).then((e)=>{
        window.alert(e.data.message)
      })
    } catch (error) {
      window.alert(error.message)
    }
  }
  return (
    <div>
      <ToastContainer />
      <Modal
        show={showtran}
        onHide={handleClosetran}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tabrwapper">
            <div className="userprofilesose">
              <div className="userprofileicon">
                <img
                  src={item.image ? item.image : "/img/logo3.png"}
                  alt="user image"
                />
              </div>
              <div className="userinformations">
                <span className="useenamecin">
                  {item.firstname} {item.lastname}
                </span>
                <br />
                <span>{item.email}</span>
              </div>
            </div>
            <div className="userqwrapper">
              <div className="infologcontent">
                <div className="intitlelog">Transaction ID:</div>
                <div className="ingolog">{trxt.id}</div>
              </div>

              <div className="infologcontent">
                <div className="intitlelog">Service Type :</div>
                <div className="ingolog"> {trxt.company}</div>
              </div>

              <div className="infologcontent">
                <div className="intitlelog">Cutsomer Name:</div>
                <div className="ingolog">{trxt.customername}</div>
              </div>

              <div className="infologcontent">
                <div className="intitlelog">Account Number:</div>
                <div className="ingolog">{trxt.AccountNumber}</div>
              </div>

              {trxt.token ? (
                <div className="infologcontent">
                  <div className="intitlelog">Token:</div>
                  <div className="ingolog">{trxt.token}</div>
                </div>
              ) : (
                ""
              )}

              {trxt.token ? (
                <div className="infologcontent">
                  <div className="intitlelog">Unit:</div>
                  <div className="ingolog">{trxt.unit}</div>
                </div>
              ) : (
                ""
              )}

              {trxt.token ? (
                <div className="infologcontent">
                  <div className="intitlelog">Debt:</div>
                  <div className="ingolog">{trxt.debt}</div>
                </div>
              ) : (
                ""
              )}
              <div className="infologcontent">
                <div className="intitlelog">Address:</div>
                <div className="ingolog">{trxt.address}</div>
              </div>

              <div className="infologcontent">
                <div className="intitlelog">Mobile No.:</div>
                <div className="ingolog">{trxt.phone}</div>
              </div>

              <div className="infologcontent">
                <div className="intitlelog">Amount:</div>
                <div className="ingolog">
                  &#8358; {formatNumber(trxt.amount)}
                </div>
              </div>

              <div className="infologcontent">
                <div className="intitlelog">Date :</div>
                <div className="ingolog">
                  {" "}
                  {trxt.created
                    ? format(trxt.created, "dd/MM/yyyy hh:mm a")
                    : ""}
                </div>
              </div>
              {pins ? (
                <>
                  {pins.map((e) => {
                    return (
                      <>
                        View Generated Pin Below
                        <div className="d-flex ">
                          <p>PIN : {e.pin}</p>
                          <p className="text-danger ms-1">
                            Serial : {e.serialNumber}
                          </p>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <div className="dkpayload">
                  <p className="w-100">{JSON.stringify(trxt.payloadres)}</p>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

      </Modal>

      <Modal
        show={showtop}
        onHide={handleClosetop}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Fund Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Enter Amount</Form.Label>
            <Form.Control
              type="tel"
              onChange={(event) => {
                setamount(event.target.value);
              }}
            />
            <Form.Text className="text-muted">
              Enter amount you want to credit user with. Enter negative value if
              you want to debit
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <center>
          <p className="text-success"> {respons}</p>
        </center>
        <Modal.Footer>
          <Button variant="danger" disabled={loading} onClick={handleClosetop}>
            Close
          </Button>
          <Button variant="primary" disabled={loading} onClick={handleTop}>
            {loading ? (
              <Spinner animation="grow" variant="warning" />
            ) : (
              "Fund Wallet"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {item.firstname} {item.lastname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="home"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="User Details">
              <div className="tabrwapper">
                <div className="userprofilesose">
                  <div className="userprofileicon">
                    <img
                      src={item.image ? item.image : "/img/logo3.png"}
                      alt="user image"
                    />
                  </div>
                  <div className="userinformations">
                    <span className="useenamecin">
                      {item.firstname} {item.lastname}
                    </span>
                    <br />
                    <span>{item.email}</span>
                  </div>
                </div>
                <div className="userqwrapper">
                  <div className="infologcontent">
                    <div className="intitlelog">User ID :</div>
                    <div className="ingolog">{item.id}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Main Wallet :</div>
                    <div className="ingolog">
                      &#8358; {formatNumber(wallet.wallet)}
                    </div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Bonus Balance :</div>
                    <div className="ingolog">{wallet.coin}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Total Balance :</div>
                    <div className="ingolog">
                      &#8358; {formatNumber(wallet.wallet + wallet.coin)}
                    </div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Join :</div>
                    <div className="ingolog">
                      {" "}
                      {item.created
                        ? format(item.created, "dd/MM/yyyy hh:mm a")
                        : ""}
                    </div>
                  </div>
                  <div className="infologcontent">
                    <div className="intitlelog">Account Number:</div>
                    <div className="ingolog">{"pending"}</div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="profile" title="Transaction">
              <div className="logitems">
                <div className="logheader">Transaction History</div>
                <div className="logsearcharea">
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder="search"
                      onChange={(event) => {
                        setTranssearch(event.target.value);
                      }}
                    />
                    <Form.Text className="text-muted">
                      Search by ID, mobile number and first name
                    </Form.Text>
                  </Form.Group>
                </div>
                <div className="logotable">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Date</th>
                        <th>Service</th>
                        <th>Account Number</th>
                        <th>Customer Name</th>
                        <th>Amount</th>
                        <th>Mobile Number</th>
                        <th>Wallet Balance</th>
                        <th>View More</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trans.filter((val)=>{
             
                        if (search === ""){
                          return val
                        }else if (val.type.toLowerCase().includes(transsearch.toLowerCase())){
                          return val
                        }else if (val.customername.includes(transsearch)){
                          return val
                        }  
                      })
                        .map((value, id) => {
                          return (
                            <tr key={id}>
                              <td>{id + 1}</td>
                              <td>
                                {value.created
                                  ? format(value.created, "dd/MM/yyyy hh:mm a")
                                  : ""}
                              </td>
                              <td>{value.type}</td>
                              <td>{value.SquardAccount}</td>
                              <td>{value.customername}</td>
                              <td>{value.amount}</td>
                              <td>{value.phone}</td>
                              <td>{value.walletbalance}</td>
                              <td onClick={Toggletransactionmodal(value)}>
                                <FcExpand />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  {loading && <div className="text-center"> loading ... </div>}
                  {!loading && !isEmpty && (
                    <div className="text-center newlogbuttin">
                      <button onClick={fetchMore} >see more</button>
                    </div>
                  )}
                  {isEmpty && <h1 className="hshhs">no more data to fetch</h1>}
                </div>
              </div>
            </Tab>

            <Tab eventKey="Wallet" title="Wallet History">
              <div className="logitems">
                <div className="logheader">Wallet History</div>
                <div className="logsearcharea">
                  <Form.Group className="mb-3">
                    <Form.Control
                      placeholder="search"
                      onChange={(event) => {
                        setTranssearch(event.target.value);
                      }}
                    />
                    <Form.Text className="text-muted">
                      Search by ID, mobile number and first name
                    </Form.Text>
                  </Form.Group>
                </div>
                <div className="logotable">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Pre-Bal</th>
                        <th>Post-Bal</th>
                        <th>Status</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      {wallethistory.filter((val)=>{
             
                        if (search === ""){
                          return val
                        }else if (val.type.toLowerCase().includes(transsearch.toLowerCase())){
                          return val
                        }else if (val.customername.includes(transsearch)){
                          return val
                        }  
                      })
                        .map((value, id) => {
                          return (
                            <tr key={id}>
                              <td>{id + 1}</td>
                              <td>
                                {value.created
                                  ? format(value.created, "dd/MM/yyyy hh:mm a")
                                  : ""}
                              </td>
                              <td>{value.type}</td>
                              <td>{formatNumber(value.PreBal)}</td>
                              <td>{formatNumber(value.postBal)}</td>
                              <td>{value.status}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  {loading && <div className="text-center"> loading ... </div>}
                  {!loading && !isEmpty && (
                    <div className="text-center newlogbuttin">
                      <button onClick={fetchMorewallet} >see more</button>
                    </div>
                  )}
                  {isEmpty && <h1 className="hshhs">no more data to fetch</h1>}
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Dropdown>
            <Dropdown.Toggle variant="info" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={handleShowtop}>
                Credit/Debit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger my-2" onClick={handlesustenusers}>
                Suspend {item.lastname}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Footer>
      </Modal>
      <NavbarAdmin />

      <div className="logcontainer">
        <div className="logcontent">
          <div className="logitems">
            <div className="logheader">User Log</div>
            <div className="logsearcharea">
              <span variant="primary">
                Number of Users <Badge bg="danger">{users.length}</Badge>
              </span>

              <span variant="primary">
                Number of Agent <Badge bg="info">{users.length}</Badge>
              </span>

              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="search"
                  onChange={(event) => {
                    setsearch(event.target.value);
                  }}
                />
              
              </Form.Group>
            </div>
            <div className="logotable">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Date</th>
                    <th>Full Name</th>
                    <th>Account Number</th>
                    <th>Account Type</th>
                    <th>Email &#8358;</th>
                    <th>Mobile Number</th>
                    <th>View More</th>
                  </tr>
                </thead>
                <tbody>
                  {records
                    .filter((val) => {
                      if (search === "") {
                        return val;
                      } else if (val.uniquinum.includes(search)) {
                        return val;
                      } else if (val.number.includes(search)) {
                        return val;
                      } else if (
                        val.firstname
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.lastname
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    .map((value, id) => {
                      return (
                        <tr key={id}>
                          <td>{id + 1}</td>
                          <td>
                            {value.created
                              ? format(value.created, "dd/MM/yyyy hh:mm a")
                              : ""}
                          </td>
                          <td>
                            {value.lastname} {value.firstname}
                          </td>
                          <td>{value.SquardAccount}</td>
                          <td>{value.email}</td>
                          <td>{value.permissions}</td>
                          <td>{value.number}</td>
                          <td onClick={Togglemodal(value)}>
                            <FcExpand />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>
                {numbers.slice(0, 10).map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span
                        className="page-link"
                        onClick={() => changecurrentPage(n)}
                      >
                        {n < 11 ? n : n + 1}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLog;
