import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useAuth } from "../../Service/UserAuth";
import { toast, ToastContainer } from "react-toastify";
import "./Services.css";
import firebase from "firebase";
import { formatNumber } from "../../Service/Utility";
import { database } from "../../firebase";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { getCurrentPayload } from "../../Service/GetUser.service";

function ElectricityPayment() {
  const history = useHistory();
  const [provider, setprovider] = useState([]);
  const { loginuser } = useAuth();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [arrangeTken, setarrangeToken] = useState("")
  const [showpayload, setShowpayload] = useState(false);
  const handleClosepayload = () => {
    setShowpayload(false);
    window.location.href = "/electricity";
  };
  const handleShowpayload = () => setShowpayload(true);
  const [payload, setpayload] = useState([]);
  const [amount, setamount] = useState(0);
  const [loading, setloading] = useState(false);
  const [phonea, setphone] = useState("");
  const [meternumber, setmeternumber] = useState("");
  const [act, setact] = useState("");
  const { userwallet, commission } = useAuth();
  const [status, setstatus] = useState("");
  const [powerpayload, setpowerpayload] = useState([]);
  const [errorpayload, seterrorpowerpayload] = useState("");
  const [response, setresponse] = useState("");
  const [selectedcable, setpowerselected] = useState("");
  const [showprint, setShowprint] = useState(false);
  const handleClosesetShowprint = () => setShowprint(false);
  const handleShowsetShowprint = () => setShowprint(true);
  const [disable, setdisable] = useState(false)
  const [fullscreen, setFullscreen] = useState(true);
  const [datapayload, setdatapayload] = useState([])
  const [comm, setcom] = useState(0);
  const [image, setimage] = useState(
    "https://i.ibb.co/HgkgGnh/images-removebg-preview.png"
  );

  const finderurl =
    "https://us-central1-mazuflex-app.cloudfunctions.net/Getnamefinder";
  const [selectdpower, setselectedpower] = useState();
  const baseURL =
    "https://us-central1-mazuflex-app.cloudfunctions.net/getpower";
  const walleturl =
    "https://us-central1-mazuflex-app.cloudfunctions.net/getuserwallet";

  const buypowerUrl =
    "https://us-central1-mazuflex-app.cloudfunctions.net/buyPowerHandler";
  useEffect(() => {
    axios.get(baseURL).then((response) => {
      if (response) {
        setprovider(response.data.data);
      }
    });

    const selectpowerobject = provider.find(
      (item) => item.service_type === selectedcable
    );
    setselectedpower(selectpowerobject);
  }, [selectedcable, meternumber, response, powerpayload]);

  const handlechnage = (e) => {
    const selectedpower = e.target.value;
    setpowerselected(selectedpower);
    if (selectedpower === "abuja_electric_prepaid") {
      const comission = commission.aedc;
      setcom(comission);
      setimage("https://cexpress.com.ng/assets/images/electricity/3.jpg");
    } else if (selectedpower === "abuja_electric_postpaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/3.jpg");
    } else if (selectedpower === "jos_electric_prepaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/6.jpg");
    } else if (selectedpower === "jos_electric_prepaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/6.jpg");
    } else if (selectedpower === "eko_electric_postpaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/1.jpg");
    } else if (selectedpower === "eko_electric_prepaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/1.jpg");
    } else if (selectedpower === "ikeja_electric_prepaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/2.jpg");
    } else if (selectedpower === "ikeja_electric_postpaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/2.jpg");
    } else if (selectedpower === "kedco_electric_prepaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/4.jpg");
    } else if (selectedpower === "kedco_electric_postpaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/4.jpg");
    } else if (selectedpower === "portharcourt_electric_prepaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/5.jpg");
    } else if (selectedpower === "portharcourt_electric_postpaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/5.jpg");
    } else if (selectedpower === "kaduna_electric_prepaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/8.jpg");
    } else if (selectedpower === "kaduna_electric_postpaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/8.jpg");
    } else if (selectedpower === "ibadan_electric_prepaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/7.jpg");
    } else if (selectedpower === "ibadan_electric_postpaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/7.jpg");
    } else if (selectedpower === "enugu_electric_prepaid") {
      const comission = commission.enugu;
      setcom(comission);
      setimage("https://cexpress.com.ng/assets/images/electricity/9.jpg");
    } else if (selectedpower === "enugu_electric_postpaid") {
      setimage("https://cexpress.com.ng/assets/images/electricity/9.jpg");
    } else {
      setimage(
        "https://i.ibb.co/HgkgGnh/images-removebg-preview.png"
      );
    }
  };

  const date = format(new Date(), "dd/MM/yyyy hh:mm a");
  const phone = phonea ? phonea : loginuser.number;


  const handlePring = (e) => {
    e.preventDefault()
    history.push(`/transaction/${datapayload.id}`)
  }

  

  const handleamount = (e) => {
    const a = parseFloat(e.target.value);
    const com = 100;
    const amount = loginuser.permissions === "user" ? a + com : a;
    setamount(amount);
  };

  const raw = powerpayload ? powerpayload : [];
  const ref = database.collection("_").doc().id;
  const handlepayment = async (e) => {
    e.preventDefault();
    const id = database.collection("_").doc().id;
    if (userwallet.wallet < amount) {
      toast.error("Insufficent wallet balance, Please Top", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setloading(false);
      return;
    }
    setloading(true);

    try {
      await axios
        .post(walleturl, { id: userwallet.id, amount: amount })
        .then(async (e) => {

          if (e.data.message !== "success") {
            toast.error("Insufficent wallet balance, Please Top", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return
          }
          setresponse("validating");
          const selectedprovider = selectdpower.service_type;
          const com =
            comm !== 0 || comm !== undefined || comm !== null
              ? comm * amount
              : 0;
          const data1 = {
            id: id,
            company: selectedprovider,
            customername: payload.name,
            address: "no value",
            userID: loginuser.id,
            AccountNumber: meternumber,
            walletbalance: userwallet.wallet,
            token: "no value",
            debt: "no value",
            amount: amount,
            phone: phone,
            type: "Power",
            errorpayload,
            status: "Network Error",
            created: firebase.firestore.FieldValue.serverTimestamp(),
          };


          const vamount = parseFloat(e.data.amount)
          if (e.data.message === "success") {

            setresponse("Generating Token ..");
            await axios
              .post(buypowerUrl, {
                service_type: selectedprovider,
                account_number: meternumber,
                amount: amount,
                phone: phone,
                ref: ref,
                isApproved: e.data.isApproved,
                verifyamount: vamount
              })
              .then(async (e) => {
             
                setresponse("Generating Token ..");
                setdisable(true)
                const raw = e.data.data;
             
                  if (e.data.status === "success") {

                  const [payloadresponse, payloadData] = getCurrentPayload(e.data, selectedcable)
               
                  const tokenval = payloadData.token
                  
                  let chunks = [];
                  for (let i = 0; i < tokenval.length; i += 4) {
                    chunks.push(tokenval.substr(i, 4));
                  }
                  const arranToken = chunks.join("-"); 
                
                  const data = {
                    id: id,
                    company: selectedprovider,
                    customername: payload.name,
                    address: payload.address,
                    userID: loginuser.id,

                    AccountNumber: meternumber,
                    unit: payloadData.units,
                    walletbalance: userwallet.wallet,
                    token:
                    selectedprovider === "jos_electric_prepaid" ||
                    selectedprovider === "kaduna_electric_prepaid"
                      ? payloadData.token
                      : arranToken,
                    debt: payloadData.debtAmount,
                    AccountBallance:payloadData.accountBalance,
                    amount: amount,
                    phone: phone,
                    type: "Power",
                    isToday: true,
                    status: "success",
                    payloadres: e.data.data,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                  };
                  const topUrl =
                    "https://us-central1-mazuflex-app.cloudfunctions.net/commissioncredit";
                  const message = `Dear Customer!! 
Your ${selectdpower.service_type} Token: ${raw.creditToken}. Thank you for choosen Mezuflex`;
                  const url =
                    "https://us-central1-mazuflex-app.cloudfunctions.net/SmsHandlernew";
                  const promisses = [
                    database.collection("Transaction").doc(data.id).set(data),
                    axios.post(url, { message: message, recipient: phone }),
                    axios.post(topUrl, {
                      userID: loginuser.id,
                      amount: parseFloat(com),
                      price: amount,
                      admincom: com,
                    }),
                    database.doc(`/income/66DyYlsUXUHQdRUIkDrs`).update({
                      electricityincome: firebase.firestore.FieldValue.increment(com),
                      totalincome: firebase.firestore.FieldValue.increment(com),
                    })
                  ];
                  handleClose();
                  setstatus("success");
                  handleShowpayload();
                  setdatapayload(data)
                  await Promise.all(promisses)

                } else {
                  handleClose();
                  seterrorpowerpayload(e.data.data);
                  setstatus("error");
                  handleShowpayload();
                  setpayload(data1)
                  await database
                    .collection("Transaction")
                    .doc(data1.id)
                    .set(data1);
                }
                setdisable(false)
              })
              .catch((e) => {
                toast.error(e.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          } else {
            toast.error("Insufficent wallet balance, Please Top", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setresponse("");
          }
        })
        .catch((e) => {

          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (e) {
      toast.error(e.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setloading(false);
  };

  const getmeterinfmation = async (e) => {
    e.preventDefault();

    if (!selectdpower || meternumber === " ") {
      toast.error("BAD FORMAT");
      return;
    }
    setloading(true);
    const selectedprovider = selectdpower.service_type;
    try {
      await axios
        .post(finderurl, {
          service_type: selectedprovider,
          account_number: meternumber,
        })
        .then((e) => {
          setpayload(e.data.data.user);
          setresponse("");
          handleShow();
        })
        .catch((e) => {
          setpayload(e.message);
          toast.error("Invalid Consumer Information", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      toast.error(e.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setloading(false);
  };

  const handleshowprint = () => {
    handleShowsetShowprint();
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        show={showprint}
        onHide={handleClosesetShowprint}
        backdrop="static"
        fullscreen={fullscreen}
        keyboard={false}
      >
        <div className="reciepheader">
          <img src="/img/logo3.png" alt="" />
        </div>
        <Modal.Header closeButton>
          <Modal.Title>
            Transaction Detail
            <span>Generated from Mezuflex on the {date}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {status === "success" ? (
            <div className="infocontainer">
              <div className="inforcontenr">
                <div className="infotittle">Company :</div>
                <div className="infodetails">
                  {selectdpower ? selectdpower.service_type : ""}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Meter Number :</div>
                <div className="infodetails">{datapayload.accountNumber}</div>
              </div>
              
              <div className="inforcontenr">
                <div className="infotittle">Customer Name :</div>
                <div className="infodetails">{payload.name}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Address :</div>
                <div className="infodetails">{payload.address}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Amount :</div>
                <div className="infodetails">
                  &#8358; {formatNumber(amount)}
                </div>
              </div>


              <div className="inforcontenr">
                <div className="infotittle">Token :</div>
                <div className="infodetails">{datapayload.token}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Unit :</div>
                <div className="infodetails">{datapayload.unit}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Debit Amount :</div>
                <div className="infodetails">
                  {datapayload.debt}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Account Balance :</div>
                <div className="infodetails">
                  {datapayload.AccountBallance}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Transaction ID :</div>
                <div className="infodetails">{datapayload.id}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Transaction Time :</div>
                <div className="infodetails">{date}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Message :</div>
                <div className="infodetails">
                  Thanks for using mezuflex. vend with us next time.
                </div>
              </div>
              <div className="d-flex">
                <Button
                  className="w-100 m-1"
                  type="submit"
                  onClick={handleClosepayload}
                >
                  back
                </Button>

                <Button
                  className="w-100 m-1"
                  type="submit"
                  onClick={() => window.print()}
                >
                  Print
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>

      <Modal
        centered
        backdrop="static"
        show={showpayload}
        onHide={handleClosepayload}
        animation={false}
      >
        <Modal.Body>
          {status === "error" ? (
            <>
              <div className="headersjdjd bg-danger">
                <h1>ERROR</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectdpower ? selectdpower.service_type : ""}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Meter Number :</div>
                  <div className="infodetails">{payload.accountNumber}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Customer Name :</div>
                  <div className="infodetails">{payload.name}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Address :</div>
                  <div className="infodetails">{payload.address}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(amount)}
                  </div>
                </div>
                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Message :</div>
                  <div className="infodetails">
                    Transaction Error, Please contact support
                  </div>
                </div>
                <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    disabled={disable}
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button className="w-100 m-1" type="submit" disabled={disable} onClick={handlePring}>
                    Print
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="headersjdjd bg-success">
                <h1>SUCCESS</h1>
              </div>
              <p>Transaction Details</p>
              {status === "success" ? (
                <div className="infocontainer">
                  <div className="inforcontenr">
                    <div className="infotittle">Company :</div>
                    <div className="infodetails">
                      {selectdpower ? selectdpower.service_type : ""}
                    </div>
                  </div>

                  <div className="inforcontenr">
                    <div className="infotittle">Meter Number :</div>
                    <div className="infodetails">{payload.accountNumber}</div>
                  </div>

                  <div className="inforcontenr">
                    <div className="infotittle">Customer Name :</div>
                    <div className="infodetails">{payload.name}</div>
                  </div>

                  <div className="inforcontenr">
                    <div className="infotittle">Address :</div>
                    <div className="infodetails">{payload.address}</div>
                  </div>

                  <div className="inforcontenr">
                    <div className="infotittle">Amount :</div>
                    <div className="infodetails">
                      &#8358; {formatNumber(amount)}
                    </div>
                  </div>

                  <div className="inforcontenr">
                    <div className="infotittle">Token :</div>
                    <div className="infodetails">
                      {datapayload.token}
                    </div>
                  </div>

                  <div className="inforcontenr">
                    <div className="infotittle">Unit :</div>
                    <div className="infodetails">
                      {datapayload.unit}
                    </div>
                  </div>
                 
                    <div className="inforcontenr">
                      <div className="infotittle">Debt Amount :</div>
                      <div className="infodetails">
                        {datapayload.debt}
                      </div>
                    </div>

                    <div className="inforcontenr">
                      <div className="infotittle">Account Balance :</div>
                      <div className="infodetails">
                        {datapayload.accountBalance}
                      </div>
                    </div>

                  <div className="inforcontenr">
                    <div className="infotittle">Transaction ID :</div>
                    <div className="infodetails">{datapayload.id}</div>
                  </div>

                  <div className="inforcontenr">
                    <div className="infotittle">Transaction Time :</div>
                    <div className="infodetails">{date}</div>
                  </div>

                  <div className="inforcontenr">
                    <div className="infotittle">Message :</div>
                    <div className="infodetails">
                      Thanks for using mezuflex. vend with us next time.
                    </div>
                  </div>
                  <div className="d-flex">
                    <Button
                      className="w-100 m-1"
                      type="submit"
                      onClick={handleClosepayload}
                      disabled={disable}
                    >
                      back
                    </Button>

                    <Button
                      className="w-100 m-1"
                      type="submit"
                      onClick={handlePring}
                      disabled={disable}
                    >
                      Print
                    </Button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        centered
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div className="headersjdjd">
            <h1>In-Progress</h1>
          </div>
          <p>Kindly confirm the information below before you proceed</p>
          <div className="infocontainer">
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">
                {selectdpower ? selectdpower.service_type : ""}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Meter Number :</div>
              <div className="infodetails">{payload.accountNumber}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">{payload.name}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Address :</div>
              <div className="infodetails">{payload.address}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Amount :</div>
              <div className="infodetails">&#8358; {formatNumber(amount)}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">charge :</div>
              <div className="infodetails">
                {loginuser.permissions === "user" ? 100 : 0}
              </div>
            </div>

            {/* <div className="inforcontenr bg-info">
              <div className="infotittle"> Total Amount :</div>
              <div className="infodetails">
                {" "}
                &#8358; {formatNumber(amount)}{" "}
              </div>
            </div> */}

            <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">{date}</div>
            </div>
          </div>
        </Modal.Body>

        <center className="info">{response}</center>
        <div className="d-flex">
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlepayment}
            className="m-1 w-50"
            type="submit"
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Pay with Wallet"
            )}
          </Button>

          <Button
            variant="danger"
            onClick={handleClose}
            className="m-1 w-50"
            type="submit"
          >
            Back
          </Button>
        </div>
      </Modal>

      <div className="allcablesyns">
        <div className="allcableitems">
          <div className="cableallre">
            <div className="imagesetuib">
              <img src={image} alt="" />
            </div>
            <div className="productsed">
              <h2>
                <center className="text-dark">VEND ELECTRICITY</center>
              </h2>
              <Form.Label className="text-dark my-3">Electricity</Form.Label>
              <Form.Select
                required
                aria-label="Floating label select example"
                value={selectedcable}
                onChange={handlechnage}
              >
                <option key="">Please Select Provider</option>
                {provider
                  ? provider.map((selectedcable) => (

                    <option key={selectedcable.service_type}>
                      {selectedcable.service_type}
                    </option>
                  ))
                  : ""}
              </Form.Select>

              <div className="optionshhd">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-dark my-3">
                    Enter Meter Number
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    onChange={(e) => {
                      setmeternumber(e.target.value);
                    }}
                    placeholder="Meter Number"
                    required
                  />
                  <Form.Text className="text-muted">
                    {act ? act : "Verification Required."}
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-dark my-2">Amount</Form.Label>
                  <Form.Control type="tel" onChange={handleamount} />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-dark my-2">
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    placeholder={loginuser.number}
                    type="email"
                    onChange={(event) => {
                      setphone(event.target.value);
                    }}
                  />
                </Form.Group>
                <Button
                  variant={loading ? "warning" : "primary"}
                  disabled={loading}
                  onClick={getmeterinfmation}
                  className="w-100"
                  type="submit"
                >
                  Verify Account
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ElectricityPayment;
