import React, { useEffect } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import "./Create.css";
import axios from "axios";
import { useState } from "react";
import { storage } from "../../../../firebase";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../../../Service/UserAuth";
import { FcApproval } from "react-icons/fc";
import { getBVN } from "../../../../Service/GetUser.service";
import { useHistory } from "react-router-dom";
import { getRandomString } from "../../../../Service/GetRandomNumber";
function Becomecomponent() {
  const { loginuser, userwallet, userAccount } = useAuth();
  const [bvn, setbvn] = useState("");
  const [disable, setdisable] = useState(true);
  const [loading, setloading] = useState(false);
  const [result, setresult] = useState([]);
  const [image, setimage] = useState(null);
  const [address, setaddress] = useState("");
  const [message, setmessage] = useState("");
  const [progress, setProgress] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    window.location.href = "/dashboard";
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [businessname, setbusinessname] = useState("");
  const [buinsessPhone, setbusinessPhone] = useState("");
  const [businessAddress, setbusinessAddress] = useState("");
  const [res, setres] = useState("BVN is required for verification Purpose");
  const [payloadres, setpayloadmessage] = useState(false);
  const history = useHistory();
  const Baseurl =
    "https://us-central1-mazuflex-app.cloudfunctions.net/verifyUser";
  // const Baseurl = "https://us-central1-mazuflex-app.cloudfunctions.net/verifyBvnUfitpay"

  useEffect(() => {
    if (userwallet.wallet < 500) {
      handleShow();
      return;
    }

    if (loginuser.isAgent === true) {
      history.push("/agent/dashboard");
      return;
    }

    if (loginuser.isBlock === true) {
      history.push("/login");
      return;
    }
  }, [disable, loading, show]);

  // const createAccount= async(e)=>{
  // //   const lastname = ? result.last_name.toLowerCase() : ""
  // // const firstname =  ? result.first_name.toLowerCase() : ""

  //   const bvn = e
  //   const data = {
  //   bank : "ENRICH",
  //   bvn  : bvn,
  //   first_name : result.first_name ,
  //   last_name : result.last_name  ,
  //   business_name : businessname,
  //   userID:loginuser.id
  //   }

  //   try {
  //     axios
  //     .post("https://us-central1-mazuflex-app.cloudfunctions.net/createAccount", {
  //       data: data,
  //     }).then((e)=>{

  //     }).catch((e)=>{

  //     })
  //   } catch (error) {
  //     console.log(error.message)
  //   }
  // }
 

  const getbvn = async (e) => {
    const bvnData = e.target.value;
    setbvn(bvnData);
    setres("BVN is required for verification Purpose");
    if (bvnData.length === 11) {
      setres("Fetching Data...");
      const url = `https://us-central1-mazuflex-app.cloudfunctions.net/getBvnUser/users/${bvnData}`;
      axios.get(url).then(async (e) => {
        const user = e.data[0];
        if (user) {
          toast.error("Record use by another user", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
        setres("finalizing ...");
        await axios
          .get(
            `https://us-central1-mazuflex-app.cloudfunctions.net/verifyUser/agent/${bvnData}`
          )
          .then(async (e) => {
            const data = e.data[0];
            if (data) {
              setresult(data.payload.data);
              setdisable(false);
              setloading(false);
              setres("");

              return;
            }
            // await axios
            // .post(
            //  Baseurl,
            //    { bvn: bvnData }
            //  )
            //  .then((e) => {
            //    console.log(e)
            //    setres("finalizing ...");
            //    if (e.data.status !== "success") {
            //      toast.error("Invalid user record", {
            //        position: toast.POSITION.TOP_RIGHT,
            //      });
            //      setloading(false);
            //      setres("Invalid User Record");
            //      return;
            //    }
            //    setres("");
            //    setresult(e.data.data);
            //    setdisable(false);
            //    setloading(false);
            //  });
            await axios.post(Baseurl, { bvn: bvnData }).then((e) => {
         
              setres("finalizing ...");
              if (e.data.status !== "successful") {
                toast.error("Invalid user record", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                setloading(false);
                setres("Invalid User Record");
                return;
              }
              setres("");
              setresult(e.data.data);
              setdisable(false);
              setloading(false);
            });
          });
        setloading(false);
      });
    }
  };

  const handleupload = (e) => {
    if (e.target.files[0]) {
      setimage(e.target.files[0]);
    }
  };

  function handleUpload() {
    return new Promise((resolve) => {
      const uploadTask = storage.ref(`customerImage/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("customerImage")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  const handleUpgrade = async (e) => {
    e.preventDefault();
    // await createAccount(bvn)
    setloading(true);
    const image = await handleUpload();
    const gen = getRandomString(
      35,
      "zxcvbnmasdfghjklqwertyuiopZXCVBNM,ASDFGHJKQWERTYUIOP!@#$%^&*1234567890"
    );
    const Token = `${loginuser.id}${gen}`;
  
    const data = {
      firstname: loginuser.firstname,
      lastname: loginuser.lastname,
      dob: "nill",
      image,
      bvn:"nill",
      token: Token,
      phone:  loginuser.number,
      address: loginuser.businessname,
      businessAddress,
      businessname,
      userID: loginuser.id,
      buinsessPhone,
    };

  

   await axios
      .post("https://us-central1-mazuflex-app.cloudfunctions.net/getsetAgent", {
        data: data,
      })
      .then((e) => {
        if (e.data.status === "success") {
          setpayloadmessage(true);
          toast.success("Request Sent Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((e) => {
      
      });

    setloading(false);
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Kindly fund your wallet first before you can continue with this
          process. Please note that any amount deposited will be credited to
          your wallet. For more information on your funding account number,
          kindly deposit to your dedicated account shown below

          {userwallet.length !==0 ? <>
            <div className="showaccoint">
            <div className="sjje"> <br/>
              <p>Account Number : {userAccount.account_number}</p>
              <p> Bank : WEMA Bank</p>
              <p> Account Name : {userAccount.account_name}</p>
            </div>
          </div></> : ""}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      <div className="becomecontaine">
        {payloadres ? (
          <>
            <div className="respinsconaybe">
              <div className="resoncslcod">
                <div className="respaksheadd">
                  <FcApproval />
                </div>
                <div className="respnisjs">
                  Agent Registration Successful!!, your agent account will be
                  approved shortly. Meanwhile, Do you know as a mezuflex agent
                  you can now vend all services with zero service charge?
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="vecomcionndd">
            <div className="becoahhdhd">
              <div className="becomeheader">BECOME A MAZUFLEX AGENT</div>
              <hr />
              <div className="messagecontenr">
                <div className="detaolssoleaghent">
                  <p>
                    Join Mezuflex agents FREE today and start reselling our bill
                    payment services, Resell Mobile Data and more to your
                    customers at much lower price.
                  </p>
                  <p>
                    You get special discounts on your payments. You enjoy ZERO
                    daily online transaction limit. You get paid special bonus
                    when you vend our services from your agent account.
                  </p>
                  <h1>APPLY HERE</h1>
                  <div className="formconiner">
                    <p>Personal Information</p>
                    <Form.Group className="mb-3">
                    </Form.Group>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>First name</Form.Label>
                        <Form.Control disabled value={loginuser.firstname} />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control disabled value={loginuser.lastname} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control disabled value={loginuser.number} />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control disabled={disable} value={loginuser.email} />
                      </Form.Group>
                    </Row>
                
                    <p>Business Information</p>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Business Name</Form.Label>
                        <Form.Control
                          
                          onChange={(event) => {
                            setbusinessname(event.target.value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Business Mobile Number</Form.Label>
                        <Form.Control
                         
                          onChange={(event) => {
                            setbusinessPhone(event.target.value);
                          }}
                        />
                      </Form.Group>
                    </Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Business Address</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(event) => {
                          setbusinessAddress(event.target.value);
                        }}
                      />
                    </Form.Group>
                    <p> Supporting Document</p>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Upload Passport</Form.Label>
                      <Form.Control
                        type="file"
                       
                        onChange={handleupload}
                        required
                      />
                    </Form.Group>
                  </div>
                  <Button
                    variant="success"
                    className="w-100"
                    onClick={handleUpgrade}
                  >
                    {loading ? (
                      <Spinner
                        animation="grow"
                        size="sm"
                        className="my-2"
                        variant="danger"
                      ></Spinner>
                    ) : (
                      "Upgrade Account"
                    )}{" "}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Becomecomponent;
