import { database } from "../firebase";
import { TimestampDate } from "timestamp-date";
import axios from "axios"

const timestampDate = new TimestampDate();



export const getuserlogin = () => {
  return new Promise((resolve) => {
    let reference = database.collection("users"); 
    reference
      .limit(40)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });
        resolve(items);
      });
  });
};

export const getAdminlogin = () => {
  return new Promise((resolve) => {
    let reference = database.collection("users").orderBy("created", "desc"); 
    reference
      .limit(5)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });
        resolve(items);
      });
  });
};

// export const getgetallUSers = () => {
//   return new Promise((resolve) => {
//     let reference = database.collection("users").orderBy("created", "desc"); 
//     reference
//       .limit(30)
//       .get()
//       .then((querylogs) => {
//         const items = [];
//         querylogs.forEach((doc) => {
//           const item = doc.data();
//           item.id = doc.id;
//           item.__doc = doc;
//           items.push(timestampDate.parseTimestampToDate(item));
//         });
//         resolve(items);
//       });
//   });
// };
export const getAdminTrfansaction = () => {
  return new Promise((resolve) => {
    let reference = database.collection("Transaction").orderBy("created", "desc"); 
    reference
      .limit(30)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });
        resolve(items);
      });
  });
};


export const getAdminnotification = () => {
  return new Promise((resolve) => {
    let reference = database.collection("PaymentNotification").orderBy("created", "desc"); 
    reference
      .limit(30)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });
        resolve(items);
      });
  });
};


export const getuser = (id , cd) => {
  const ref = database.doc(`users/${id}`);
  ref.onSnapshot((queryproduct) => {
    const user = queryproduct.data();
   
   cd(user);
  });
};





// export const validatore = (id, cd) => {

 
//     let reference = database.collection("users");
//     if (id) {
//       reference = reference.where("id", "==", id);
//     }
//     reference
//       .get()
//       .then((querylogs) => {
//         const items = [];
//         querylogs.forEach((doc) => {
//           const item = doc.data();
//           item.id = doc.id;
//           items.push(timestampDate.parseTimestampToDate(item));
//         });
//      console.log(items)
//         cd(items);
//       });

// };


export const getuserwallettransactions = (id, cd) => {

  
  let reference = database.collection("WalletTransaction").orderBy("created", "desc");
  if (id) {
    reference = reference.where("userID", "==", id);
  }
  reference
    .get()
    .then((querylogs) => {
      const items = [];
      querylogs.forEach((doc) => {
        const item = doc.data();
        item.id = doc.id;
        items.push(timestampDate.parseTimestampToDate(item));
      });

      cd(items);
    });

};

export const getusertransaction = (id, cd) => {
    let reference = database.collection("Transaction").orderBy("created", "desc");
    if (id) {
      reference = reference.where("userID", "==", id);
    }
    reference
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          items.push(timestampDate.parseTimestampToDate(item));
        });

        cd(items);
      });

};

// export const getgetallUSers = () => {
//   return new Promise((resolve) => {
//     let reference = database.collection("users").orderBy("created", "desc"); 
//     reference
//       .limit(30)
//       .get()
//       .then((querylogs) => {
//         const items = [];
//         querylogs.forEach((doc) => {
//           const item = doc.data();
//           item.id = doc.id;
//           item.__doc = doc;
//           items.push(timestampDate.parseTimestampToDate(item));
//         });
//         resolve(items);
//       });
//   });
// };

export const getgetallUSers = (cd) => {
  let reference = database.collection("users").orderBy("created", "desc");

  reference
    .get()
    .then((querylogs) => {
      const items = [];
      querylogs.forEach((doc) => {
        const item = doc.data();
        item.id = doc.id;
        items.push(timestampDate.parseTimestampToDate(item));
      });

      cd(items);
    });

};

export const getadmtransaction = (cd) => {
  let reference = database.collection("APITransaction").orderBy("created", "desc");
  // if (id) {
  //   reference = reference.where("userID", "==", id);
  // }
  reference
    .get()
    .then((querylogs) => {
      const items = [];
      querylogs.forEach((doc) => {
        const item = doc.data();
        item.id = doc.id;
        items.push(timestampDate.parseTimestampToDate(item));
      });

      cd(items);
    });

};

export const getallusertransaction = (id, lastDoc) => {

  return new Promise((resolve) => {
    let reference = database.collection("Transaction").orderBy("created", "desc");

    if (id) {
      reference = reference.where("userID", "==", id);
    }

    if (lastDoc) {
      reference = reference.startAfter(lastDoc.__doc);
    }

    reference
      .limit(10)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });

        resolve(items);
      });
  });
};

export const getuserwallettransaction = (id, lastDoc) => {
 
  return new Promise((resolve) => {
    let reference = database.collection("WalletTransaction").orderBy("created", "desc");

    if (id) {
      reference = reference.where("userID", "==", id);
    }

    if (lastDoc) {
      reference = reference.startAfter(lastDoc.__doc);
    }

    reference
      .limit(10)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });

        resolve(items);
      });
  });
};

export const getuserwallettransactionall = (id, lastDoc) => {
 
  return new Promise((resolve) => {
    let reference = database.collection("WalletTransaction").orderBy("created", "desc");

    if (id) {
      reference = reference.where("userID", "==", id);
    }

    if (lastDoc) {
      reference = reference.startAfter(lastDoc.__doc);
    }

    reference
   
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });

        resolve(items);
      });
  });
};
export const getuserwallet = (id , cd) => {
  const ref = database.doc(`wallet/${id}`);
  ref.onSnapshot((queryproduct) => {
    const user = queryproduct.data();
   cd(user);
  });
};

export const getusertransactiondetails = (id , cd) => {
  
  const ref = database.doc(`Transaction/${id}`);
  ref.onSnapshot((queryproduct) => {
    const user = queryproduct.data();
   cd(timestampDate.parseTimestampToDate(user));
  });
};

export const getuserwalletTransaction = (id , cd) => {
  
  const ref = database.doc(`WalletTransaction/${id}`).orderBy("created", "desc");
  ref.onSnapshot((queryproduct) => {
    const user = queryproduct.data();
   cd(timestampDate.parseTimestampToDate(user));
  });
};

export const getusercount = (cd) => {

  const ref = database.collection("user");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate (item));
    });

    cd(items);

    
  });
};


export const getEod = (cd) => {

  const ref = database.collection("EOD").orderBy("created", "desc");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate (item));
    });

    cd(items);

    
  });
};



export const getweekly = (cd) => {

  const ref = database.collection("WeeklyReport").orderBy("created", "desc");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate (item));
    });

    cd(items);

    
  });
};



export const getMonthlyReport = (cd) => {

  const ref = database.collection("MonthlyReport").orderBy("created", "desc");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate (item));
    });

    cd(items);

    
  });
};
export const getReport = (id, cd) => {

  const ref = database.collection("Report").orderBy("created", "desc");
  let reference = ref;
  if (id) {
    reference = reference.where("userID", "==", id);
  }
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate (item));
    });

    cd(items);

    
  });
};


export const getversion = (cd) => {
  const ref = database.collection("version");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(item);
    });

    cd(items);

    
  });
};


export const getAllTransaction = (cd) => {
  let reference = database.collection("Transaction").orderBy("created", "desc");
  // if (id) {
  //   reference = reference.where("userID", "==", id);
  // }
  reference
    .get()
    .then((querylogs) => {
      const items = [];
      querylogs.forEach((doc) => {
        const item = doc.data();
        item.id = doc.id;
        items.push(timestampDate.parseTimestampToDate(item));
      });

      cd(items);
    });

};

export const getApiTransaction = (cd) => {
  let reference = database.collection("ApiTransaction").orderBy("created", "desc");
  // if (id) {
  //   reference = reference.where("userID", "==", id);
  // }
  reference
    .get()
    .then((querylogs) => {
      const items = [];
      querylogs.forEach((doc) => {
        const item = doc.data();
        item.id = doc.id;
        items.push(timestampDate.parseTimestampToDate(item));
      });

      cd(items);
    });

};
// export const getApiTransaction = (lastDoc) => {

//   return new Promise((resolve) => {
//     let reference = database.collection("ApiTransaction").orderBy("created", "desc");
//     if (lastDoc) {
//       reference = reference.startAfter(lastDoc.__doc);
//     }
//     reference
//       .limit(30)
//       .get()
//       .then((querylogs) => {
//         const items = [];
//         querylogs.forEach((doc) => {
//           const item = doc.data();
//           item.id = doc.id;
//           item.__doc = doc;
//           items.push(timestampDate.parseTimestampToDate(item));
//         });

//         resolve(items);
//       });
//   });
// };
// export const  = (lastDoc) => {

//   return new Promise((resolve) => {
//     let reference = database.collection("Transaction").orderBy("created", "desc");
//     if (lastDoc) {
//       reference = reference.startAfter(lastDoc.__doc);
//     }
//     reference
//       .limit(30)
//       .get()
//       .then((querylogs) => {
//         const items = [];
//         querylogs.forEach((doc) => {
//           const item = doc.data();
//           item.id = doc.id;
//           item.__doc = doc;
//           items.push(timestampDate.parseTimestampToDate(item));
//         });

//         resolve(items);
//       });
//   });
// };

export const getApiTransactionman = (lastDoc) => {
  return new Promise((resolve) => {
    let reference = database.collection("APITransaction").orderBy("created", "desc");
    if (lastDoc) {
      reference = reference.startAfter(lastDoc.__doc);
    }
    reference
      .limit(30)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });

        resolve(items);
      });
  });
};



export const getAllAgent = (lastDoc) => {

  return new Promise((resolve) => {
    let reference = database.collection("users").orderBy("created", "desc");
    if (lastDoc) {
      reference = reference.startAfter(lastDoc.__doc);
    }
    reference
      .limit(30)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });

        resolve(items);
      });
  });
};

export const getBVN = (bvn) => {
  return new Promise((resolve) => {
    let reference = database.collection("users");
    if (bvn) {
      reference = reference.where("bvn", "==", bvn);
    }
    reference
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          items.push(timestampDate.parseTimestampToDate(item));
        });

        resolve(items);
      });
  });
};

export const getaccountholders = async(cd) => {
 await axios.get(`https://us-central1-mazuflex-app.cloudfunctions.net/CreateAccountSquad/create/account`).then((res)=>{
  const p = res.data
  cd(p);
 })
   
};

export const getCurrentPayload = (payload, selectedprovoder) => {

console.log(selectedprovoder)
  if (
    selectedprovoder === "abuja_electric_prepaid"
  ) {
    const c = payload.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.units,
      accountBalance: e.accountBalance,
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  if (selectedprovoder === "portharcourt_electric_prepaid"  ) {
    const c = payload.data;
    const dataarray = {
      token: c.tokenCode,
      units: "",
      accountBalance: "0",
      debtAmount: "0",
    };
    return [true, dataarray];
  }

  if (selectedprovoder === "benin_electric_prepaid"
  ) {
    const c = payload.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.amountOfPower,
      accountBalance: e.accountBalance,
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  if (
    selectedprovoder === "eko_electric_prepaid"
  ) {
    const c = payload.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.creditToken,
      units: e.amountOfPower,
      accountBalance: "0",
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  if (
    selectedprovoder=== "ikeja_electric_prepaid"
  ) {
    const c = payload.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.creditToken,
      units: e.amountOfPower,
      accountBalance: "0",
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  if (
    selectedprovoder === "jos_electric_prepaid"
   
  ) {
    const c = payload.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.units,
      accountBalance:e.outstandingPaid,
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  
  if (
    selectedprovoder === "enugu_electric_prepaid"
    
  ) {
    const c = payload.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.units,
      accountBalance:e.arrearsBalance,
      debtAmount: e.appliedToArrears,
    };
    return [true, dataarray];
  }

  if (
    selectedprovoder === "kaduna_electric_prepaid") {
    const c = payload.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.units,
      accountBalance:null,
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  if (
    selectedprovoder === "ibadan_electric_prepaid"
  ) {
    const c = payload.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.units,
      accountBalance:null,
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  if (
    selectedprovoder === "kedco_electric_prepaid"
  ) {
    const c = payload.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.units,
      accountBalance:null,
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  return[false, "Error"]

};