import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import "./Services.css";
import Gotv from "../../Gotv.json";
import Dstv from "../../dstv.json";
import Startimes from "../../Startimes.json";
import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../Service/UserAuth";
import { database } from "../../firebase";
import { formatNumber } from "../../Service/Utility";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import { format } from "date-fns";
const walleturl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/getuserwallet";

const finderurl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/Getcablenamefinder";
const curl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/CustomerInformation";
const url =
  "https://us-central1-mazuflex-app.cloudfunctions.net/GetcableTVProvider";

function Cabletvsubescription() {
  const [selectedcable, setcableselected] = useState("gotv");
  const [image, setimage] = useState("/img/gotv.png");
  const [startimes] = useState(Startimes);
  const [gotv] = useState(Gotv);
  const [dstv] = useState(Dstv);
  const [b, setb] = useState("");
  const [datatable, setdatacable] = useState([]);
  const [phoneea, setphone] = useState("");
  const { loginuser, userwallet, commission } = useAuth();
  const [cableboundle, setboundle] = useState([]);
  const [bundleprice, setbundleprice] = useState(0);
  const [loading, setloading] = useState(false);
  const [iuc, setiuc] = useState("");
  const [responspayload, setresponsepayload] = useState("");
  const [payload, setpayload] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [v, setv] = useState(false);
  const history = useHistory();
  const [cableresponse, setcableresponse] = useState([]);
  const [cablerror, setcableerroresponse] = useState("");
  const [status, setstatus] = useState("");
  const [code, setboundconde] = useState("");
  const [showprint, setShowprint] = useState(false);
  const handleClosesetShowprint = () => setShowprint(false);
  const handleShowsetShowprint = () => setShowprint(true);
  const [fullscreen, setFullscreen] = useState(true);
  const [showpayload, setShowpayload] = useState(false);
  const [disable, setdisable]= useState(false)
  const [datapayload, setdatapayload]= useState([])
  const [monthprice, setmonperiod]= useState()
  const [comm, setcom] = useState(0);
  const handleClosepayload = () => {
    setShowpayload(false);
    window.location.href = "/cable";
  };
  const handleShowpayload = () => setShowpayload(true);
  const date = format(new Date(), "dd/MM/yyyy hh:mm a");
  const g = gotv.data;
  const d = dstv.data;
  const s = startimes.data;

  useEffect(() => {
    const result = cableboundle.find((name) => name.name === b);
   
    if (result) {
      const boundle = result.availablePricingOptions[0].price;
      setbundleprice(boundle);
      const boundlecode = result.code;
      setboundconde(boundlecode);
      const mon =  result.availablePricingOptions[0].monthsPaidFor
      setmonperiod(mon)
    }
    setdatacable(result);

    if (selectedcable === "gotv") {
      const comission = commission.gotv;
      setcom(comission);
      setboundle(g);
    } else if (selectedcable === "dstv") {
      const comission = commission.dstv;
      setcom(comission);
      setboundle(d);
    } else if (selectedcable === "startimes") {
      const comission = commission.startimes;
      setcom(comission);
      setboundle(s);
    } else {
      setboundle("");
    }

    if (selectedcable === "gotv") {
      setimage("/img/gotv.png");
    } else if (selectedcable === "startimes") {
      setimage("/img/star.jpg");
    } else if (selectedcable === "dstv") {
      setimage("/img/dstv.png");
    } else {
      setimage("/img/gotv.png");
    }
  }, [image, selectedcable, b, loginuser, bundleprice]);

  const handlechangeamount = () => {
    toast.warning("Action Rejected", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return;
  };

  const handleparkage = (e) => {
    setb(e.target.value);
  };

  const handleverifier = async (e) => {
    e.preventDefault();

    if (b === "") {
      toast.error("Bad format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setloading(true);
    try {
      setresponsepayload("fetching information");
      await axios
        .post(finderurl, {
          service_type: selectedcable,
          account_number: iuc,
        })
        .then((e) => {
          if (e.data.message === "Successful") {
            setpayload(e.data.data.user);
            setv(true);
            handleShow();
            setresponsepayload("");
          } else {
            toast.error("😔 Invalid Consumer Information", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return;
          }
        })
        .catch((e) => {
         
        });
    } catch (error) {
      console.log(error);
    }
    setloading(false);
  };
  

  const com = 100;
  const payable =
    loginuser.permissions === "user" ? bundleprice + com : bundleprice;
  const phone = phoneea ? phoneea : loginuser.number;
  const ref = database.collection("_").doc().id;
  const handlePring = (e)=>{
    e.preventDefault()
    history.push(`/transaction/${datapayload.id}`)
  }
  const handlepayment = async (e) => {
    e.preventDefault();

    if (userwallet.wallet < payable) {
      toast.error("Insufficent wallet balance, Please Top", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setloading(false);
      return;
    }

    setloading(true);
    try {
      await axios
        .post(walleturl, { id: userwallet.id, amount: payable })
        .then(async (e) => {
          setresponsepayload("validating");
          if (e.data.message !=="success"){
            toast.error("Insufficent wallet balance, Please Top", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return
          }
          const data1 = {
            id: ref,
            company: selectedcable,
            customername: payload.name,
            address: "no value",
            userID: loginuser.id,
            AccountNumber: iuc,
            cablerror,
            walletbalance: userwallet.wallet,
            amount: payable,
            phone: phone,
            type: "Cable",
            status: "Network Error",
            created: firebase.firestore.FieldValue.serverTimestamp(),
          };
          const vamount = parseFloat(bundleprice)
          if (e.data.message === "success") {
            setresponsepayload("Connecting service ..");
            await axios
              .post(
                "https://us-central1-mazuflex-app.cloudfunctions.net/buycable",
                {
                  smartcard_number: iuc,
                  total_amount: bundleprice,
                  product_code: code,
                  product_monthsPaidFor: monthprice,
                  service_type: selectedcable,
                  agentReference: ref,
                  agentId: "205",
                  isApproved: e.data.isApproved,
                  verifyamount:vamount
                }
              )
              .then(async (e) => {
               
                setdisable(true)
                setresponsepayload("finalizing connection. Please wait ");
                if (e.data.status === "success") {
                  handleClose();
                  setcableresponse(e.data.data);
                  setstatus("success");
                  handleShowpayload();
                  const topUrl =
                    "https://us-central1-mazuflex-app.cloudfunctions.net/commissioncredit";
                  const com =
                    comm !== 0 || comm !== undefined || comm !== null
                      ? comm * payable
                      : 0;
                     
                  const com2 =
                    comm !== 0 || comm !== undefined || comm !== null
                      ? comm * payable
                      : 0;
                     
                  const data = {
                    id: ref,
                    company: selectedcable,
                    customername: payload.name,
                    address: "no value",
                    userID: loginuser.id,
                    AccountNumber: iuc,
                    walletbalance: userwallet.wallet,
                    amount: payable,
                    phone: phone,
                    payloadres: e.data.data,
                    isToday: true,
                    type: "Cable",
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    status: "success",
                  };
                 
                  setpayload(data)
                  const promises = [
                    database
                    .collection("Transaction")
                    .doc(data.id)
                    .set(data),
                    axios.post(topUrl, {
                      userID: loginuser.id,
                      amount: loginuser.permissions === "user" ? 0 : parseFloat(com),
                      price:  payable,
                      admincom: com2,
                    }),
                    database.doc(`/income/66DyYlsUXUHQdRUIkDrs`).update({
                      cableincome: firebase.firestore.FieldValue.increment(com2),
                      totalincome: firebase.firestore.FieldValue.increment(com2),
                      })
                  ]
                  await Promise.all(promises)
                } else {
                  handleClose();
                  setcableerroresponse(e.data.data);
                  setstatus("error");
                  handleShowpayload();
                  setpayload(data1)
                  await database
                    .collection("Transaction")
                    .doc(data1.id)
                    .set(data1);
                }
                setdisable(false)
                setresponsepayload("");
              })
              .catch((e) => {
                toast.error(e.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          } else {
            toast.error("Insufficent wallet balance, Please Top", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setresponsepayload("");
          }
        })
        .catch((e) => {
       
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (e) {
     
      toast.error(e.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setloading(false);
  };

  const handleshowprint = () => {
    handleShowsetShowprint();
  };

  return (
    <div>
      <ToastContainer />

      <Modal
        show={showprint}
        onHide={handleClosesetShowprint}
        backdrop="static"
        fullscreen={fullscreen}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transaction Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="infocontainer">
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">
                {selectedcable ? selectedcable : ""}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">IUC :</div>
              <div className="infodetails">{iuc}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">{payload.name}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Amount :</div>
              <div className="infodetails">
                &#8358; {formatNumber(payable)}
                Charge Inclusive
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">{date}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Message :</div>
              <div className="infodetails">Transaction Successful</div>
            </div>
            <div className="d-flex">
              <Button
                variant="danger"
                className="w-100 m-1 "
                type="submit"
                onClick={handleClosepayload}
              >
                back
              </Button>

              <Button
                className="w-100 m-1"
                type="submit"
                onClick={handlePring}
              >
                Print
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        backdrop="static"
        show={showpayload}
        onHide={handleClosepayload}
        animation={false}
      >
        <Modal.Body>
          {status === "error" ? (
            <>
              <div className="headersjdjd bg-danger">
                <h1>ERROR</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectedcable ? selectedcable : ""}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">IUC :</div>
                  <div className="infodetails">{iuc}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Customer Name :</div>
                  <div className="infodetails">{payload.name}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {payable} <br />
                    Charge Inclusive
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Message :</div>
                  <div className="infodetails">
                    Transaction Error, Please contact support
                  </div>
                </div>
                <center className="text-danger">{responspayload}</center> 
              <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    disabled={disable}
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button className="w-100 m-1" type="submit" disabled={disable} onClick={handlePring} >
                    Print
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="headersjdjd bg-success">
                <h1>SUCCESS</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectedcable ? selectedcable : ""}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">IUC :</div>
                  <div className="infodetails">{iuc}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Customer Name :</div>
                  <div className="infodetails">{payload.name}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(payable)}
                    Charge Inclusive
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Message :</div>
                  <div className="infodetails">Transaction Successful</div>
                </div>
                <center className="text-danger">{responspayload}</center> 
              <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    disabled={disable}
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button className="w-100 m-1" type="submit" disabled={disable} onClick={handlePring} >
                    Print
                  </Button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        centered
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div className="headersjdjd">
          <h1>In-Progress</h1>
          </div>
          <p>Kindly confirm the information below before you proceed</p>
          <div className="infocontainer">
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">
                {selectedcable ? selectedcable.toUpperCase() : ""}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">IUC :</div>
              <div className="infodetails">{iuc}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">{payload.name}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Package :</div>
              <div className="infodetails">&#8358; {b}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Amount :</div>
              <div className="infodetails">&#8358; {bundleprice}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">charge :</div>
              <div className="infodetails">
                {loginuser.permissions === "user" ? 100 : 0}
              </div>
            </div>
          </div>
        </Modal.Body>

        <center className="info">{responspayload}</center>
        <div className="d-flex">
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlepayment}
            className="m-1 w-50"
            type="submit"
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Pay with Wallet"
            )}
          </Button>

          <Button
            variant="danger"
            onClick={handleClose}
            className="m-1 w-50"
            type="submit"
          >
            Back
          </Button>
        </div>
      </Modal>
      <div className="allcablesyns">
        <div className="allcableitems">
          <div className="cableallre">
            <div className="imagesetuib">
              <img src={image} alt="" />
            </div>
            <div className="productsed">
              <h2>
                <center className="text-dark">CABLE SUBSCRIPTION</center>
              </h2>
              <Form.Label className="text-dark my-3">Cable TV</Form.Label>
              <FloatingLabel
                controlId="floatingSelect"
                onChange={(event) => {
                  setcableselected(event.target.value);
                }}
              >
                <Form.Select aria-label="Floating label select example">
                  <option value="gotv">Gotv</option>
                  <option value="dstv">DSTV</option>
                  <option value="startimes">STARTIMES</option>
                </Form.Select>
              </FloatingLabel>
              <div className="optionshhd">
                <Form.Label className="text-dark my-3">
                  Select your preferred Package
                </Form.Label>
                <Form.Select
                  value={b}
                  required
                  aria-label="Floating label select example"
                  onChange={handleparkage}
                >
                  <option key="">Please Select Parkage</option>
                  {cableboundle.map((b) => (
                    <option key={b.code}>{b.name}</option>
                  ))}
                </Form.Select>
                <Form.Text className="text-muted">
                  Amount = &#8358; {formatNumber(payable)}
                </Form.Text>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-dark my-3">Enter IUC</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(event) => setiuc(event.target.value)}
                    placeholder="Enter IUC"
                  />
                  <Form.Text className="text-muted">
                    Verification Required.
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-dark my-2">
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    placeholder={loginuser.number}
                    type="email"
                    onChange={(event) => {
                      setphone(event.target.value);
                    }}
                  />
                </Form.Group>
                <Button
                  variant={loading ? "warning" : "primary"}
                  disabled={loading}
                  onClick={handleverifier}
                  className="my-2 w-100"
                  type="submit"
                >
                  {loading ? (
                    <>
                      {" "}
                      <Spinner animation="border" size="sm" /> {responspayload}
                    </>
                  ) : (
                    "Verify IUC"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cabletvsubescription;
