import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import "../Servicescomponent/Services.css";
import { PaystackButton } from "react-paystack";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { database, functions} from "../../firebase";
import { formatNumber } from "../../Service/Utility";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import Inloader from "../../Inloader"
import { format } from "date-fns";
function Cabletvsubescription() {
  const [selectednetwork, setselectednetwork] = useState("Wace");
  const [image, setimage] = useState("/img/waec.png");
  const [b, setb] = useState("");
  const [amountaaa, setamount] = useState(0);
  const [awaiting, setwaiting] = useState(false)
  const [phoneea, setphone] = useState("");
  const [bundleprice, setbundleprice] = useState(0);
  const [loading, setloading] = useState(false);
  const [responspayload, setresponsepayload] = useState("");
  const [payload, setpayload] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [number, setnumber] = useState(0);
  const history = useHistory();
  const [cableresponse, setcableresponse] = useState([]);
  const [cablerror, setcableerroresponse] = useState("");
  const [status, setstatus] = useState("");
  const [showprint, setShowprint] = useState(false);
  const handleClosesetShowprint = () => setShowprint(false);
  const handleShowsetShowprint = () => setShowprint(true);
  const [fullscreen, setFullscreen] = useState(true);
  const [disable, setdisable]= useState(false)
  const [showpayload, setShowpayload] = useState(false);
  const handleClosepayload = () => {
    setShowpayload(false);
    window.location.href = "/more/service/waec";
  };
  const handleShowpayload = () => setShowpayload(true);

  const date = format(new Date(), "dd/MM/yyyy hh:mm a");
  const amount = amountaaa;
  useEffect(() => {
    history.push('/login')
  }, [image, selectednetwork]);

  const handleamout = (e) => {
    const price = 3000;
    const am = e.target.value;
    setnumber(am);
    const amount = am * price;
    setamount(amount);
  };

  const pins = cableresponse.pins ? cableresponse.pins : [];

  const ref = database.collection("_").doc().id;
  const phone = phoneea;
  const handleverifier = async (e) => {
    e.preventDefault();
    if (isNaN(phone)) {
      toast.error("Number Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (phoneea === "") {
      toast.error("Number Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const data = {
      network: selectednetwork,
      number: phone,
      amount: amount,
      referrence: ref,
    };

    setpayload(data);
    handleShow();
    setloading(false);
  };


  const config = {
    reference: new Date().getTime().toString(),
    email: `mezuflex@gmail.com`,
    amount: amount * 100,
    publicKey: "pk_live_7c49d4df21fc6207a1ece0514092b34ad3f5bbee",
  };
  
  const handlePaystackSuccessAction = async (data) => {
  
    const verifyPaymentHandler = functions.httpsCallable(
      "verifyPaystackPayment"
    );
  
   
    const value = {
      ref: data.reference,
      status: data.success,
      firstname: "Customer",
      lastname: "Mezuflex",
      email: "mezuflex@gmail.com",
      userId: "iu4WWdVD3GeRe9373RpDm25zN4M2",
      phone: "08165459398",
      amount: amount,
    };
  
      setwaiting(true)
    await verifyPaymentHandler(value).then(async (result) => {
   
      if (result === null || result === undefined) {
        return (
          <>
            <Inloader />
          </>
        );
      }
      if (result.data.data.status === "success") {
        const ref = database.collection("_").doc().id;
        setresponsepayload("Connecting service ..");
        const data1 = {
            id: ref,
            company: selectednetwork,
            customername: "no value",
            address: "no value",
            userID: "iu4WWdVD3GeRe9373RpDm25zN4M2",
            AccountNumber: phone,
            cablerror,
            walletbalance:0,
            amount: amount,
            phone: phone,
            type: "exam e-PIN",
            status: "Network Error",
            created: firebase.firestore.FieldValue.serverTimestamp(),
          };
            await axios
              .post(
                "https://us-central1-mazuflex-app.cloudfunctions.net/buyWaec",
                { amount: amount, agentReference: ref, numberOfPins: number }
              )
              .then(async (e) => {
                setdisable(true)
                setresponsepayload("finalizing connection. Please wait ")
                // console.log(e.data);
                if (e.data.status === "success") {
                  handleClose();
                  setcableresponse(e.data.data);
                  setstatus("success");
                  handleShowpayload();
                  const data = {
                    id: ref,
                    company: selectednetwork,
                    customername: phone,
                    address: "no value",
                    userID: "iu4WWdVD3GeRe9373RpDm25zN4M2",
                    AccountNumber: phone,
                    pins:pins,
                    walletbalance: 0,
                    amount: amount,
                    phone: phone,
                    isToday:true,
                    payloadres: e.data.data,
                    type: "exam e-PIN",
                    status: "success",
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                  };
                 
                  await database
                    .collection("Transaction")
                    .doc(data.id)
                    .set(data);
                } else {
                  handleClose();
                  setcableerroresponse(e.data.data);
                  setstatus("error");
                  handleShowpayload();
                  await database
                    .collection("Transaction")
                    .doc(data1.id)
                    .set(data1);
                }
                setdisable(false)
              })
              .catch((e) => {
               
                toast.error(e.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
        // Setpaymentpayload(result.data.data);
        // setshowvalue(true);
        handleClose();
      } else {
        // Setpayload(result.data.data);
        handleClose();
        handleShowpayload();
      }
    }).catch((e)=>{
   
      toast.error(e.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    });
  
  };
  
  const handlePaystackCloseAction = () => {
    window.location.href="/";
  };
  
  const componentProps = {
    ...config,
   text: loading ? "Proccessing request" : "Pay with Paystack",
    onSuccess: (data) => handlePaystackSuccessAction(data),
    onClose: () => handlePaystackCloseAction(),
  };


  const handleshowprint = ()=>{
    handleShowsetShowprint()
  }
  return (
    <div>
      <ToastContainer />
      <Modal
        show={showprint} 
        onHide={handleClosesetShowprint }
        backdrop="static"
        fullscreen={fullscreen}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transaction Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="infocontainer">
        <center> <img src="/img/logo3.png" alt="" /></center>
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectednetwork ? selectednetwork.toUpperCase() : ""}
                  </div>
                </div>


                <div className="carconatienr">
                  <div className="cordrawwaper">
                    {pins.map((item, id) => {
                      return(
                        <div key={id}>
                        <div className="cardcontenr">
                          <div className="cardinma">
                            <img src="/img/waec.png" alt="" />
                          </div>
                          <div className="caedtejnss">
                            <div className="inforcontenr">
                              <div className="infotittle"> Pin:</div>
                              <div className="infodetails">{item.pin}</div>
                            </div>

                            <div className="inforcontenr">
                              <div className="infotittle"> Serial Number:</div>
                              <div className="infodetails">{item.serialNumber}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      )
                    })}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(payload.amount)}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Number of Pin :</div>
                  <div className="infodetails">{number}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>
                <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>
                  <Button className="w-100 m-1" type="submit"  onClick={() => window.print()}>
                    Print
                  </Button>
                </div>
              </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        backdrop="static"
        show={showpayload}
        size="lg"
        onHide={handleClosepayload}
        animation={false}
      >
        <Modal.Body>
          {status === "error" ? (
            <>
              <div className="headersjdjd bg-danger">
                <h1>ERROR</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectednetwork ? selectednetwork.toUpperCase() : ""}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Number :</div>
                  <div className="infodetails">{payload.number}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(payload.amount)}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Message :</div>
                  <div className="infodetails">
                    Transaction Failed, Please try again later
                  </div>
                </div>
                <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    disabled={disable}
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button className="w-100 m-1" disabled={disable} type="submit">
                    Print
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="headersjdjd  bg-success">
                <h1>SUCCESS</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectednetwork ? selectednetwork.toUpperCase() : ""}
                  </div>
                </div>
                <div className="carconatienr">
                  <div className="cordrawwaper">
                    {pins.map((item, id) => {
                      return(
                        <div key={id}>
                        <div className="cardcontenr">
                          <div className="cardinma">
                            <img src="/img/waec.png" alt="" />
                          </div>
                          <div className="caedtejnss">
                            <div className="inforcontenr">
                              <div className="infotittle"> Pin:</div>
                              <div className="infodetails">{item.pin}</div>
                            </div>

                            <div className="inforcontenr">
                              <div className="infotittle"> Serial Number:</div>
                              <div className="infodetails">{item.serialNumber}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      )
                    })}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(payload.amount)}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Number of Pin :</div>
                  <div className="infodetails">{number}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>
                <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button className="w-100 m-1" type="submit" onClick={handleshowprint}>
                    Print
                  </Button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        centered
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div className="headersjdjd">
          <h1>In-Progress</h1>
          </div>
          <p>Kindly confirm the information below before you proceed</p>
          <div className="infocontainer">
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">
                {selectednetwork ? selectednetwork.toUpperCase() : ""}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Number :</div>
              <div className="infodetails">{payload.number}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Price :</div>
              <div className="infodetails">
                &#8358; {formatNumber(payload.amount)}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Number of Pin :</div>
              <div className="infodetails">{number}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">{date}</div>
            </div>
          </div>
        </Modal.Body>

        <center className="info">{responspayload}</center>
        <div className="d-flex ddd">
        <PaystackButton {...componentProps}  />
          <button
            variant="danger"
            onClick={handleClose}
            className=" ms-1 w-50 cnsnddd"
            type="submit"
          >
            Back
          </button>
        </div>
      </Modal>
      <div className="allcablesyns">
        <div className="allcableitems">
          <div className="cableallre">
            <div className="imagesetuib">
              <img src={image} alt="" />
            </div>
            <div className="productsed">
              <h2>
                <center className="text-dark">WACE RESULT CHECKER PIN</center>
              </h2>
              <Form.Label className="text-dark my-3">Buy E-pin</Form.Label>
              <FloatingLabel
                controlId="floatingSelect"
                onChange={(event) => {
                  setselectednetwork(event.target.value);
                }}
              >
                <Form.Select aria-label="Floating label select example">
                  <option value="wace">WACE</option>
                </Form.Select>
              </FloatingLabel>
              <div className="optionshhd">
                <Form.Group>
                  <Form.Label className="text-dark my-2">
                    Number of Pin
                  </Form.Label>
                  <Form.Control
                    placeholder="e.g 1"
                    type="tel"
                    onChange={handleamout}
                  />
                </Form.Group>
                <Form.Label className="text-dark my-2">Amount</Form.Label>

                <Form.Control placeholder={amount} disabled />

                <Form.Group>
                  <Form.Label className="text-dark my-2">
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    placeholder=""
                    type="tel"
                    onChange={(event) => {
                      setphone(event.target.value);
                    }}
                  />
                </Form.Group>
                <Button
                  variant={loading ? "warning" : "primary"}
                  disabled={loading}
                  onClick={handleverifier}
                  className="my-2 w-100"
                  type="submit"
                >
                  {loading ? (
                    <>
                      {" "}
                      <Spinner animation="border" size="sm" /> {responspayload}
                    </>
                  ) : (
                    "Buy E-pin"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cabletvsubescription;
