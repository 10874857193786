import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import {FiLogOut} from "react-icons/fi"
import {AiOutlineApi} from "react-icons/ai"
export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/admin/auth/:uid',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'Users',
    path: '/admin/users',
    icon: <IoIcons.IoIosPaper />,
    cName: 'nav-text'
  },
  {
    title: 'Transaction',
    path: '/admin/Transaction',
    icon: <FaIcons.FaCartPlus />,
    cName: 'nav-text'
  },


  {
    title: 'API Management',
    path: '/api/management',
    icon: <AiOutlineApi/>,
    cName: 'nav-text'
  },

  {
    title: 'Management',
    path: '/admin/management',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
  {
    title: 'Manage Agent',
    path: '/register/agent',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
  {
    title: 'Sqaurd ',
    path: '/squard/management',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
  {
    title: 'Support Center',
    path: '/messages',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text'
  },
  {
    title: 'Data Price',
    path: '/support',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'nav-text'
  },
  {
    title: 'logout',
    path: '/admin/logout',
    icon: <FiLogOut/>,
    cName: 'nav-text'
  }
];
