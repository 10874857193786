import React from "react";
import "./CreateUser.css";

function AboutUS() {
  return (
    <div>
      <div className="boutuscone">
        <div className="aboutisekdk">
          <div className="aboutusheader">ABOUT US</div>
          <div className="AmoutUScbodhd">
            Mezuflex is a subsidiary of Coletking Concept. Here at Coletking
            concept, we are a team of young skilled professionals who have come
            together to bring you the best affordable services to make your life
            easier. <br />
            You can visit us at: off Panaf Drive, Dawaki Express Road, Abuja
            FCT. Tel: +(234) 708 627 6054 Email: mezuflex@gmail.com
            <br />
            Talk to us on WhatApp{" "}
            <a
              href="https://wa.me/2348076519831"
              className="social text-success my-4 fs-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click Me
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUS;
