import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAuth } from "../Service/UserAuth";
import Loading from "../Loading";

function Userverify(props) {
  const [loading, setloading] = useState(false);
  const [user, setuser] = useState(null);
  const [remessage, setremessage] = useState("");
  const { uid } = useAuth().currentUser;
  const [isActive, setActive] = useState(false);
  const history = useHistory();
  const CampURL =
    "https://us-central1-mazuflex-app.cloudfunctions.net/EmailHandlernew";
  const baseURL = `https://us-central1-mazuflex-app.cloudfunctions.net/getUser/users/${uid}`;
  // const getuser = async()=>{
  //   await axios.get(baseURL).then((e)=>{
  //     console.log(e)
  //   }).catch((e)=>{
  //     console.log(e)
  //   })
  // }

  // getuser()
  useEffect(() => {
    setremessage("");
    axios.get(baseURL).then(async (response) => {
      const userinfo = await response.data;
      if (userinfo) {
        setuser(userinfo);

        if (
          userinfo.isAllowed === true &&
          userinfo.permissions === "user" &&
          userinfo.isBlock === false
        ) {
          history.push("/dashboard");
          return;
        }

        if (
          userinfo.isAllowed === true &&
          userinfo.permissions === "Partner" &&
          userinfo.isBlock === false
        ) {
          history.push("/dashboard");
          return;
        }

        if (
          userinfo.isAllowed === true &&
          userinfo.permissions === "agent" &&
          userinfo.isBlock === false
        ) {
          history.push("/dashboard");
          return;
        }

        if (
          userinfo.isAllowed === true &&
          userinfo.permissions === "super agent" &&
          userinfo.isBlock === false
        ) {
          history.push("/dashboard");
          return;
        }

        if (
          userinfo.isAllowed === true &&
          userinfo.permissions === "admin" &&
          userinfo.isBlock === false
        ) {
          history.push(`/admin/auth/${uid}`);
          return;
        }
      }
    });
  }, []);

  const resetLink = async (e) => {
    e.preventDefault();
  
    if (user.isAllowed === true) {
      toast.warning("User already verified please procced to login.. Thanks");
      return;
    }

    if (user.isSendEmail === true) {
      toast.warning(
        "Email already sent, please check inbox for the verification link or check  span folder if email not found in inbox"
      );
      setremessage(
        "Email already sent, please check inbox for the verification link or check span if email not found in inbox"
      );
      
      return;
    }
    setloading(true);
    console.log("qwertyuio")
    try {
      const url = `https://mazuflex-app.web.app/users/${user.id}`;
      const logo = "https://i.ibb.co/NNsgkQy/logo3.png";
      const tittle = "Account Verification";
      const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
      <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
          style="font-family: 'Open Sans', sans-serif;">
          <tr>
              <td>
                  <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                      align="center" cellpadding="0" cellspacing="0">
                      <tr>
                          <td style="height:80px;">&nbsp;</td>
                      </tr>
                      <tr>
                          <td style="text-align:center;">
                            <a href="https://mezuflex.com" title="logo" target="_blank">
                              <img width="130" src=${logo} title="logo" alt="logo">
                            </a>
                          </td>
                      </tr>
                      <tr>
                          <td style="height:20px;">&nbsp;</td>
                      </tr>
                      <tr>
                          <td>
                              <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                  style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                  <tr>
                                      <td style="height:40px;">&nbsp;</td>
                                  </tr>
                                  <tr>
                                      <td style="padding:0 35px;">
                                          <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Email Verification</h1>
                                          <span
                                              style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                          <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                                          <p>Hello ${user.firstname} ${user.lastname}!</p> 
                                          Kindly verify your email address before you can access your account. click on the button below to
                                          Verify your email address.
                                          </p>
                                          <p>Welcome to mezuflex family</p>
                                          <a href=${url}
                                              style="background:#20e277;text-decoration:none !important; font-weight:500; margin-top:35px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Verify Email</a>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td style="height:40px;">&nbsp;</td>
                                  </tr>
                              </table>
                          </td>
                      <tr>
                          <td style="height:20px;">&nbsp;</td>
                      </tr>
                      <tr>
                          <td style="text-align:center;">
                              <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>https://mezuflex.com</strong></p>
                          </td>
                      </tr>
                      <tr>
                          <td style="height:80px;">&nbsp;</td>
                      </tr>
                  </table>
              </td>
          </tr>
      </table>
     

</div>`;
     
      await axios
        .post(CampURL, {
          content,
          logo,
          tittle,
          email: user.email,
          firstname: user.firstname,
          url,
          subject:"Account Verification"
        })
        .then((res) => {
          console.log(res)
          setremessage("Activation sent successfully");
          toast.success("Email sent ");
        })
        .catch((e) => {});
    } catch (error) {
      toast.error(error.mesage);
    }
    setloading(false)
  };
  if (!user) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <div>
      <ToastContainer />
      <div className="verifycontainer">
        <div className="verifycontent">
          <div className="verfyvalue">
            <div>
              <div className="welcome1">
                <div className="welcomeman">
                  <div className="indv">
                    <img src="/img/v.png" alt="" />
                  </div>
                  Welcome <strong></strong> {user.lastname} {user.firstname}
                  <div className="welcomegrand">
                    <div className="welcomeparent">
                      <div className="welcomechil">
                        Your Account need To be Verified; <br />
                        Please click on the link sent to your Email to verify
                        your account
                      </div>{" "}
                      <br />
                    </div>
                  </div>
                  <div className="welcomegrand">
                    <div className="welcomeparent">
                      <div className="welcomechil">
                        DO YOU KNOW!!
                        <br />
                        When you become a Mezuflex agent, you can earn
                        commission of every successfull transaction you make on mezuflex, so what are you wait for?
                      </div>{" "}
                      <br />
                      <p>For more information please contact </p>{" "}
                      <span>
                        <Link to="/contact">Support</Link>
                      </span>{" "}
                      <br />
                    </div>
                  </div>
                  <div className="restbuton">
                    <button onClick={resetLink} disabled={loading}>
                      {" "}
                      {loading ? " Please Wait" : "Re-Send verification Link"}{" "}
                    </button>{" "}
                    <br />
                  <span className="text-danger">{remessage}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Userverify;
