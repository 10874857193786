import React from 'react'
import Navbar from '../Admincomponent/NavbarAdmin'
import PaymentNotification from '../Admincomponent/PaymentNotification'


function AdminDashboard() {
  return (
    <div>
      <Navbar/>
      <PaymentNotification/>
    </div>
  )
}

export default AdminDashboard