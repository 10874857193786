import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import "./Services.css";
import BettingProviders from "../../BettingProviders.json";

import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../Service/UserAuth";
import { database } from "../../firebase";
import { formatNumber } from "../../Service/Utility";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import { format } from "date-fns";
const walleturl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/getuserwallet";

const finderurl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/getUserBettingID";
const curl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/CustomerInformation";
const url =
  "https://us-central1-mazuflex-app.cloudfunctions.net/GetcableTVProvider";

function Cabletvsubescription() {
  const [selectedcable, setcableselected] = useState("gotv");
  const [image, setimage] = useState(
    "https://olbgimages.co.uk/image-cache/?src=https://olbgimages.co.uk/wp-content/gallery/betting/betting_3.jpg&w=1116&h=682&zc=1"
  );
  const [BettingProvider] = useState(BettingProviders);
  const [selectedbet, setselectedbet] = useState("");
  const [userbetID, SetuserID] = useState("");
  const [datatable, setdatacable] = useState([]);
  const [phoneea, setphone] = useState("");
  const [amountvalue, setamount] = useState(0);
  const { loginuser, userwallet} = useAuth();
  const [loading, setloading] = useState(false);
  const [responspayload, setresponsepayload] = useState("");
  const [payload, setpayload] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [v, setv] = useState(false);
  const history = useHistory();
  const [cableresponse, setcableresponse] = useState([]);
  const [cablerror, setcableerroresponse] = useState("");
  const [status, setstatus] = useState("");
  const [code, setboundconde] = useState("");
  const [showprint, setShowprint] = useState(false);
  const handleClosesetShowprint = () => setShowprint(false);
  const handleShowsetShowprint = () => setShowprint(true);
  const [fullscreen, setFullscreen] = useState(true);
  const [showpayload, setShowpayload] = useState(false);
  const [disable, setdisable] = useState(false);

  const handleClosepayload = () => {
    setShowpayload(false);
    window.location.href = "/cable";
  };
  const handleShowpayload = () => setShowpayload(true);
  const date = format(new Date(), "dd/MM/yyyy hh:mm a");
  const getProvider = BettingProvider.data;
  useEffect(() => {
    setdatacable(getProvider);
  }, [datatable]);

  const amount =  parseFloat(amountvalue)

  const handlegetimage = (e) => {
    const val = e.target.value;
    if (val === "") {
      setimage(
        "https://olbgimages.co.uk/image-cache/?src=https://olbgimages.co.uk/wp-content/gallery/betting/betting_3.jpg&w=1116&h=682&zc=1"
      );
      setdatacable([]);
      return;
    }
    setselectedbet(val);
    const bettingImage = getProvider.filter((e) => e.serviceName === val)[0];
    if (bettingImage) {
      const getimagevalue = bettingImage.serviceLogo;
      setimage(getimagevalue);
    }
  };

  const b = selectedbet.toLowerCase();

  const handleverifier = async (e) => {
    e.preventDefault();

    if (selectedbet === "" || amount === 0 || phoneea === "") {
      toast.error("Bad format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (isNaN(amount)){
      toast.error("Bad format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (amount <100){
      toast.error("Minimum required amount", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; 
    }
    setloading(true);
    try {
      setresponsepayload("fetching information");
      await axios
        .post(finderurl, {
          service_type: b,
          account_number: userbetID,
        })
        .then((e) => {
         
          if (e.data.message === "Successful") {
            setpayload(e.data.data.user);
            setv(true);
            handleShow();
            setresponsepayload("");
          } else {
            toast.error("😔 Invalid Consumer Information", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
    setloading(false);
  };

  // const handlechangeamount = () => {
  //   toast.warning("Action Rejected", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  //   return;
  // };

  const com = 30;
  const payable = amount + com;
  const phone = phoneea ? phoneea : loginuser.number;
  const ref = database.collection("_").doc().id;

  const handlePring = (e) => {
    e.preventDefault();
    history.push(`/transaction/${payload.id}`);
  };

  const handlepayment = async (e) => {
    e.preventDefault();

    if (userwallet.wallet < amount) {
      toast.error("Insufficent wallet balance, Please Top", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setloading(false);
      return;
    }

    setloading(true);
    try {
      await axios
        .post(walleturl, { id: userwallet.id, amount: amount })
        .then(async (e) => {
          setresponsepayload("validating");
          if (e.data.message !== "success") {
            toast.error("Insufficent wallet balance, Please Top", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return;
          }
          const data1 = {
            id: ref,
            company: selectedbet,
            customername: payload.name,
            address: userbetID,
            userID: loginuser.id,
            AccountNumber: payload.accountNumber,
            cablerror,
            walletbalance: userwallet.wallet,
            amount: payable,
            phone: phone,
            type: "Betting",
            status: "Network Error",
            created: firebase.firestore.FieldValue.serverTimestamp(),
          };
          const vamount = parseFloat(e.data.amount)
          if (e.data.message === "success") {
            setresponsepayload("Connecting service ..");
            await axios
              .post(
                "https://us-central1-mazuflex-app.cloudfunctions.net/fundbetting",
                {
                  account_number:userbetID,
                  amount: amount,
                  service_type: b,
                  agentReference: ref,
                  isApproved: e.data.isApproved,
                  verifyamount:vamount
                }
              )
              .then(async (e) => {
              
                setdisable(true);
                setresponsepayload("finalizing connection. Please wait ");
                if (e.data.status === "success") {
                  handleClose();
                  setcableresponse(e.data.data);
                  setstatus("success");
                  handleShowpayload();
                  const topUrl =
                    "https://us-central1-mazuflex-app.cloudfunctions.net/commissioncredit";

                  const data = {
                    id: ref,
                    company: selectedbet,
                    customername: payload.name,
                    address: userbetID,
                    userID: loginuser.id,
                    AccountNumber: payload.accountNumber,
                    walletbalance: userwallet.wallet,
                    amount: payable,
                    phone: phone,
                    payloadres: e.data.data,
                    isToday: true,
                    type: "Betting",
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    status: "success",
                  };
                  setpayload(data);

                  const promises = [
                    database
                    .collection("Transaction")
                    .doc(data.id)
                    .set(data),
                    axios.post(topUrl, {
                      userID: loginuser.id,
                      amount: loginuser.permissions === "user" ? 0 : 15,
                      price: payable,
                      admincom: 15,
                      
                    }),
                    database.doc(`/income/66DyYlsUXUHQdRUIkDrs`).update({
                      bettingincome: firebase.firestore.FieldValue.increment(15),
                      totalincome: firebase.firestore.FieldValue.increment(15),
                      })
                  ]
                  await Promise.all(promises)
                } else {
                  handleClose();
                  setcableerroresponse(e.data.data);
                  setstatus("error");
                  handleShowpayload();
                  setpayload(data1);
                  await database
                    .collection("Transaction")
                    .doc(data1.id)
                    .set(data1);
                }
                setdisable(false);
                setresponsepayload("");
              })
              .catch((e) => {
                toast.error(e.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          } else {
            toast.error("Insufficent wallet balance, Please Top", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setresponsepayload("");
          }
        })
        .catch((e) => {
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (e) {
      toast.error(e.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setloading(false);
  };

 

  return (
    <div>
      <ToastContainer />

       <Modal
        show={showprint}
        onHide={handleClosesetShowprint}
        backdrop="static"
        fullscreen={fullscreen}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transaction Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
             <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectedbet ? selectedbet : ""}
                  </div>
                </div>

                <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">{payload.customername}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">User ID :</div>
              <div className="infodetails">{userbetID}</div>
            </div>

                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {payable} <br />
                    Charge Inclusive
                  </div>
                </div>

            <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">{date}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Message :</div>
              <div className="infodetails">Transaction Successful</div>
            </div>
            <div className="d-flex">
              <Button
                variant="danger"
                className="w-100 m-1 "
                type="submit"
                onClick={handleClosepayload}
              >
                back
              </Button>

              <Button
                className="w-100 m-1"
                type="submit"
                onClick={handlePring}
              >
                Print
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        backdrop="static"
        show={showpayload}
        onHide={handleClosepayload}
        animation={false}
      >
        <Modal.Body>
          {status === "error" ? (
            <>
              <div className="headersjdjd bg-danger">
                <h1>ERROR</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectedbet ? selectedbet : ""}
                  </div>
                </div>

                <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">{payload.customername}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">User ID :</div>
              <div className="infodetails">{userbetID}</div>
            </div>

                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {payable} <br />
                    Charge Inclusive
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Message :</div>
                  <div className="infodetails">
                    Transaction Error, Please contact support
                  </div>
                </div>
                <center className="text-danger">{responspayload}</center> 
              <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    disabled={disable}
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button className="w-100 m-1" type="submit" disabled={disable} onClick={handlePring} >
                    Print
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="headersjdjd bg-success">
                <h1>SUCCESS</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectedbet ? selectedbet : ""}
                  </div>
                </div>

                <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">{payload.customername}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">User ID :</div>
              <div className="infodetails">{userbetID}</div>
            </div>

                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {payable} <br />
                    Charge Inclusive
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Message :</div>
                  <div className="infodetails">Transaction Successful</div>
                </div>
                <center className="text-danger">{responspayload}</center> 
              <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    disabled={disable}
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button className="w-100 m-1" type="submit" disabled={disable} onClick={handlePring} >
                    Print
                  </Button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal> 

      <Modal
        centered
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div className="headersjdjd">
            <h1>In-Progress</h1>
          </div>
          <p>Kindly confirm the information below before you proceed</p>
          <div className="infocontainer">
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">
                {selectedbet ? selectedbet.toUpperCase() : ""}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">{payload.name}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">User ID :</div>
              <div className="infodetails">{payload.accountNumber}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Amount :</div>
              <div className="infodetails">&#8358; {amount}</div>
            </div>
          </div>
        </Modal.Body>

        <center className="info">{responspayload}</center>
        <div className="d-flex">
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlepayment}
            className="m-1 w-50"
            type="submit"
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Pay with Wallet"
            )}
          </Button>

          <Button
            variant="danger"
            onClick={handleClose}
            className="m-1 w-50"
            type="submit"
          >
            Back
          </Button>
        </div>
      </Modal>
      <div className="allcablesyns">
        <div className="allcableitems">
          <div className="cableallre">
            <div className="imagesetuib">
              <img src={image} alt="" />
            </div>
            <div className="productsed">
              <h2>
                <center className="text-dark">Fund Betting Wallet</center>
              </h2>
              <Form.Label className="text-dark my-3">Betting</Form.Label>
              <FloatingLabel
                controlId="floatingSelect"
                onChange={(event) => {
                  setcableselected(event.target.value);
                }}
              >
                <Form.Select
                  aria-label="Floating label select example"
                  onChange={handlegetimage}
                >
                  <option value="">Selecting Bet</option>
                  {datatable.map((e) => {
                    return (
                      <option value={e.serviceType}>{e.serviceName}</option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
              <div className="optionshhd">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-dark my-3">
                    Enter User ID/Account No.
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(event) => SetuserID(event.target.value)}
                    placeholder="Enter User ID/Account No."
                  />
                  <Form.Text className="text-muted">
                    Verification Required.
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-dark my-2">Amount</Form.Label>
                  <Form.Control
                    type="tel"
                    onChange={(event) => {
                      setamount(event.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-dark my-2">
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    onChange={(event) => {
                      setphone(event.target.value);
                    }}
                  />
                </Form.Group>
                <Button
                  variant={loading ? "warning" : "primary"}
                  disabled={loading}
                  onClick={handleverifier}
                  className="my-2 w-100"
                  type="submit"
                >
                  {loading ? (
                    <>
                      {" "}
                      <Spinner animation="border" size="sm" /> {responspayload}
                    </>
                  ) : (
                    "Verifying User"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cabletvsubescription;
