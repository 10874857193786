import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import "./CreateUser.css";
import { useAuth } from "../../Service/UserAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";

function ForgetPassword() {
  const [email, setemail] = useState("");
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const { resetPassword } = useAuth();

  async function handlereset(e) {
    e.preventDefault();

    try {
      setloading(true);
      await resetPassword(email);
      toast.success("Check your inbox for further instructions");
      setInterval(() => {
        history.push("/login");
      }, 2000);
    } catch (e) {
      toast.error(e.message);
    }
    setloading(false);
  }

  return (
    <div>
      <ToastContainer />
      <div className="registrationrapper">
        <div className="rgistercontern">
          <div className="registerheass">
            <center>
              <img src="/img/logo.png" alt="" />
              <h1>Welcome Back!!</h1>
              <span>Let get you started</span>
            </center>
          </div>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                onChange={(event) => {
                  setemail(event.target.value);
                }}
                required
              />
            </Form.Group>
            <Button
              variant={loading ? "warning" : "primary"}
              disabled={loading}
              onClick={handlereset}
              className="w-100"
              type="submit"
            >
              {loading? <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        /> : "Rest Password"}
            </Button>
            <center>
              <p>
                I know my password now.{" "}
                <Link to="/login" className="text-danger jsjsiaiaia">
                  Login
                </Link>
              </p>
            </center>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
