import React from 'react'
import { useHistory } from 'react-router-dom'
import "./Homeindex.css"

function HomeIndex() {
    const history =  useHistory()

    const handlelogin = () =>{
history.push("/login")
    }
  return (
    <div>
        <div className="indexhome">
            <div className="homeallshe">
                <center>
                <h1>Let Smile With MezuFLex</h1>
                <span>Buy Cheap Data, Buy Airtime, Pay Bills and Get Electricity Token.</span> <br/>
                <button onClick={handlelogin}>Get Started</button>
                </center>
            </div>
        </div>
    </div>
  )
}

export default HomeIndex