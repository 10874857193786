import axios from "axios";
import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { backendUrl, database } from "../../../../firebase";
import { getRandomString } from "../../../../Service/GetRandomNumber";
import firebase from "firebase";
import "./Create.css"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";

function AdminRegister() {
    const [number, setnumber] = useState("");
    const [password, setpassword] = useState("");
    const [firstname, setfirst] = useState("");
    const [lastname, setLast] = useState("");
    const [confirmpassword, setcomfirmpassword] = useState("");
    const [email, setemail] = useState("");
    const baseURL = backendUrl+"/create/admin";
  
    const [loading, setloading] = useState(false);
    const history = useHistory();


  const handleregister = async (e) => {
    e.preventDefault();

    const id = database.collection("_").doc().id;
    const UniquieNum = getRandomString(7, "1234567890")
    const unikey = getRandomString(7, "mezu1234567890");
    const data = {
      id,
      UniquieNum,
      email,
      password,
      confirmpassword,
      number,
      firstname,
      lastname,
      wallet: 0,
      unikey:unikey,
      coin: 0,
      isAllowed: true,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if (password !== confirmpassword) {
      toast.error("password Mismatch");
      return;
    }

    if (
      password === "" ||
      confirmpassword === "" ||
      firstname === "" ||
      lastname === "" ||
      email === "" ||
      number === ""
    ) {
      toast.error("required input value missing");
      return;
    }

    setloading(true);

    axios
      .post(baseURL, { data: data })
      .then(async (e) => {
       
        toast.success(e.data.messeage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((e) => {
        toast.error(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setloading(false);
  };
  return (
    <div>
      <div className="adminallcontainer">
        <div className="adminroutecotne">
          <ToastContainer />
          <div className="adminregistrationrapper">
            <div className="adminrgistercontern">
              <div className="adminregisterheass">
                <center>
                  <img src="/img/logo.png" alt="" />
                  <h1>This is Home!! Mezu-welomes you</h1>
                  <span>
                    Tell Mezu about yourself to access the admin pannel
                  </span>
                </center>
              </div>
              <Form>
                <Row>
                  <Col>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      onChange={(event) => {
                        setfirst(event.target.value);
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      onChange={(event) => {
                        setLast(event.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      required
                      onChange={(event) => {
                        setemail(event.target.value);
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      required
                      onChange={(event) => {
                        setnumber(event.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      required
                      onChange={(event) => {
                        setpassword(event.target.value);
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      required
                      onChange={(event) => {
                        setcomfirmpassword(event.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <Button
                  variant={loading ? "warning" : "primary"}
                  disabled={loading}
                  onClick={handleregister}
                  className="w-100 my-2"
                  type="submit"
                >
                  Submit
                </Button>
                <center>
                  <p>
                    Already have an account{" "}
                    <Link to="/mezuflex/login" className="text-white jsjsiaiaia">
                      Login
                    </Link>
                  </p>
                </center>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminRegister;
