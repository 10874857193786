import React, { useCallback, useEffect, useState } from 'react'
import { getReport } from '../../../Service/GetUser.service';
import {FcExpand} from "react-icons/fc"
import { formatNumber } from '../../../Service/Utility';
import { format } from 'date-fns';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { database } from '../../../firebase';
import firebase from 'firebase';

function IssueReport() {
    const [userCollection,setcollection] =  useState([])
    const [search,setsearch]=  useState("")
    const [show, setShow] = useState(false);
    const [response, setreponse] =  useState("")
    const [item, setitem]= useState([])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true)
    const [loading, setLoading]=  useState(false)
    const [message, setmessage] =  useState("")
    useEffect(() => { 
        getReport(null,(collections) => {
            setcollection(collections);
          });
    }, [])

    const Togglemodal = useCallback(
        (item) => () => {
           setitem(item)
          handleShow();
        },
        []
      );

      const handlesend = async(e)=>{
        e.preventDefault()
        setLoading(true)
    try {
        await database
        .doc(`/Report/${item.id}`)
        .update({
            response: response,
            isTreated:true,
            Responstime: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((e)=>{
        setmessage("Please note that this issue status has being updated to close, any issue after this operation need the user to create a new ticket and treat accordingly")
        })
    } catch (error) {
        
    }
    setLoading(false)
      }



  return (
    <div>

<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Feeback Center</Modal.Title>
         
        </Modal.Header>
        <center>{item.customername}</center>
        <Modal.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Response to user Complain</Form.Label>
        <Form.Control as="textarea" rows={3}  onChange={(event)=>{
            setreponse(event.target.value)
        }}/>
      </Form.Group>
        </Modal.Body>
        <center className='text-success'>{message}</center>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" disabled={loading} onClick={handlesend}>Send Message</Button>
        </Modal.Footer>
      </Modal>
         <div className="logcontainer">
        <div className="logcontent">
          <div className="logitems">
            <div className="logheader">Report Log</div>
            <div className="logsearcharea">

              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="search"
                  onChange={(event) => {
                    setsearch(event.target.value);
                  }}
                />
              
              </Form.Group>
            </div>
            <div className="logotable">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th className='hide'>Date</th>
                    <th>Service</th>
                    <th className='hide'>Amount</th>
                  
                    <th className='hide'>Message</th>
                    
                    <th>View More</th>
                  </tr>
                </thead>
                <tbody>
                  {userCollection
                    .filter((val) => {
                      if (search === "") {
                        return val;
                      } else if (val.amount.includes(search)) {
                        return val;
                      } else if (
                        val.service
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return val;
                      }else if (
                        val.customername
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return val;
                      } 
                       
                    })
                    .map((value, id) => {
                    
                      return (
                        <tr key={id}>
                          <td>{id + 1}</td>
                          <td className='hide'>
                            {value.created
                              ? format(value.created, "dd/MM/yyyy hh:mm a")
                              : ""}
                          </td>
                          <td>
                            {value.service} 
                          </td>
                          <td className='hide'>{formatNumber(value.amount)}</td>
                          <td>{value.report}</td>
                          
                          <td onClick={Togglemodal(value)}>
                            <FcExpand />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {/* {loading && <div className="text-center"> loading ... </div>}
                  {!loading && !isEmpty && (
                    <div className="text-center newlogbuttin">
                      <button onClick={fetchMore} >see more</button>
                    </div>
                  )}
                  {isEmpty && <h1 className="hshhs">no more data to fetch</h1>}
                */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IssueReport