import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage'
import "firebase/firebase-functions"

const FirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAMBSJcAjRa2G6l2V4Yb98PfbZHnZl0_f8",
  authDomain: "mazuflex-app.firebaseapp.com",
  projectId: "mazuflex-app",
  storageBucket: "mazuflex-app.appspot.com",
  messagingSenderId: "566166071505",
  appId: "1:566166071505:web:726727be74c6add6517df0"
});

export const auth = FirebaseApp.auth()
export const database = FirebaseApp.firestore();
const storage = firebase.storage()
const functions = firebase.functions()
const backendUrl = 'https://mezuflex.com/';

export  {
  storage,functions,  FirebaseApp as default, backendUrl
}
