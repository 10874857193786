import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import "./Services.css";
import Mtn from "../../Dataprice.json";
import Glo from "../../glo.json";
import Airtel from "../../airtel.json";
import Mobile from "../../9mobile.json";
import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../Service/UserAuth";
import { database } from "../../firebase";
import { formatNumber } from "../../Service/Utility";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import { format } from "date-fns";
const walleturl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/getuserwallet";

function Cabletvsubescription() {
  const [selectednetwork, setselectednetwork] = useState("mtn");
  const [image, setimage] = useState("/img/gotv.png");
  const [mtn] = useState(Mtn);
  const [airtel] = useState(Airtel);
  const [glo] = useState(Glo);
  const [mobil] = useState(Mobile);
  const [b, setb] = useState("");
  const [datatable, setdatacable] = useState([]);
  const [phoneea, setphone] = useState("");
  const { loginuser, userwallet } = useAuth();
  const [networkboundle, setboundle] = useState([]);
  const [bundleprice, setbundleprice] = useState(0);
  const [loading, setloading] = useState(false);
  const [iuc, setiuc] = useState("");
  const [responspayload, setresponsepayload] = useState("");
  const [payload, setpayload] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [v, setv] = useState(false);
  const history = useHistory();
  const [cableresponse, setcableresponse] = useState([]);

  const [status, setstatus] = useState("");
  const [code, setboundconde] = useState("");
  const [showpayload, setShowpayload] = useState(false);
  const [disable, setdisable] = useState(false);
  const [datapayload, setdatapayload] = useState([]);
  const [boundname, setboundleName] = useState("");
  const [net, setnet] = useState("");
  const handleClosepayload = () => {
    setShowpayload(false);
    window.location.href = "/data";
  };
  const [pro, setprofit] = useState(0);
  const handleShowpayload = () => setShowpayload(true);
  const date = format(new Date(), "dd/MM/yyyy hh:mm a");
  // const m = mtn.data;
  // const g = glo.data;
  // const a = airtel.data;
  // const e = mobil.data;
  // console.log(bundleprice)

  useEffect(() => {
    const result = networkboundle.find((name) => name.plan === b);

    if (result) {
      const price = result.price;

      setbundleprice(parseFloat(price));
      const boundlecode = result.PRODUCT_ID;
      setboundconde(boundlecode);
      const net = result.network;
      setnet(net);

      const bundleName = result.plan;
      setboundleName(bundleName);
      const profit = result.price - result.buyPrice;
      setprofit(profit);
    }
    setdatacable(result);
    // if (selectednetwork === "mtn") {
    //   setboundle(m);
    // } else if (selectednetwork === "glo") {
    //   setboundle(g);
    // } else if (selectednetwork === "airtel") {
    //   setboundle(a);
    // } else if (selectednetwork === "etisalat") {
    //   setboundle(e);
    // } else {
    //   setboundle("");
    // }

    if (selectednetwork === "mtn") {
      setimage("/img/mtn.png");
    } else if (selectednetwork === "glo") {
      setimage("/img/glo.png");
    } else if (selectednetwork === "airtel") {
      setimage("/img/airtel.png");
    } else if (selectednetwork === "etisalat") {
      setimage("/img/9mobile.png");
    } else {
      setimage("/img/mtn.png");
    }
    handlenetworkprvider(selectednetwork);
  }, [image, selectednetwork, b, loginuser, net, bundleprice]);

  const handlenetworkprvider = async (e) => {
    const val = e;
    await axios
      .get(
        `https://us-central1-mazuflex-app.cloudfunctions.net/GetDataPrice/${val}`
      )
      .then((result) => {
        setboundle(result.data);
      });
  };

  const handleparkage = (e) => {
    setb(e.target.value);
  };

  const ref = database.collection("_").doc().id;
  const handlePring = (e) => {
    e.preventDefault();
    history.push(`/transaction/${datapayload.id}`);
  };
  const phone = phoneea ? phoneea : loginuser.number;
  const handleverifier = async (e) => {
    e.preventDefault();

    if (isNaN(phone)) {
      toast.error("Number Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (phoneea === "") {
      toast.error("Number Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (b === "") {
      toast.error("Bad format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const data = {
      network: net,
      bundle: boundname,
      number: phone,
      referrence: ref,
      plan: b,
    };

    setpayload(data);
    handleShow();
    setloading(false);
  };

  const handlepayment = async (e) => {
    e.preventDefault();
    const com = 0;
    const payable =
      loginuser.permissions === "user" ? bundleprice + com : bundleprice;

    if (userwallet.wallet < payable) {
      toast.error("Insufficent wallet balance, Please Top", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setloading(false);
      return;
    }

    setloading(true);
    try {
      await axios
        .post(walleturl, { id: userwallet.id, amount: payable })
        .then(async (e) => {
          if (e.data.message !== "success") {
            toast.error("Insufficent wallet balance, Please Top", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return;
          }
          const data1 = {
            id: ref,
            company: selectednetwork,
            customername: payload.plan,
            address: "no value",
            userID: loginuser.id,
            AccountNumber: payload.number,

            walletbalance: userwallet.wallet,
            amount: bundleprice,
            phone: phone,
            type: "DATA",
            status: "Network Error",
            created: firebase.firestore.FieldValue.serverTimestamp(),
          };
          const vamount = parseFloat(e.data.amount);

          if (e.data.message === "success") {
            setresponsepayload("Connecting service ..");
            await axios
              .post(
                "https://us-central1-mazuflex-app.cloudfunctions.net/GetDataPrice",
                {
                  network: payload.network,
                  bundle: code,
                  number: payload.number,
                  referrence: payload.referrence,
                  isApproved: e.data.isApproved,
                }
              )
              .then(async (e) => {
                setdisable(true);
                setresponsepayload("finalizing connection. Please wait ");
                if (
                  e.data.status === "success" ||
                  e.data.status === "ORDER_RECEIVED"
                ) {
                  handleClose();
                  setcableresponse(e.data.data);
                  setstatus("success");
                  handleShowpayload();
                  const data = {
                    id: ref,
                    company: payload.network,
                    customername: phone,
                    address: "no value",
                    userID: loginuser.id,
                    AccountNumber: phone,
                    walletbalance: userwallet.wallet,
                    amount: parseFloat(bundleprice),
                    phone: phone,
                    payloadres: e.data,
                    type: "DATA",
                    isToday: true,
                    status: "success",
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                  };
                  setdatapayload(data);
                  await database
                    .collection("Transaction")
                    .doc(data.id)
                    .set(data);
                  await database.doc(`/income/66DyYlsUXUHQdRUIkDrs`).update({
                    dataincome: firebase.firestore.FieldValue.increment(pro),
                    totalincome: firebase.firestore.FieldValue.increment(pro),
                  });
                } else {
                  handleClose();

                  setstatus("error");
                  handleShowpayload();
                  setdatapayload(data1);
                  await database
                    .collection("Transaction")
                    .doc(data1.id)
                    .set(data1);
                }
                setdisable(false);
                setresponsepayload("");
              })
              .catch((e) => {
                toast.error(e.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          } else {
            toast.error("Insufficent wallet balance, Please Top", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setresponsepayload("");
          }
        })
        .catch((e) => {
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (e) {
      toast.error(e.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setloading(false);
  };

  const handleselectednetwork = (e) => {
    setb("");
    setbundleprice(0);
    setselectednetwork(e.target.value);
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        centered
        backdrop="static"
        show={showpayload}
        onHide={handleClosepayload}
        animation={false}
      >
        <Modal.Body>
          {status === "error" ? (
            <>
              <div className="headersjdjd bg-danger">
                <h1>ERROR</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectednetwork ? selectednetwork.toUpperCase() : ""}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Bundle :</div>
                  <div className="infodetails">{payload.bundle}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Number :</div>
                  <div className="infodetails">{payload.number}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Price :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(bundleprice)}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Message :</div>
                  <div className="infodetails">
                    Transaction Failed, Please try again later
                  </div>
                </div>
                <center className="text-danger p-1">{responspayload}</center>
                <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    disabled={disable}
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button
                    className="w-100 m-1"
                    type="submit"
                    disabled={disable}
                    onClick={handlePring}
                  >
                    Print
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="headersjdjd bg-success">
                <h1>SUCCESS</h1>
              </div>
              <p>Transaction Details</p>
              <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {selectednetwork ? selectednetwork.toUpperCase() : ""}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Bundle :</div>
                  <div className="infodetails">{payload.bundle}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Number :</div>
                  <div className="infodetails">{payload.number}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Price :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(bundleprice)}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">{date}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Message :</div>
                  <div className="infodetails">Transaction Successful</div>
                </div>
                <center className="text-danger">{responspayload}</center>
                <div className="d-flex">
                  <Button
                    variant="danger"
                    className="w-100 m-1 "
                    type="submit"
                    disabled={disable}
                    onClick={handleClosepayload}
                  >
                    back
                  </Button>

                  <Button
                    className="w-100 m-1"
                    type="submit"
                    disabled={disable}
                    onClick={handlePring}
                  >
                    Print
                  </Button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        centered
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <div className="headersjdjd">
            <h1>In-Progress</h1>
          </div>
          <p>Kindly confirm the information below before you proceed</p>
          <div className="infocontainer">
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">
                {selectednetwork ? selectednetwork.toUpperCase() : ""}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Bundle :</div>
              <div className="infodetails">{payload.bundle}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Number :</div>
              <div className="infodetails">{payload.number}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Price :</div>
              <div className="infodetails">
                &#8358; {formatNumber(bundleprice)}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">{date}</div>
            </div>
          </div>
        </Modal.Body>

        <center className="info">{responspayload}</center>
        <div className="d-flex">
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlepayment}
            className="m-1 w-50"
            type="submit"
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Pay with Wallet"
            )}
          </Button>

          <Button
            variant="danger"
            onClick={handleClose}
            className="m-1 w-50"
            type="submit"
          >
            Back
          </Button>
        </div>
      </Modal>
      <div className="allcablesyns">
        <div className="allcableitems">
          <div className="cableallre">
            <div className="imagesetuib">
              <img src={image} alt="" />
            </div>
            <div className="productsed">
              <h2>
                <center className="text-dark">DATA SUBSCRIPTION</center>
              </h2>
              <Form.Label className="text-dark my-3">Buy Data</Form.Label>
              <FloatingLabel
                controlId="floatingSelect"
                onChange={handleselectednetwork}
              >
                <Form.Select aria-label="Floating label select example">
                  <option value="mtn">MTN</option>
                  <option value="glo">GLO</option>
                  <option value="airtel">AIRTEL</option>
                  <option value="etisalat">9Mobile</option>
                </Form.Select>
              </FloatingLabel>
              <div className="optionshhd">
                <Form.Label className="text-dark my-3">
                  Select your preferred Package
                </Form.Label>
                <Form.Select
                  value={b}
                  required
                  aria-label="Floating label select example"
                  onChange={handleparkage}
                >
                  <option key="">Please Select Parkage</option>
                  {networkboundle.map((b) => {
                    return (
                      <>
                        <option key={b.bundle}>{b.plan}</option>
                      </>
                    );
                  })}
                </Form.Select>
                <Form.Label className="text-dark my-1">
                  Price : &nbsp; &#8358;{formatNumber(bundleprice)}
                </Form.Label>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="text-dark my-2">Recipient</Form.Label>
                  <Form.Control
                    placeholder={loginuser.number}
                    type="email"
                    onChange={(event) => {
                      setphone(event.target.value);
                    }}
                  />
                </Form.Group>
                <Button
                  variant={loading ? "warning" : "primary"}
                  disabled={loading}
                  onClick={handleverifier}
                  className="my-2 w-100"
                  type="submit"
                >
                  {loading ? (
                    <>
                      {" "}
                      <Spinner animation="border" size="sm" /> {responspayload}
                    </>
                  ) : (
                    "Buy Data"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cabletvsubescription;
