import React, { useCallback, useEffect, useState } from "react";
import "./Dashoard.css";
import { Link, useHistory } from "react-router-dom";
import { getuser, getusertransaction, getuserwallettransactions } from "../../Service/GetUser.service";
import { useAuth } from "../../Service/UserAuth";
import { formatNumber, timeStamptoDate } from "../../Service/Utility";
import { Form, Spinner, Table } from "react-bootstrap";
import { format } from "date-fns";
import { FcApproval, FcHighPriority } from "react-icons/fc";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import firebase from "firebase";
import { database } from "../../firebase";

function TransactionLog() {
  const history = useHistory();
  const { uid } = useAuth().currentUser;
  const [user, setuser] = useState([]);

  const [userCollection, setuserCollection] = useState([]);
  const [lastDocs, setlastDocs] = useState();
  const [isEmpty, setisEmpty] = useState(false);
  const [loading, setlaoding] = useState(false);
  const [show, setShow] = useState(false);
  const [item, setitem] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = (id)=> {
   
      history.push(`/transaction/${id}`)
   
  };
  const [pins, setpin] = useState([]);
  const [fullscreen, setFullscreen] = useState(true);
  const [report, setreport] =useState("")
  const [showreport, setShowreport] = useState(false);
  const handleClosereport = () => setShowreport(false);
  const handleShowreport = () => setShowreport(true);
  const [message, setmessage] =  useState("")

  
  const [currentPage, setcurrentpage] = useState(1);

  const recordpage = 10;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = userCollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(userCollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  useEffect(() => {
    getuserwallettransactions(uid,(result)=>{
      setuserCollection(result)
    })
  }, []);


   
  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };


  const date = format(new Date(), "dd/MM/yyyy hh:mm a");
  const Togglemodal = useCallback(
    (item) => () => {
      handleShow(item.id);
      setitem(item);
      const pins =
        item.type === "Wace e-PIN" || item.type === "exam e-PIN"
          ? item.payloadres.pins
          : [];
      setpin(pins);
    },
    []
  );
  
  const handlesend = async(e)=>{
    e.preventDefault()
    const id = database.collection("_").doc().id;
    const data = {
      id,
      report,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      service : item.company,
      amount :item.amount,
      customername:`${user.fullname} ${user.lastname}`,
      Trxt:item.id,
      userID:user.id,
      isTreated:false
    }

    if (report === ""){
      toast.console.error('Give details of your complain', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return
    }
    try {
      await database
      .collection("Report")
      .doc(data.id)
      .set(data).then(()=>{
        toast.success('Sorry for the inconvenience experience while making this transaction, we will treat and give feeback shortly. Thanks', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setmessage("Sorry for the inconvenience experience while making this transaction, we will treat and give feeback shortly. Thanks")
      })
    } catch (error) {
      console.log(error.message)
    }
  }
  // exam e-PIN"

  return (
    <div>
      <Modal
        show={showreport}
        onHide={handleClosereport}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Report Issue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group className="mb-3">
            <Form.Label>Service Type</Form.Label>
            <Form.Control type="text" value={item.company}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control type="text" value={item.amount} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>User Name</Form.Label>
            <Form.Control type="text" value={`${user.firstname} ${user.lastname}`} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Transaction ID</Form.Label>
            <Form.Control type="text" value={item.id}/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Give Report Here</Form.Label>
        <Form.Control as="textarea" rows={3} onChange={(event)=>{
          setreport(event.target.value)
        }}/>
      </Form.Group>
        </Modal.Body>
      <center>{message}</center>
        <Modal.Footer>
         
          <Button variant="secondary" onClick={handleClosereport}>
            Close
          </Button>
          <Button variant="success" onClick={handlesend}>Send</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        fullscreen={fullscreen}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transaction Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {item.type === "Wace e-PIN" || item.type === "exam e-PIN" ? (
            <div className="infocontainer">
              <div className="inforcontenr">
                <div className="infotittle">Company :</div>
                <div className="infodetails">
                  {item.company ? item.company.toUpperCase() : ""}
                </div>
              </div>

              <div className="carconatienr">
                <div className="cordrawwaper">
                  {pins.map((item, id) => {
                    return (
                      <div key={id}>
                        <div className="cardcontenr">
                          <div className="cardinma">
                            <img src="/img/waec.png" alt="" />
                          </div>
                          <div className="caedtejnss">
                            <div className="inforcontenr">
                              <div className="infotittle"> Pin:</div>
                              <div className="infodetails">{item.pin}</div>
                            </div>

                            <div className="inforcontenr">
                              <div className="infotittle"> Serial Number:</div>
                              <div className="infodetails">
                                {item.serialNumber}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Amount :</div>
                <div className="infodetails">
                  &#8358; {formatNumber(item.amount)}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Number of Pin :</div>
                <div className="infodetails">{pins.length}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Transaction Time :</div>
                <div className="infodetails">
                  {item.created
                    ? format(item.created, "dd/MM/yyyy hh:mm a")
                    : ""}
                </div>
              </div>
              <div className="d-flex">
                <Button
                  variant="secondary"
                  className="w-100 m-1"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  variant="danger"
                  className="w-100 m-1"
                  onClick={handleShowreport}
                >
                  Report Issue
                </Button>
                <Button
                  className="w-100 m-1"
                  type="submit"
                  onClick={() => window.print()}
                >
                  Print
                </Button>
              </div>
            </div>
          ) : (
            <div className="infocontainer">
              <img src="/img/" alt="" />
              <div className="inforcontenr">
                <div className="infotittle">Company :</div>
                <div className="infodetails">{item.company}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Account Number :</div>
                <div className="infodetails">
                  {item.AccountNumber ? item.AccountNumber : item.meter}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Customer Name :</div>
                <div className="infodetails">
                  {item.customername === "no value" ? "" : item.customername}
                </div>
              </div>

              <div
                className="inforcontenr"
                style={{ display: item.token ? "block" : "none" }}
              >
                <div className="infotittle">Unit :</div>
                <div className="infodetails">
                  {item.unit === "no value" ? "" : item.unit}
                </div>
              </div>

              <div
                className="inforcontenr"
                style={{ display: item.token ? "block" : "none" }}
              >
                <div className="infotittle">Token :</div>
                <div className="infodetails">
                  {item.token === "no value" ? "" : item.token}
                </div>
              </div>

              <div
                className="inforcontenr"
                style={{ display: item.token ? "block" : "none" }}
              >
                <div className="infotittle">Address :</div>
                <div className="infodetails">
                  {item.address === "no value" ? "" : item.address}
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Amount :</div>
                <div className="infodetails">
                  &#8358; {formatNumber(item.amount)} <br />
                  Charge Inclusive
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Transaction Time :</div>
                <div className="infodetails">
                  {item.created
                    ? format(item.created, "dd/MM/yyyy hh:mm a")
                    : ""}
                </div>
              </div>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="danger" onClick={handleShowreport}>
                  Report Issue
                </Button>
                <Button variant="primary" onClick={() => window.print()}>
                  Print Reciept
                </Button>
              </Modal.Footer>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <ToastContainer />
      <div className="buid">
        <div className="quicktoservices">
            <Link to="/wallet/history"><button style={{background:"green", color:"white"}}>Wallet History</button></Link>
            <Link to="/transaction"><button>Transaction History</button></Link>
        </div>
        <div className="showtransactions  alloverfolowprodud">
          <div className="transactionprijhse">
            {records.length === 0 ? (
              <>
                <center> No record found</center>
              </>
            ) : (
              <Table striped="columns">
                <thead>
                  <tr className="bg-white">
                    <th>S/N</th>
                    <th className="hide">Data/Time</th>
                    <th>Amount</th>
                    <th>Pre_Bal</th>
                    <th>Post_Bal</th>
                    <th>Service Type</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((itemDoc, id) => {
                    const item = timeStamptoDate(itemDoc);

                    return (
                      <tr
                        key={id}
                        className="bg-light newLldjdjd cuspointer"
                       
                      >
                        <td>{id + 1}</td>
                        <td className="hide">
                          {item.created
                            ? format(item.created, "dd/MM/yyyy hh:mm a")
                            : ""}
                        </td>
                        <td className="iconesdes">
                          {item.amount}{" "}
                        </td>
                        <td >{item.PreBal ? formatNumber(item.PreBal.toFixed(2)) : 0}</td>
                        <td>{item.postBal ? formatNumber(item.postBal.toFixed(2)): 0}</td>
                        <td >{item.type}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
      <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>

                {numbers.slice(0, 10).map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span
                        className="page-link"
                        onClick={() => changecurrentPage(n)}
                      >
                        {n < 11 ? n : n + 1}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionLog;
