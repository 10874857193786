import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getAdminnotification } from '../../../Service/GetUser.service';
import { formatNumber } from '../../../Service/Utility';
import axios from "axios";
import { database } from '../../../firebase';
import { useAuth } from '../../../Service/UserAuth';

function PaymentNotification() {
    const [paymentnotification, setpaymentnotification] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [amount, setamount] = useState(0);
    const [loading, setloading] = useState(false);
    const [respons, setresposne] = useState("");
    const { loginuser } = useAuth();
    const topUrl = "https://us-central1-mazuflex-app.cloudfunctions.net/getTopUp";
    useEffect(() => {
        getAdminnotification().then((result) => {
            setpaymentnotification(result);
          });
    }, [])


    const Togglemodal = useCallback(
        (item) => () => {
          if (item.isApproved === true) {
            setresposne("already treated");
            return;
          }
          setamount(item);
          handleShow();
        },
        []
      );
    
      const handleTop = async (e) => {
        e.preventDefault();
        setloading(true);
        setresposne("Authentication");
    
        await axios
          .post(topUrl, {
            userID: loginuser.id,
            id: amount.userId,
            amount: parseFloat(amount.amount),
          })
          .then(async (e) => {
            setresposne(e.data.message);
            database
              .doc(`/PaymentNotification/${amount.id}`)
              .update({
                isApproved: true,
              })
              .then((e) => {
                console.log(e);
              });
            setloading(false);
          });
        setloading(false);
      };
    
  return (
    <div>
         <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Fund Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Enter Amount</Form.Label>
            <Form.Control type="tel" disabled value={amount.amount} />
            <Form.Text className="text-muted">
              Enter amount you want to credit user with. Enter negative value if
              you want to debit
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <center>
          <p className="text-success"> {respons}</p>
        </center>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={loading || amount.isApproved}
            onClick={handleTop}
          >
            {loading ? (
              <Spinner animation="grow" variant="warning" />
            ) : (
              "Fund Wallet"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Link to={`/admin/auth/${loginuser.id}`}><div className="headeradmin">Back To Dashboard</div></Link>

         <div className="summarysection p-2">
          <div className="warrheasderadmin">
            <h6>Recent Funding Request</h6>
            <Link to="/fund/request">
              <p>View More</p>
            </Link>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S/N</th>
                <th className="hide">Date</th>
                <th>Depositor</th>
                <th>Amount &#8358;</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paymentnotification.map((value, id) => {
                return (
                  <tr key={id}>
                    <td>{id + 1}</td>
                    <td className="hide">
                      {value.created
                        ? format(value.created, "dd/MM/yyyy hh:mm a")
                        : ""}
                    </td>
                    <td>{value.depositor}</td>
                    <td>{formatNumber(value.amount)}</td>
                    <td>
                      {value.isApproved === false ? "Pending" : "Approved"}
                    </td>
                    <td>
                      {value.isApproved === false ? <button onClick={Togglemodal(value)}>Open</button> :"Resolved" } 
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
      </div>
    </div>
  )
}

export default PaymentNotification