import React from 'react'
import { useHistory } from 'react-router-dom'
import "./Services.css"

function MoreServices() {
  const history =  useHistory()

  const handlewaec = ()=>{
    history.push("/buy/wace")
  }

  const handleneco = ()=>{
    history.push("/buy/neco")
  }

  const handlebetting =()=>{
    history.push("/fund/betting")
  }

  return (
    <div>
      <div className="allitemsfornd">
        <div className="allconteionr">
          {/* <div className="ddjbdd" onClick={handlebetting}>
            <img src="/img/bet.png" alt="" />
            <span>Fund wallet</span>
          </div> */}
          <div className="ddjbdd" onClick={handleneco}>
            <img src="/img/neco.png" alt="" />
            <span>Neco result checker</span>
          </div>
          <div className="ddjbdd" onClick={handlewaec}>
            <img src="/img/waec.png" alt="" />
            <span>Wace result checker</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MoreServices