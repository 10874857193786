import axios from "axios";
import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import "./Create.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../../../Service/UserAuth";

function AdminRegister() {
    const [password, setpassword] = useState("");
    const [email, setemail] = useState("");
    const [loading, setloading] = useState(false);
      const history =  useHistory()
      const { login } = useAuth();
      
  
    const handlelogin = async (e) => {
      e.preventDefault();
      setloading(true);
      try {
        await login(email, password);
        toast.success("Please wait while fetching data");
        history.push("/check_user");
      } catch (e) {
        toast.error(e.message);
      }
  
      setloading(false)
    };
  return (
    <div>
      <div className="adminallcontainer">
        <div className="adminroutecotne">
          <ToastContainer />
          <div className="adminregistrationrapper">
            <div className="adminrgistercontern">
              <div className="adminregisterheass">
                <center>
                  <img src="/img/logo.png" alt="" />
                  <h1>This is Home!! Mezu-welomes you</h1>
                  <span>
                    Welcome Back
                  </span>
                </center>
              </div>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    onChange={(event) => {
                      setemail(event.target.value);
                    }}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    onChange={(event) => {
                      setpassword(event.target.value);
                    }}
                    required
                  />
                </Form.Group>
                <Button
                  variant={loading ? "warning" : "primary"}
                  disabled={loading}
                  onClick={handlelogin}
                  className="w-100"
                  type="submit"
                >
                  Submit
                </Button>
              
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminRegister;
