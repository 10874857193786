import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import "../Servicescomponent/Services.css";

function MoreServices() {
  const history =  useHistory()

  const handlewaec = ()=>{
    history.push("/more/service/waec")
  }

  const handleneco =()=>{
    history.push("/more/service/neco")
  }

  useEffect(() => {
    history.push('/login')
  }, [])
  

  return (
    <div>
      <div className="allitemsfornd">
        <div className="allconteionr">
          {/* <div className="ddjbdd" onClick={handlebetting}>
            <img src="/img/bet.png" alt="" />
            <span>Fund wallet</span>
          </div> */}
          <div className="ddjbdd" onClick={handleneco}>
            <img src="/img/neco.jpg" alt="" />
            <span>Neco result checker</span>
          </div> 
          <div className="ddjbdd" onClick={handlewaec}>
            <img src="/img/waec.png" alt="" />
            <span>Wace result checker</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MoreServices