import React, { useEffect } from 'react'
import Sidebar from '../../../Component/ClientComponent/Sidemenu';
import '../../../App.css'
import axios from 'axios';
import AnAirtime from '../AnAirtime';


function CableTV() {

  return (
    <div  className='pageindexcloser' >
     <div className="allwraper">
     <div className="allitems" style={{backgroundImage: "url(/img/comic4.jpg)"}}>
     <div className="allsjuijd">
     <Sidebar/>
     </div>
      </div>
      <div className="allitemsksksnew">
      <AnAirtime/>
      </div>
     </div>
    </div>
  )
}

export default CableTV