
import React from 'react'
import Registercomponet from '../Component/ClientComponent/Registercomponent';
import Sidebar from '../Component/ClientComponent/Sidemenu';
import '../App.css'

function Home() {

  return (
    <div  className='pageindexcloser' style={{backgroundImage: "url(/img/comic4.jpg)"}}>
     <div className="allwraper neaals">
     <div className="allitems">
      <Sidebar/>
      </div>
      <div className="allitemsksks">
      <Registercomponet/>
      </div>
     </div>
    </div>
  )
}

export default Home