import React, { useCallback, useEffect, useState } from "react";
import "../../../Component/ClientComponent/Dashoard.css";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Form, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { format } from "date-fns";
import { FcApproval, FcHighPriority } from "react-icons/fc";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { getadmtransaction, getuser, getuserwallet } from "../../../Service/GetUser.service";
import Navbar from "./NavbarAdmin";
import { database } from "../../../firebase";
import { useAuth } from "../../../Service/UserAuth";
import axios from "axios"
import { formatNumber } from "../../../Service/Utility";
function TransactionLog() {
  const [userCollection, setuserCollection] = useState([]);
  const [search, setsearch] = useState("");
  const [item, setitem] = useState([]);
  const [showview, setShowview] = useState(false);
  const handleCloseview = () => setShowview(false);
  const handleShowview = () => setShowview(true);
  const [currentPage, setcurrentpage] = useState(1);
  const {loginuser} = useAuth()
  const recordpage = 10;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = userCollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(userCollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [loading, setloading] =  useState(false)
  const [userinfo, setuserinfo] = useState([]);
  const [userwallet, setuserwallet] = useState([]);
  const topUrl = "https://us-central1-mazuflex-app.cloudfunctions.net/getTopUp";
  useEffect(() => {
    getadmtransaction((collections) => {
        setuserCollection(collections);
      });

  }, []);

  
  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };

//   const date = format(new Date(), "dd/MM/yyyy hh:mm a");
const Togglemodal = useCallback(
  (item) => () => {
    setitem(item);
    handleShowview();
    getuser(item.AgentID, (result) => {       
    setuserinfo(result);
    });

    getuserwallet(item.AgentID, (result) => {  
    setuserwallet(result);
    });
  },
  []
);


const handlresolve = async (e) => {
  e.preventDefault();

  const prompt = window.prompt("Enter Pin");
  if (prompt !== loginuser.pin) {
    window.alert("Invalid pin, try again");
    return;
  }
  setloading(true);
  await database
    .doc(`/APITransaction/${item.id}`)
    .update({
      status: "success",
    })
    .then((e) => {
      window.alert("reversed successfully");
    });
    setloading(false)
};
const handlereverse = async () => {
  const prompt = window.prompt("Enter Pin");
  if (prompt !== loginuser.pin) {
    window.alert("Invalid pin, try again");
    return;
  }

  if (item.status === "Reverse"){
    window.alert('Transaction already reversed');
    return;
  }
  setloading(true);
  await axios
    .post(topUrl, {
      userID: loginuser.id,
      id: item.AgentID,
      amount: parseFloat(item.amount),
    })
    .then(async (e) => {
  
    await  database
        .doc(`/APITransaction/${item.id}`)
        .update({
          status: "Reverse",
        })
      window.alert(e.data.message);
      setloading(false);
    });
  setloading(false);
};
  // exam e-PIN"
  return (
    <div>
        <Navbar/>
        <Modal
        show={showview}
        onHide={handleCloseview}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{/* {item.firstname} {item.lastname} */}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="home"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Transaction Details">
              <div className="tabrwapper">
                <div className="userqwrapper">
                  <div className="infologcontent">
                    <div className="intitlelog">Transaction ID:</div>
                    <div className="ingolog">{item.id}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Category :</div>
                    <div className="ingolog"> {item.type}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Service Type :</div>
                    <div className="ingolog"> {item.company}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Cutsomer Name:</div>
                    <div className="ingolog">{item.customername}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Account Number:</div>
                    <div className="ingolog">{item.AccountNumber}</div>
                  </div>

                  {item.token ? (
                    <div className="infologcontent">
                      <div className="intitlelog">Token:</div>
                      <div className="ingolog">{item.token}</div>
                    </div>
                  ) : (
                    ""
                  )}

                  {item.token ? (
                    <div className="infologcontent">
                      <div className="intitlelog">Unit:</div>
                      <div className="ingolog">{item.unit}</div>
                    </div>
                  ) : (
                    ""
                  )}

                  {item.token ? (
                    <div className="infologcontent">
                      <div className="intitlelog">Debt:</div>
                      <div className="ingolog">{item.debt}</div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="infologcontent">
                    <div className="intitlelog">Address:</div>
                    <div className="ingolog">{item.address}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Mobile No.:</div>
                    <div className="ingolog">{item.phone}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Amount:</div>
                    <div className="ingolog">
                      &#8358; {formatNumber(item.amount)}
                    </div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Status:</div>
                    <div className="ingolog">{item.status}</div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">payload:</div>
                    <div className="ingolog">
                      <p>{JSON.stringify(item.payload)}</p>
                    </div>
                  </div>

                  <div className="infologcontent">
                    <div className="intitlelog">Date :</div>
                    <div className="ingolog">
                      {" "}
                      {item.created
                        ? format(item.created, "dd/MM/yyyy hh:mm a")
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="profile" title="UserProfile">
              <div className="userprofilesose">
                <div className="userprofileicon">
                  <img
                    src={userinfo.image ? userinfo.image : "/img/logo3.png"}
                    alt="user"
                  />
                </div>
                <div className="userinformations">
                  <span className="useenamecin">
                    {userinfo.firstname} {userinfo.lastname}
                  </span>
                  <br />
                  <span>{userinfo.email}</span>
                </div>
              </div>
              <div className="userqwrapper">
                <div className="infologcontent">
                  <div className="intitlelog">Email:</div>
                  <div className="ingolog">{userinfo.email}</div>
                </div>

                <div className="infologcontent">
                  <div className="intitlelog">Mobile Number :</div>
                  <div className="ingolog"> {userinfo.number}</div>
                </div>

                <div className="infologcontent">
                  <div className="intitlelog">Account Status:</div>
                  <div className="ingolog">
                    {userinfo.isBlock === false ? "Active" : "Block"}{" "}
                  </div>
                </div>

                <div className="infologcontent">
                  <div className="intitlelog">Account Type:</div>
                  <div className="ingolog">{userinfo.permissions}</div>
                </div>

                <div className="infologcontent">
                  <div className="intitlelog">Wallet Balance.:</div>
                  <div className="ingolog">{userwallet.wallet}</div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseview}>
            Close
          </Button>
          {item.status !== "success" ? (
            <Dropdown>
              <Dropdown.Toggle variant="info" id="dropdown-basic">
                Actions
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handlresolve} disabled={loading}>
                  {loading? "Please wait" : "Resolve"}
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger my-2"
                  onClick={handlereverse}
                  disabled={loading}
                >
                  {loading ? "Please wait" : "Reverse"}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
      <ToastContainer />

      <div className="buid">
        <div className="quicktoservices">
          <Link to="/api/management">
            <button >
              Back
            </button>
          </Link>
          <div className="logsearcharea">
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="search"
                  onChange={(event) => {
                    setsearch(event.target.value);
                  }}
                />
              </Form.Group>
            </div>
        </div>
        <div className="showtransactions">
          <div className="transactionprijhse">
            {userCollection.length === 0 ? (
              <>
                <center> No record found</center>
              </>
            ) : (
              <Table striped="columns">
                <thead>
                  <tr className="bg-white">
                    <th>S/N</th>
                    <th className="hide">Data/Time</th>
                    <th>Status</th>
                    <th className="hide">Service Type</th>
                    <th>Account No.</th>
                    <th>Amount</th>
                    <th className="hide">Status</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {records.filter((val) => {
                      if (search === "") {
                        return val;
                      } else if (val.AccountNumber.includes(search)) {
                        return val;
                      } else if (
                        val.mexuflexRef.toLowerCase().includes(search.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.type.toLowerCase().includes(search.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.status.toLowerCase().includes(search.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                  .map((item, id) => {
                
                    return (
                      <tr
                        key={id}
                        className="bg-light cuspointer"
                        onClick={Togglemodal(item)}
                      >
                        <td>{id + 1}</td>
                        <td className="hide">
                          {item.created
                            ? format(item.created, "dd/MM/yyyy hh:mm a")
                            : ""}
                        </td>
                        <td className="iconesdes">
                          {item.status === "success" ? (
                            <>
                              <FcApproval />
                            </>
                          ) : (
                            <>
                              <FcHighPriority />
                            </>
                          )}{" "}
                        </td>
                        <td className="hide">{item.type}</td>
                        <td>
                          {item.AccountNumber} <br />
                          <span className="ksjdhdmobid">{item.type}</span>
                        </td>
                        <td>{item.amount}</td>
                        <td className="hide">{item.status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>
                {numbers.slice(0, 10).map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span
                        className="page-link"
                        onClick={() => changecurrentPage(n)}
                      >
                        {n < 11 ? n : n + 1}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionLog;
