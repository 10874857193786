import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import "../Servicescomponent/Services.css";
import Gotv from "../../Gotv.json";
import Dstv from "../../dstv.json";
import Startimes from "../../Startimes.json";
import { PaystackButton } from "react-paystack";
import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../Service/UserAuth";
import { database } from "../../firebase";
import { formatNumber } from "../../Service/Utility";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import { functions } from "../../firebase";
import { format } from "date-fns";
import Inloader from "../../Inloader"
import BettingProviders from "../../BettingProviders.json";
const walleturl =
"https://us-central1-mazuflex-app.cloudfunctions.net/getuserwallet";


const finderurl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/getUserBettingID";
const curl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/CustomerInformation";
const url =
  "https://us-central1-mazuflex-app.cloudfunctions.net/GetcableTVProvider";
  

function Cabletvsubescription() {
    const [image, setimage] = useState(
        "https://olbgimages.co.uk/image-cache/?src=https://olbgimages.co.uk/wp-content/gallery/betting/betting_3.jpg&w=1116&h=682&zc=1"
      );
    const [BettingProvider] = useState(BettingProviders);
    const [selectedbet, setselectedbet] = useState("");
    const [userbetID, SetuserID] = useState("");
    const [datatable, setdatacable] = useState([]);
    const [phoneea, setphone] = useState("");
    const [amountvalue, setamount] = useState(0);
  const {unknow} = useAuth();
  const [cableboundle, setboundle] = useState([]);
  const [bundleprice, setbundleprice] = useState(0);
  const [loading, setloading] = useState(false);
  const [iuc, setiuc] = useState("");
  const [responspayload, setresponsepayload] = useState("");
  const [payload, setpayload] =  useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [v , setv] =  useState(false)

  const history = useHistory()
  
  const [result, setresult] =  useState("")
  const [cableresponse, setcableresponse] = useState([])
  const [cablerror, setcableerroresponse] =  useState("")
  const [ status, setstatus] = useState("")
  const [code , setboundconde] = useState("")
  const [awaiting, setwaiting] = useState(false)
  const [showpayload, setShowpayload] = useState(false);
  const [disable, setdisable]= useState(false)
  const handleClosepayload = () => {
    setShowpayload(false);
    window.location.href ="/v5/betting";
  };
  const handleShowpayload = () => setShowpayload(true);
  const date =   format(new Date(), "dd/MM/yyyy hh:mm a")

  const getProvider = BettingProvider.data;
  useEffect(() => {
    history.push('/login')
    setdatacable(getProvider);
  }, [datatable]);

  const amount =  parseFloat(amountvalue)
  const com = 30;
  const payable = amount + com;
  const handlegetimage = (e) => {
    const val = e.target.value;
    if (val === "") {
      setimage(
        "https://olbgimages.co.uk/image-cache/?src=https://olbgimages.co.uk/wp-content/gallery/betting/betting_3.jpg&w=1116&h=682&zc=1"
      );
      setdatacable([]);
      return;
    }
    setselectedbet(val);
    const bettingImage = getProvider.filter((e) => e.serviceName === val)[0];
    if (bettingImage) {
      const getimagevalue = bettingImage.serviceLogo;
      setimage(getimagevalue);
    }
  };
  const config = {
    reference: new Date().getTime().toString(),
    email: `mezuflex@gmail.com`,
    amount: payable * 100,
    publicKey: "pk_live_7c49d4df21fc6207a1ece0514092b34ad3f5bbee",
  };
  
  const handlePaystackSuccessAction = async (data) => {
    const verifyPaymentHandler = functions.httpsCallable(
      "verifyPaystackPayment"
    );
    const value = {
      ref: data.reference,
      status: data.success,
      firstname: "Customer",
      lastname: "Mezuflex",
      email: "juliuskingsley1100@gmail.com",
      userId: "iu4WWdVD3GeRe9373RpDm25zN4M2",
      phone: "08165459398",
      amount: payable,
    };
    setwaiting(true)
    await verifyPaymentHandler(value).then(async (result) => {
      
      setresult(result);
      if (result === null || result === undefined) {
        return (
          <>
            <Inloader />
          </>
        );
      }
      if (result.data.data.status === "success") {
        // make the payment for the user
        setresponsepayload("Connecting service ..")
        const ref = database.collection("_").doc().id;

        const data1 = {
            id: ref,
            company: selectedbet,
            customername: payload.name,
            address: userbetID,
            userID: "iu4WWdVD3GeRe9373RpDm25zN4M2",
            AccountNumber: payload.accountNumber,
            cablerror,
            walletbalance: 0,
            amount: payable,
            phone: phoneea,
            type: "Betting",
            status: "Network Error",
            created: firebase.firestore.FieldValue.serverTimestamp(),
          };
          await axios
              .post(
                "https://us-central1-mazuflex-app.cloudfunctions.net/fundbetting",
                {
                  account_number:userbetID,
                  amount: amount,
                  service_type: b,
                  agentReference: ref,
                }
              )
          .then(async (e) => { 
            setdisable(true)
            setresponsepayload("finalizing connection. Please wait ")           
            if (e.data.status === "success") {
              handleClose();
              setcableresponse(e.data.data);
              setstatus("success")
              handleShowpayload();
              const data = {
                id : ref,
                company : selectedbet, 
                customername: payload.name,
                address: "no value",
                userID : "iu4WWdVD3GeRe9373RpDm25zN4M2",
                AccountNumber : iuc,
                walletbalance : 0,
                amount: amount, 
                isToday:true,
                phone: phoneea,
                payloadres: e.data.data,
                
                type : "Cable", 
                created: firebase.firestore.FieldValue.serverTimestamp(),
                status : "success",
              }   
              await database.collection("Transaction").doc(data.id).set(data)
            }else{
              handleClose();
              setcableerroresponse(e.data.data);
              setstatus("error")
              handleShowpayload();
              await database.collection("Transaction").doc(data1.id).set(data1)
            }
            setdisable(false)
          })
          .catch((e) => {
            toast.error(e.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        // Setpaymentpayload(result.data.data);
        // setshowvalue(true);
        handleClose();
      } else {
        // Setpayload(result.data.data);
        handleClose();
        handleShowpayload();
      }
    }).catch((e)=>{
      toast.error(e.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    });

  };

  const handlePaystackCloseAction = () => {
    history.goBack();
  };

  const componentProps = {
    ...config,
    text: loading ? "Proccessing request" : "Pay with Paystack",
    onSuccess: (data) => handlePaystackSuccessAction(data),
    onClose: () => handlePaystackCloseAction(),
  };

  
  const b = selectedbet.toLowerCase();

  const handleverifier = async (e) => {
    e.preventDefault();

    if (selectedbet === "" || amount === 0 || phoneea === "") {
      toast.error("Bad format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (isNaN(amount)){
      toast.error("Bad format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (amount <100){
      toast.error("Minimum required amount", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; 
    }
    setloading(true);
    try {
      setresponsepayload("fetching information");
      await axios
        .post(finderurl, {
          service_type: b,
          account_number: userbetID,
        })
        .then((e) => {
         
          if (e.data.message === "Successful") {
            setpayload(e.data.data.user);
            setv(true);
            handleShow();
            setresponsepayload("");
          } else {
            toast.error("😔 Invalid Consumer Information", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
    setloading(false);
  };

//   const handleverifier = async (e) => {
//     e.preventDefault();

//     if (b === "") {
//       toast.error("Bad format", {
//         position: toast.POSITION.TOP_RIGHT,
//       });
//       return;
//     }
//     setloading(true);
//     try {
//       setresponsepayload("fetching information")
//       await axios.post(finderurl, {
//         service_type: selectedbet,
//         account_number: userbetID,
//       }).then((e)=>{
        
//          if (e.data.message === "Successful"){
//           setpayload(e.data.data.user)
//           setv(true)
//           handleShow()
//         setresponsepayload("")
//          }else{
//           toast.error("😔 Invalide customer information", {
//             position: toast.POSITION.TOP_RIGHT,
//           });
//           return
//          }
//       }).catch((e)=>{
//         console.log(e)
//       });
//     } catch (error) {
//       console.log(error)
//     }
//     setloading(false);
//   };


  // const handlepayment = async (e) => {
  //   e.preventDefault();
  //   const com = 100
  //   const payable =  bundleprice + com 

  //   setloading(true);
  //   try {
  //     await axios
  //       .post(walleturl, { id: userwallet.id, amount: payable })
  //       .then(async (e) => {
  //         setresponsepayload("validating");
       
  //         const ref = database.collection("_").doc().id;
    
  //        const data1 = {
  //           id : ref,
  //           company : selectedcable, 
  //           customername: payload.name,
  //           address: "no value",
  //           userID : loginuser.id,
  //           AccountNumber : iuc,
  //           cablerror,
  //           walletbalance : userwallet.wallet,
  //           amount:payable, 
  //           phone: phone,
  //           type : "Power", 
  //           status : "Network Error",
  //           created: firebase.firestore.FieldValue.serverTimestamp()
  //         }
         
  //         if (e.data.message === "success") {
  //           setresponsepayload("Connecting service ..");
  //           await axios
  //             .post("https://us-central1-mazuflex-app.cloudfunctions.net/buycable", {
  //               smartcard_number: iuc,
  //               total_amount: bundleprice,
  //               product_code: code,
  //               product_monthsPaidFor: payload.rawOutput.invoicePeriod,
  //               service_type: selectedcable,
  //               agentReference:ref,
  //               agentId: "205",
                
  //             })
  //             .then(async (e) => { 
                
  //               setresponsepayload("finalizing connection. Please wait ")           
  //               if (e.data.status === "success") {
  //                 handleClose();
  //                 setcableresponse(e.data.data);
  //                 setstatus("success")
  //                 handleShowpayload();
  //                 const data = {
  //                   id : ref,
  //                   company : selectedcable, 
  //                   customername: payload.name,
  //                   address: "no value",
  //                   userID : loginuser.id,
  //                   AccountNumber : iuc,
  //                   walletbalance : userwallet.wallet,
  //                   amount: payable, 
  //                   phone: phone,
  //                   payloadres: e.data.data,
                    
  //                   type : "Power", 
  //                   created: firebase.firestore.FieldValue.serverTimestamp(),
  //                   status : "success",
  //                 }   
  //                 await database.collection("Transaction").doc(data.id).set(data)
  //               }else{
  //                 handleClose();
  //                 setcableerroresponse(e.data.data);
  //                 setstatus("error")
  //                 handleShowpayload();
  //                 await database.collection("Transaction").doc(data1.id).set(data1)
  //               }
  //             })
  //             .catch((e) => {
  //               toast.error(e.message, {
  //                 position: toast.POSITION.TOP_RIGHT,
  //               });
  //             });
  //         } else {
  //           toast.error("Insufficent wallet balance, Please Top", {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //           setresponsepayload("");
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e)
  //         toast.error(e.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       });
  //   } catch (e) {
  //     console.log(e)
  //     toast.error(e.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }

  //   setloading(false);
  // };

  return (
    <div>
    <ToastContainer />

     <Modal
      //show={showprint}
      onHide={handleClosepayload}
      backdrop="static"
    //   fullscreen={fullscreen}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Transaction Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
           <div className="infocontainer">
              <div className="inforcontenr">
                <div className="infotittle">Company :</div>
                <div className="infodetails">
                  {selectedbet ? selectedbet : ""}
                </div>
              </div>

              <div className="inforcontenr">
            <div className="infotittle">Customer Name :</div>
            <div className="infodetails">{payload.customername}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">User ID :</div>
            <div className="infodetails">{userbetID}</div>
          </div>

              <div className="inforcontenr">
                <div className="infotittle">Amount :</div>
                <div className="infodetails">
                  &#8358; {payable} <br />
                  Charge Inclusive
                </div>
              </div>

          <div className="inforcontenr">
            <div className="infotittle">Transaction Time :</div>
            <div className="infodetails">{date}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Message :</div>
            <div className="infodetails">Transaction Successful</div>
          </div>
          <div className="d-flex">
            <Button
              variant="danger"
              className="w-100 m-1 "
              type="submit"
              onClick={handleClosepayload}
            >
              back
            </Button>

            <Button
              className="w-100 m-1"
              type="submit"
              //onClick={handlePring}
            >
              Print
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <Modal
      centered
      backdrop="static"
      show={showpayload}
      onHide={handleClosepayload}
      animation={false}
    >
      <Modal.Body>
        {status === "error" ? (
          <>
            <div className="headersjdjd bg-danger">
              <h1>ERROR</h1>
            </div>
            <p>Transaction Details</p>
            <div className="infocontainer">
              <div className="inforcontenr">
                <div className="infotittle">Company :</div>
                <div className="infodetails">
                  {selectedbet ? selectedbet : ""}
                </div>
              </div>

              <div className="inforcontenr">
            <div className="infotittle">Customer Name :</div>
            <div className="infodetails">{payload.customername}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">User ID :</div>
            <div className="infodetails">{userbetID}</div>
          </div>

              <div className="inforcontenr">
                <div className="infotittle">Amount :</div>
                <div className="infodetails">
                  &#8358; {payable} <br />
                  Charge Inclusive
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Transaction Time :</div>
                <div className="infodetails">{date}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Message :</div>
                <div className="infodetails">
                  Transaction Error, Please contact support
                </div>
              </div>
              <center className="text-danger">{responspayload}</center> 
            <div className="d-flex">
                <Button
                  variant="danger"
                  className="w-100 m-1 "
                  type="submit"
                  disabled={disable}
                  onClick={handleClosepayload}
                >
                  back
                </Button>

                <Button className="w-100 m-1" type="submit" disabled={disable}  >
                  Print
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="headersjdjd bg-success">
              <h1>SUCCESS</h1>
            </div>
            <p>Transaction Details</p>
            <div className="infocontainer">
              <div className="inforcontenr">
                <div className="infotittle">Company :</div>
                <div className="infodetails">
                  {selectedbet ? selectedbet : ""}
                </div>
              </div>

              <div className="inforcontenr">
            <div className="infotittle">Customer Name :</div>
            <div className="infodetails">{payload.customername}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">User ID :</div>
            <div className="infodetails">{userbetID}</div>
          </div>

              <div className="inforcontenr">
                <div className="infotittle">Amount :</div>
                <div className="infodetails">
                  &#8358; {payable} <br />
                  Charge Inclusive
                </div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Transaction Time :</div>
                <div className="infodetails">{date}</div>
              </div>

              <div className="inforcontenr">
                <div className="infotittle">Message :</div>
                <div className="infodetails">Transaction Successful</div>
              </div>
              <center className="text-danger">{responspayload}</center> 
            <div className="d-flex">
                <Button
                  variant="danger"
                  className="w-100 m-1 "
                  type="submit"
                  disabled={disable}
                  onClick={handleClosepayload}
                >
                  back
                </Button>

                <Button className="w-100 m-1" type="submit" disabled={disable} >
                  Print
                </Button>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal> 

    <Modal
      centered
      backdrop="static"
      show={show}
      onHide={handleClose}
      animation={false}
    >
      <Modal.Body>
        <div className="headersjdjd">
          <h1>In-Progress</h1>
        </div>
        <p>Kindly confirm the information below before you proceed</p>
        <div className="infocontainer">
          <div className="inforcontenr">
            <div className="infotittle">Company :</div>
            <div className="infodetails">
              {selectedbet ? selectedbet.toUpperCase() : ""}
            </div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Customer Name :</div>
            <div className="infodetails">{payload.name}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">User ID :</div>
            <div className="infodetails">{payload.accountNumber}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Amount :</div>
            <div className="infodetails">&#8358; {amount}</div>
          </div>
        </div>
      </Modal.Body>

      <center className="info">{responspayload}</center>
      <div className="d-flex ddd">
        <PaystackButton {...componentProps} />

          <button
            variant="danger"
            onClick={handleClose}
            className=" ms-1 w-50 cnsnddd"
            type="submit"
          >
            Back
          </button>
      </div>
    </Modal>
    <div className="allcablesyns">
      <div className="allcableitems">
        <div className="cableallre">
          <div className="imagesetuib">
            <img src={image} alt="" />
          </div>
          <div className="productsed">
            <h2>
              <center className="text-dark">Fund Betting Wallet</center>
            </h2>
            <Form.Label className="text-dark my-3">Betting</Form.Label>
            <FloatingLabel
              controlId="floatingSelect"
            
            >
              <Form.Select
                aria-label="Floating label select example"
                onChange={handlegetimage}
              >
                <option value="">Selecting Bet</option>
                {datatable.map((e) => {
                  return (
                    <option value={e.serviceType}>{e.serviceName}</option>
                  );
                })}
              </Form.Select>
            </FloatingLabel>
            <div className="optionshhd">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-dark my-3">
                  Enter User ID/Account No.
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(event) => SetuserID(event.target.value)}
                  placeholder="Enter User ID/Account No."
                />
                <Form.Text className="text-muted">
                  Verification Required.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-dark my-2">Amount</Form.Label>
                <Form.Control
                  type="tel"
                  onChange={(event) => {
                    setamount(event.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-dark my-2">
                  Phone Number
                </Form.Label>
                <Form.Control
                  type="tel"
                  onChange={(event) => {
                    setphone(event.target.value);
                  }}
                />
              </Form.Group>
              <Button
                variant={loading ? "warning" : "primary"}
                disabled={loading}
                onClick={handleverifier}
                className="my-2 w-100"
                type="submit"
              >
                {loading ? (
                  <>
                    {" "}
                    <Spinner animation="border" size="sm" /> {responspayload}
                  </>
                ) : (
                  "Verifying User"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Cabletvsubescription;
