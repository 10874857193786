import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Loading from "../../../Loading";
import { getuser } from "../../../Service/GetUser.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "bootstrap";
import "./Admin.css"

function PinSetupvlaue() {
  const id = useParams().id;
  const [user, setuser] = useState([]);
  const [newpin, setnewPin] = useState("");
  const [confirmpin, setconfirmPin] = useState("");
  const [pin, setvaluepin] = useState("");
  const [loading, setloading] = useState(false);
  const [response, setresponse] = useState("");

  useEffect(() => {
    getuser(id, (user) => {
      
      if (!user) {
        return (
          <>
            <Loading />
          </>
        );
      }

      if (
        user.permissions !== "admin" ||
        user.isAllowed === false ||
        user.isBlock === true
      ) {
        window.location.href = "/login";
        return;
      }
      setuser(user);
    });
  }, []);

  const handlechangepin = (e) => {
    e.preventDefault();
    setresponse("Please enter your unique Key");
    if (newpin !== confirmpin) {
      toast.error("Pin Mismatch", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (newpin.length !== 4) {
      toast.error("Required Length  is 4", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (newpin === "" || pin === "") {
      toast.error("Bad Request", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setloading(true);
    try {
      axios
        .post(
          `https://us-central1-mazuflex-app.cloudfunctions.net/getsetUser`,
          { newpin: newpin, id: user.id, oldpin: pin }
        )
        .then((e) => {
       ;
          setresponse("Please Wait");
          toast.error(e.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setresponse(e.data.message);

          if (e.data.code === 200){
            window.location.href = `/admin/auth/${user.id}`
          }
        })
        .catch((e) => {
          toast.error(e.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setloading(false)
        });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    
  };

  return (
    <div>
      <ToastContainer />
      <div className="pincontainer">
        <div className="pincntenr">
          <div className="inputconennr w-100">
          <div className="adminregisterheass">
                <center>
                  <img src="/img/logo.png" alt="" /> <br/>
                
                  <span>
                    Tell Mezu about yourself to access the admin pannel
                  </span>
                </center>
          </div>
            <Form.Group className="mb-3">
              <Form.Label>Enter Assign Pin</Form.Label>
              <Form.Control
                type="text"
                placeholder="Pin"
                onChange={(event) => {
                  setvaluepin(event.target.value);
                }}
              />
              <Form.Text className=" text-danger">
                Enter pin Assign to you by admin.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>New Pin</Form.Label>
              <Form.Control
                type="text"
                onChange={(event) => {
                  setnewPin(event.target.value);
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Confirm Pin</Form.Label>
              <Form.Control
                type="text"
                onChange={(event) => {
                  setconfirmPin(event.target.value);
                }}
              />
            </Form.Group>


            <Button
              variant={loading ? "primary" : "success"}  
              disabled={loading}
              className="w-100 my-2"
              onClick={handlechangepin}
            >
              {loading ? <Spinner animation="border" variant="warning" /> : "Change Pin"}
            </Button>

          </div>
        </div>
      </div>
    </div>
  );
}

export default PinSetupvlaue;
