import React, { useEffect } from 'react'
import Sidebar from './Component/ClientComponent/Sidemenuuser';
import './App.css'
import { Spinner } from 'react-bootstrap';



function CableTV() {

  return (
    <div  className='pageindexcloser' >
     <div className="allwraper">
     <div className="allitems" style={{backgroundImage: "url(/img/comic4.jpg)"}}>
     <div className="allsjuijd">
     <Sidebar/>
     </div>
      </div>
      <div className="allitemsksksnew">
        <div className="loaderjeader">
        <Spinner animation="border" variant="warning" />
        </div>
      </div>
     </div>
    </div>
  )
}

export default CableTV