import axios from "axios";
import React, { useState } from "react";
import { Form, Button, Row, Col, Modal, Spinner } from "react-bootstrap";
import { database } from "../../firebase";
import { getRandomString } from "../../Service/GetRandomNumber";
import firebase from "firebase";
import "./CreateUser.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";

function Registercomponent() {
  const [number, setnumber] = useState("");
  const [password, setpassword] = useState("");
  const [firstname, setfirst] = useState("");
  const [lastname, setLast] = useState("");
  const [confirmpassword, setcomfirmpassword] = useState("");
  const [email, setemail] = useState("");
  const CampURL =
    "https://us-central1-mazuflex-app.cloudfunctions.net/EmailHandlernew";
  const baseURL =
    "https://us-central1-mazuflex-app.cloudfunctions.net/creatuserhandle";
  const [checked, setChecked] = useState(false);
  const [loading, setloading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isuser,setisUser]= useState(false)
  const history = useHistory();

  const handlecheck = () => {
    handleShow()
    setChecked(!checked);
  };

  const CheckuserNumber = async(e)=>{
    const number = e.target.value
    setnumber(number)
    if (number.length === 11){
      setloading(true)
      await axios.get(`https://us-central1-mazuflex-app.cloudfunctions.net/verifyBvnUfitpay/${number}`).then((res)=>{
        const num =  res.data[0]
        if (num){
          toast.error("number user by another customer")
          setisUser(true)
          return
        }
        setisUser(false)

      })  

    }
    setloading(false)
  

  }
  const handleregister = async (e) => {
    e.preventDefault();

    if (isuser){
      toast.error("mobile number already use by another user")
      return
    }
    const id = database.collection("_").doc().id;
    const UniquieNum = getRandomString(7, "1234567890");
    const data = {
      id,
      UniquieNum,
      email,
      password,
      confirmpassword,
      number,
      firstname,
      lastname,
      wallet: 0,
      coin: 0,
      isAllowed: true,
      permissions: "user",
      created: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if (password !== confirmpassword) {
      toast.error("password Mismatch");
      return;
    }

    if (
      password === "" ||
      confirmpassword === "" ||
      firstname === "" ||
      lastname === "" ||
      email === "" ||
      number === ""
    ) {
      toast.error("required input value missing");
      return;
    }
    if (checked === false) {
      toast.error("Please accept our terms and conditions");
      return;
    }

    setloading(true);

   await axios
      .post(baseURL, { data: data })
      .then(async (e) => {
     
        toast.success(e.data.messeage, {
          position: toast.POSITION.TOP_RIGHT,
        });
        const url = `https://mezuflex.com/users/${e.data.uid}`;
        const logo = "https://i.ibb.co/NNsgkQy/logo3.png";
        const tittle = "Account Verification";
        const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
        <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
            style="font-family: 'Open Sans', sans-serif;">
            <tr>
                <td>
                    <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                        align="center" cellpadding="0" cellspacing="0">
                        <tr>
                            <td style="height:80px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="text-align:center;">
                              <a href="https://mezuflex.com" title="logo" target="_blank">
                                <img width="130" src=${logo} title="logo" alt="logo">
                              </a>
                            </td>
                        </tr>
                        <tr>
                            <td style="height:20px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                    style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                    <tr>
                                        <td style="height:40px;">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style="padding:0 35px;">
                                            <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Email Verification</h1>
                                            <span
                                                style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                            <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                                            <p>Hello ${firstname} ${lastname}!</p> 
                                            Kindly verify your email address before you can access your account. click on the button below to
                                            Verify your email address.
                                            </p>
                                            <p>Welcome to mezuflex family</p>
                                            <a href=${url}
                                                style="background:#20e277;text-decoration:none !important; font-weight:500; margin-top:35px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Verify Email</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="height:40px;">&nbsp;</td>
                                    </tr>
                                </table>
                            </td>
                        <tr>
                            <td style="height:20px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="text-align:center;">
                                <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>https://mezuflex.com</strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="height:80px;">&nbsp;</td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
       
  
  </div>`;
       
        await axios.post(CampURL, {
          content,
          logo,
          tittle,
          email,
          firstname,
          url,
          subject:"Account Verification"
        });
        setInterval(() => {
          history.push(`/login`);
        }, 4000);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setloading(true);
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article>
            <p>INTRODUCTION </p>

            Welcome to the Mezuflex.com.website (the “Site”).
            These terms and conditions (“Terms and Conditions”) apply to the
            Site, and all of its divisions, subsidiaries, and affiliate operated
            Internet sites which reference these Terms and Conditions. This
            website is owned and operated by Coletking concept. For the purpose
            of this website, “seller”, “we”, “us” and “our” all refer
            Mezuflex.com The Site reserves the right, to change, modify, add,
            or remove portions of both the Terms and Conditions of Use and the
            Terms and Conditions of Sale at any time. Changes will be effective
            when posted on the Site with no other notice provided. Please check
            these Terms and Conditions regularly for updates. 
            <br/> Your continued use
            of the Site following the posting of changes to these Terms and
            Conditions constitutes your acceptance of those changes. Kindly
            review the Terms and Conditions listed below diligently prior to
            using this website as your use of the website indicates your
            agreement to be wholly bound by its Terms and Conditions without
            modification. <br/>You agree that if you are unsure of the meaning of any
            part of these Terms and Conditions or have any questions regarding
            the Terms and Conditions, you will not hesitate to contact us for
            clarification. These Terms and Conditions fully govern the use of
            this website. No extrinsic evidence, whether oral or written, will
            be incorporated. <br/>
            
            <p>TERMS AND CONDITIONS OF USE</p> 
            
            Usage of Site You
            confirm that you are at least 18 years of age or are accessing the
            Site under the supervision of a parent or legal guardian. You
            confirm that you are a member by virtue of registration (click here
            to sign up if you are not a member); this will enable us to know our
            members. Both parties agree that this website may only be used in
            accordance with these Terms and Conditions of Use. If you do not
            agree with the Terms and Conditions of Use or do not wish to be
            bound by them, you agree to refrain from using this website. We
            grant you a non-transferable, revocable and non-exclusive license to
            use this Site, in accordance with the Terms and Conditions of Use,
            for such things as: shopping for personal items sold on the site,
            gathering prior information regarding our products and services and
            making purchases.<br/> Commercial use or use on behalf of any third party
            is prohibited, except as explicitly permitted by us in advance.
            These Terms and Conditions of Use specifically prohibit actions such
            as: accessing our servers or internal computer systems, interfering
            in any way with the functionality of this website, gathering or
            altering any underlying software code, infringing any intellectual
            property rights. This list is non-comprehensive and similar actions
            are also strictly prohibited. Any breach of these Terms and
            Conditions of Use shall result in the immediate rescission of the
            license granted in this paragraph without prior notice to you.
            Should we determine at our sole discretion that you are in breach of
            any of these conditions, we reserve the right to deny you access to
            this website and its contents and do so without parti pris to any
            available remedies at law or otherwise. <br/>Certain services and
            affiliated features that may be made available on the Site may
            require registration or subscription. Should you choose to register
            or subscribe for any such services or related features, you agree to
            provide accurate and current information about yourself, and to
            promptly update such information if there are any changes. Every
            user of the Site is exclusively responsible for keeping passwords
            and other account identifiers safe and secure. The account owner is
            entirely responsible for all activities that occur under such
            password or account. <br/>Furthermore, you must notify us of any
            unauthorized use of your password or account. The Site shall not be
            responsible or liable, directly or indirectly, in any way for any
            loss or damage of any kind incurred as a result of, or in connection
            with, your failure to comply with this section. During the
            registration process you agree to receive promotional emails from
            the Site. You can subsequently opt out of receiving such promotional
            e-mails by clicking on the link at the bottom of any promotional
            email. <br/>
            <p>USER COMPLIANCES</p>
            Anything that you submit to the Site and/or
            provide to us, including but not limited to, questions, reviews,
            comments, and suggestions (collectively “Submissions”) will become
            our sole and exclusive property and shall not be returned to you. In
            addition to the rights applicable to any Submission, when you post
            comments or reviews to the Site, you also grant us the right to use
            the name that you submit, in connection with such review, comment,
            or other content. You shall not use a false e-mail address, pretend
            to be someone other than yourself or otherwise mislead us or third
            parties as to the origin of any Submissions. We may, but shall not
            be obligated to, remove or edit any Submissions.  <br/>

            <p>INFORMATION AVAILABLE ON WEBSITE </p>
            
            You accept that the information contained in
            this website as provided is intended for information purposes only
            and that it is subject to change without notice. Although we take
            rational steps to ensure the accuracy of information and we believe
            the information to be reliable when posted, it should not be relied
            upon and it does not in any way constitute either a representation
            or a warranty or a guarantee. Product representations expressed on
            this Site are those of the vendor and are not made by us.
            Submissions or opinions expressed on this Site are those of the
            individual posting such content and may not reflect our opinions. <br/> We
            make no representations as to the merchantability of any products
            listed on our website, and we hereby disclaim all warranties,
            whether express or implied, as to the merchantability and/or fitness
            of the products listed on our website for any particular purpose. We
            shall not be held responsible or made liable for any damages or
            injury which may arise as a result of any error, omission,
            interruption, deletion, delay in operation or transmission, computer
            virus, communication failure and defect in the information, content,
            materials, software or other services included on or otherwise made
            available through our Website. We understand that certain state laws
            do not allow boundaries on implied warranties or limit of certain
            damages, these disclaimers may therefore not apply where these laws
            are applicable. <br/>

           <p> ACCESSIBILITY OF WEBSITE </p>

            Our aim is to ensure
            accessibility to the website at all times, however we make no
            representation of that nature and reserves the right to terminate
            the website at any time and without notice. You accept that service
            interruption may occur in order to allow for website improvements,
            scheduled maintenance or may also be due to outside factors beyond
            our control.  <br/>
            <p>LINKS AND THIRDS PARTY WEBSITES </p>
            We may include links to
            third party websites at any time. However, the existence of a link
            to another website should not be consider as an affiliation or a
            partnership with a third party or viewed as an endorsement of a
            particular website unless explicitly stated otherwise. In the event
            the user follows a link to another website, he or she does so at his
            or her own risk. We accept no responsibility for any content,
            including, but not limited to, information, products and services,
            available on third party websites. Creating a link to this website
            is strictly forbidden without our prior written consent.
            Furthermore, we reserve the right to revoke our consent without
            notice or justification. <br/>
            <p>INTELLECTUAL PROPERTY </p>
            Both parties agree
            that all intellectual property rights and database rights, whether
            registered or unregistered, in the Site, information content on the
            Site and all the website design, including, but not limited to,
            text, graphics, software, photos, video, music, sound, and their
            selection and arrangement, and all software compilations, underlying
            source code and software shall remain at all times vested in us or
            our licensors. Use of such material will only be permitted as
            expressly authorized by us or our licensors. <br/> Any unauthorised use of
            the material and content of this website is strictly prohibited and
            you agree not to, or facilitate any third party to, copy, reproduce,
            transmit, publish, display, distribute, commercially exploit or
            create derivative works of such material and content. <br/>
            <p>DATA
            PROTECTION </p>
            Any personal information collected in relation to the use
            of this website will be held and used in accordant with our Privacy
            Policy, which is available on our Site.  <br/>
            
            <p>INDEMNITY </p>
            
            You agree to
            indemnify and hold us, our affiliates, officers, directors, agents
            and/or employees, as the case may be, free from any claim or demand,
            including reasonable legal fees, related to your breach of these
            Terms of Use and User Agreement. <br/>
            
            <p>APPLICABLE LAW AND JURISDICTION</p>

            These Terms and Conditions of Use shall be interpreted and governed
            by the laws in force in the Federal Republic of Nigeria. Subject to
            the Arbitration section below, each party hereby agrees to submit to
            the jurisdiction of the courts of Nigeria and to waive any
            objections based upon venue. 
            
            <br/>
            <p>ARBITRATION </p>
            Any controversy, claim or
            dispute arising out of or relating to these Terms and Conditions of
            Use will be referred to and finally settled by private and
            confidential binding arbitration before a single arbitrator held in
            Nigeria in English and governed by Nigeria law pursuant to the
            Arbitration and Conciliation Act Cap A18 Laws of the Federation of
            Nigeria 2004, as amended, replaced or re-enacted from time to time.
            The arbitrator shall be a person who is legally trained and who has
            experience in the information technology field in Nigeria and is
            independent of either party. Notwithstanding the foregoing, the Site
            reserves the right to pursue the protection of intellectual property
            rights and confidential information through injunctive or other
            equitable relief through the courts. <br/>
             <p>TERMINATION </p>
             
             In addition to any
            other legal or equitable remedies, we may, without prior notice to
            you, immediately terminate the Terms and Conditions of Use or revoke
            any or all of your rights granted under the Terms and Conditions of
            Use. Upon any termination of this Agreement, you shall immediately
            cease all access to and use of the Site and we shall, in addition to
            any other legal or equitable remedies, immediately revoke all
            password(s) and account identification issued to you and deny your
            access to and use of this Site in whole or in part. Any termination
            of this agreement shall not affect the respective rights and
            obligations (including without limitation, payment obligations) of
            the parties arising before the date of termination. You furthermore
            agree that the Site shall not be liable to you or to any other
            person as a result of any such suspension or termination. If you are
            dissatisfied with the Site or with any terms, conditions, rules,
            policies, guidelines, or practices of E-Cart Internet Services
            Nigeria Limited in operating the Site, your sole and exclusive
            remedy is to discontinue using the Site. 

           <p> SEVERABILITY </p>
            
            If any portion
            of these terms or conditions is held by any court or tribunal to be
            invalid or unenforceable, either in whole or in part, then that part
            shall be severed from these Terms and Conditions of Use and shall
            not affect the validity or enforceability of any other section
            listed in this document. 
           <p> MISCELLANUOUS PROVISIONS </p>
            You agree that all
            agreements, notices, disclosures and other communications that we
            provide to you electronically satisfy any legal requirement that
            such communications be in writing. Assigning or sub-contracting any
            of your rights or obligations under these Terms and Conditions of
            Use to any third party is prohibited unless agreed upon in writing
            by the seller. We reserve the right to transfer, assign or
            sub-contract the benefit of the whole or part of any rights or
            obligations under these Terms and Conditions of Use to any third
            party. 
            
           <p> TERMS AND CONDITIONS OF SALE </p>
            
            General You confirm that you are
            at least 18 years of age or are accessing the Site under the
            supervision of a parent or legal guardian. You agree that if you are
            unsure of the meaning of any part of the Terms and Conditions of
            Sale, you will not hesitate to contact us for clarification prior to
            making a purchase. These Terms and Conditions of Sale fully govern
            the sale of products and services purchased on this Site. No
            extrinsic evidence, whether oral or written, will be incorporated.
            Formation of Contract Both parties agree that browsing the website
            and gathering information regarding the services provided by the
            seller does not constitute an offer to sell, but merely an
            invitation to treat. <br/>The parties accept that an offer is only made
            once you have logged into your account created by you (the user),
            selected the item you intend to purchase, chosen your preferred
            payment method, proceeded to payment and completed the payment
            process. Both parties agree that the acceptance of the offer is not
            made when the seller contacts you by phone or by email to confirm
            that the order has been placed online. Your offer is only accepted
            when we dispatch the product to you and inform you either by email
            or by phone of the dispatch of your ordered product. Before your
            order is confirmed, you may be asked to provide additional
            verifications or information, including but not limited to phone
            number and address, before we accept the order. Please note that
            there are cases when an order cannot be processed for various
            reasons. <br/>The Site reserves the right to refuse or cancel any order
            for any reason at any given time. Acceptance of Electronic Documents
            You agree that all agreements, notices, disclosures and other
            communications that we provide to you electronically satisfy any
            legal requirement that such communications be in writing. Payment
            and Pricing We are determined to provide the most accurate pricing
            information on the Site to our users; however, errors may still
            occur, such as cases when the price of an item is not displayed
            correctly on the website. As such, we reserve the right to refuse or
            cancel any order. In the event that an item is mispriced, we may, at
            our own discretion, either contact you for instructions or cancel
            your order and notify you of such cancellation. We shall have the
            right to refuse or cancel any such orders whether or not the order
            has been confirmed and your credit/debit card charged. In the event
            that we are unable to provide the services, we will inform you of
            this as soon as possible. <br/>A full refund will be given where you have
            already paid for the products. Feel free to check our payments
            methods here. Use of Voucher Codes Our Site accepts the use of
            voucher codes for orders placed online. The marketing voucher codes
            which are accepted on our Site entitle you at the time of ordering a
            product to a saving on the order being placed on our Site. Vouchers
            may also be issued to customers in exchange for advance payments
            made to us via transfer to our bank accounts for products intended
            to be purchased on the Site. Our voucher codes may not be exchanged
            for cash. With the exception of vouchers issued in accordance with
            our refunds policy and vouchers issued in exchange for advance
            payments, we reserve the right to cancel or withdraw our voucher
            codes at any time. Delivery This Site is only for delivery of
            products to customers within Nigeria. We make every effort to
            deliver goods within the estimated timescales set out on our Site;
            however, delays are occasionally inevitable due to unforeseen
            factors. We shall be under no liability for any delay or failure to
            deliver the products within the estimated timescales where they did
            not occur due to our fault or negligence. Indemnity You agree to
            indemnify us, our affiliates, officers, directors, agents and/or
            employees, as the case may be, free from any claim or demand,
            including reasonable legal fees, related to your breach of these
            Terms and Conditions of Sale. <br/>Applicable Law and Jurisdiction These
            Terms and Conditions of Sale shall be interpreted and governed by
            the laws in force in the Federal Republic of Nigeria. Subject to the
            Arbitration section below, each party hereby agrees to submit to the
            jurisdiction of the courts of Nigeria and to waive any objections
            based upon venue. Arbitration Any controversy, claim or dispute
            arising out of or relating to these Terms and Conditions of Sale
            will be referred to and finally settled by private and confidential
            binding arbitration before a single arbitrator held in Nigeria in
            English and governed by Nigeria law pursuant to the Arbitration and
            Conciliation Act Cap A18 Laws of the Federation of Nigeria 2004, as
            amended, replaced or re-enacted from time to time. The arbitrator
            shall be a person who is legally trained and who has experience in
            the information technology field in Nigeria and is independent of
            either party. Notwithstanding the foregoing, the Site reserves the
            right to pursue the protection of intellectual property rights and
            confidential information through injunctive or other equitable
            relief through the courts. Severability If any portion of these
            Terms or Conditions of Sale is held by any court or tribunal to be
            invalid or unenforceable, either in whole or in part, then that part
            shall be severed from these Terms and Conditions of Sale and shall
            not affect the validity or enforceability of any other section
            listed in this document. <br/>Miscellaneous Provisions You agree that all
            agreements, notices, disclosures and other communications that we
            provide to you electronically satisfy any legal requirement that
            such communications be in writing. Assigning or sub-contracting any
            of your rights or obligations under these Terms and Conditions of
            Sale to any third party is prohibited unless agreed upon in writing
            by the seller. We reserve the right to transfer, assign or
            sub-contract the benefit of the whole or part of any rights or
            obligations under these Terms and Conditions of Sale to any third
            party. Notice of Copyright Infringement If you have any complaints
            with respect to the infringement of your copyright, kindly write to
            the following address: Coletking concept, off Panaf Drive, Dawaki Express Road,  FCT-Nigeria. Who? Where? Where you believe
            that your intellectual property has been infringed upon on our
            website, please notify us by email to (insert physical address and
            email address for copyright complaints). We expeditiously respond to
            all concerns regarding copyright infringements. We request that you
            provide the following information along with your complaint: A
            physical or electronic signature of the person authorized to act on
            behalf of the owner of the copyrighted work for the purposes of the
            complaint. A proper description of the copyrighted work claimed to
            have been infringed. <br/>A description of the location of the infringing
            material on our Website. The address, telephone number or e-mail
            address of the complaining party. A statement made by the
            complaining party that he has a good-faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent or by law. A statement deposed to under
            oath, that the information in the notice of copyright infringement
            is accurate, and that the complaining party is authorized to act on
            behalf of the copyright owner. Please note that this procedure is
            exclusively for notifying Coleking Concept, that your copyrighted
            material has been infringed.
          </article>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            I Accept
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="registrationrapper">
        <div className="rgistercontern">
          <div className="registerheass">
            <center>
              <img src="/img/logo.png" alt="" />
              <h1>Create account</h1>
              <span>Fill the form below to create a new account for free</span>
            </center>
          </div>

          <Form>
            <Row>
              <Col>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  onChange={(event) => {
                    setfirst(event.target.value);
                  }}
                />
              </Col>
              <Col>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  onChange={(event) => {
                    setLast(event.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  required
                  onChange={(event) => {
                    setemail(event.target.value);
                  }}
                />
              </Col>
              <Col>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="tel"
                  required
                  onChange={CheckuserNumber}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  required
                  onChange={(event) => {
                    setpassword(event.target.value);
                  }}
                />
              </Col>
              <Col>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  required
                  onChange={(event) => {
                    setcomfirmpassword(event.target.value);
                  }}
                />
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Read our terms and condition"
                onChange={handlecheck}
                required
              />
            </Form.Group>
            <Button
              variant={loading ? "warning" : "primary"}
              disabled={loading}
              onClick={handleregister}
              className="w-100"
              type="submit"
            >
             {loading ? <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        /> : "Register"} 
            </Button>
            <center>
              <p>
                Already have an account{" "}
                <Link to="/login" className="text-white jsjsiaiaia">
                  Login
                </Link>
              </p>
            </center>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Registercomponent;
