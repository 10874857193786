import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAdminnotification, getEod, getMonthlyReport, getweekly } from "../../../Service/GetUser.service";
import { formatNumber } from "../../../Service/Utility";
import axios from "axios";
import { database } from "../../../firebase";
import { useAuth } from "../../../Service/UserAuth";
import "./AgentDash.css"

function PaymentNotification() {
  const [paymentnotification, setpaymentnotification] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [amount, setamount] = useState(0);
  const [loading, setloading] = useState(false);
  const [respons, setresposne] = useState("");
  const { loginuser, userwallet } = useAuth();
  const [wallet, setwallet] =  useState([])
  const [viewPoint, setViewPoint] = useState("Daily");
  const topUrl = "https://us-central1-mazuflex-app.cloudfunctions.net/getTopUp";

  const getuserWallet = async()=>{
    await axios.get("https://us-central1-mazuflex-app.cloudfunctions.net/getuserwallet").then((result)=>{
        setwallet(result.data)
        
    })
  }
  useEffect(() => {
    if (viewPoint === "Daily") {
      getEod((result) => {
        setpaymentnotification(result);
      });
    } else if (viewPoint === "Weekly") {
        getweekly((result) => {
        setpaymentnotification(result);
      });
    } else if (viewPoint === "Monthly") {
        getMonthlyReport((result) => {
        setpaymentnotification(result);
      });
    }

    getuserWallet()
    
  }, [viewPoint]);

  let sumtusercomm = wallet.reduce(function(a, b){
    return a + b.coin;
  },0);


  const Togglemodal = useCallback(
    (item) => () => {
      if (item.isApproved === true) {
        setresposne("already treated");
        return;
      }
      setamount(item);
      handleShow();
    },
    []
  );

  const handleTop = async (e) => {
    e.preventDefault();
    setloading(true);
    setresposne("Authentication");

    await axios
      .post(topUrl, {
        userID: loginuser.id,
        id: amount.userId,
        amount: parseFloat(amount.amount),
      })
      .then(async (e) => {
        setresposne(e.data.message);
        database
          .doc(`/PaymentNotification/${amount.id}`)
          .update({
            isApproved: true,
          })
          .then((e) => {
            
          });
        setloading(false);
      });
    setloading(false);
  };

  return (
    <div>
      <Link to={`/admin/auth/${loginuser.id}`}>
        <div className="headeradmin">Back To Dashboard</div>
      </Link>

      <div className="headersectinff">
        <div className="headconteineimcine">
          <div className="incomestatmain">
            <h1>No. Trasaction</h1>
            <p>{formatNumber(userwallet.alltimeTransaction)}</p>
          </div>

          <div className="incomestatmain">
            <h1>Total Earning</h1>
            <p>{formatNumber(userwallet.Totalcoin)}</p>
          </div>
          <div className="incomestatmain">
            <h1>Total Earning On users</h1>
            <p>{formatNumber(sumtusercomm)}</p>
          </div>
          <div className="incomestatmain">
            <Form.Select
              aria-label="Default select example"
              defaultValue={viewPoint}
              onChange={(event) => {
                setViewPoint(event.target.value);
              }}
            >
              <option value="">Open this select menu</option>
              <option value="Daily">Daily Report</option>
              <option value="Weekly">Weekly Report</option>
              <option value="Monthly">Monthly Report</option>
            </Form.Select>
          </div>
        </div>
      </div>
      <div
        className="summarysection p-2"
        style={{ display: viewPoint === "" ? "none" : "block" }}
      >
        <div
          className="alltgereoirt"
          style={{ display: viewPoint === "Daily" ? "block" : "none" }}
        >
          <div className="warrheasderadmin">
            <h6>Daily Log</h6>
    
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S/N</th>
                <th className="hide">Date</th>
                <th>Total Coin</th>
                <th>coin &#8358;</th>
                <th>Status</th>
                <th>No Tran</th>
              </tr>
            </thead>
            <tbody>
              {paymentnotification.map((value, id) => {
             

                return (
                  <tr key={id}>
                    <td>{id + 1}</td>
                    <td className="hide">
                      {value.created
                        ? format(value.created, "dd/MM/yyyy hh:mm a")
                        : ""}
                    </td>
                    <td>{formatNumber(value.Totalcoin)}</td>
                    <td>{formatNumber(value.coin)}</td>
                    <td>
                      {value.status}
                    </td>
                    <td>
                      {value.NotodayTransaction}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div
          className="alltgereoirt"
          style={{ display: viewPoint === "Weekly" ? "block" : "none" }}
        >
          <div className="warrheasderadmin">
            <h6>Recent Funding Request</h6>
            <Link to="/fund/request">
              <p>View More</p>
            </Link>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S/N</th>
                <th className="hide">Date</th>
                <th>Total Coin</th>
                <th>coin &#8358;</th>
                <th>Status</th>
                <th>No Tran</th>
              </tr>
            </thead>
            <tbody>
              {paymentnotification.map((value, id) => {
           

                return (
                  <tr key={id}>
                    <td>{id + 1}</td>
                    <td className="hide">
                      {value.created
                        ? format(value.created, "dd/MM/yyyy hh:mm a")
                        : ""}
                    </td>
                    <td>{formatNumber(value.Totalcoin)}</td>
                    <td>{formatNumber(value.coin)}</td>
                    <td>
                      {value.status}
                    </td>
                    <td>
                      {value.NotodayTransaction}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div
          className="alltgereoirt"
          style={{ display: viewPoint === "Monthly" ? "block" : "none" }}
        >
          <div className="warrheasderadmin">
            <h6>Recent Funding Request</h6>
            <Link to="/fund/request">
              <p>View More</p>
            </Link>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S/N</th>
                <th className="hide">Date</th>
                <th>Total Coin</th>
                <th>coin &#8358;</th>
                <th>Status</th>
                <th>No Tran</th>
              </tr>
            </thead>
            <tbody>
              {paymentnotification.map((value, id) => {
                

                return (
                  <tr key={id}>
                    <td>{id + 1}</td>
                    <td className="hide">
                      {value.created
                        ? format(value.created, "dd/MM/yyyy hh:mm a")
                        : ""}
                    </td>
                    <td>{formatNumber(value.Totalcoin)}</td>
                    <td>{formatNumber(value.coin)}</td>
                    <td>
                      {value.status}
                    </td>
                    <td>
                      {value.NotodayTransaction}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default PaymentNotification;
