import React from "react";
import Sidebar from "../Component/ClientComponent/Sidemenuuser";
import "../App.css";
import { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../Service/UserAuth";
import firebase from "firebase";
import { database } from "../firebase";

// import Bank from "../bank.json";

function Paymentnotify() {
    const [loading, setloading] =  useState(false)
    const [bank, setbank]=  useState("")
    const [amount, setamount] =  useState(0)
    const {loginuser} = useAuth()
    const [depositor, setdepositor] = useState("")



    const handlesend = async(e)=>{
 e.preventDefault()

 if (bank === "" || amount === 0 || depositor === ""){
  toast.error("Input required", {
    position: toast.POSITION.TOP_RIGHT,
  });
  return
 }

 if (amount <500){
  toast.error("mininum deposit amount 500", {
    position: toast.POSITION.TOP_RIGHT,
  });
  return
 }

 setloading(true)

 try {
  const ref = database.collection("_").doc().id;
 const data = {
    id:ref,
    amount: parseFloat(amount),
    depositor,
    bank,
    userId: loginuser.id,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    isApproved:false
  }

  const notify = {
    id:ref,
    tittle: "wallet funding",
    message: "Please verify user request and act on it",
    usermessage: "Payment notification received, waiting confirmation",
    created: firebase.firestore.FieldValue.serverTimestamp(),
    isRead: false,
    userId: loginuser.id,
  }

  const promises = [
    database.collection("PaymentNotification").doc(data.id).set(data),
    database.collection("notify").doc(notify.id).set(notify)
  ]
  await Promise.all(promises).then(()=>{
    toast.success("Notification sent successfully ", {
      position: toast.POSITION.TOP_RIGHT,
    });
  })

 } catch (error) {
  toast.error(error.message, {
    position: toast.POSITION.TOP_RIGHT,
  });
 }
 setloading(false)
}

  
 
  return (
    <div className="pageindexcloser">
       <ToastContainer />
      <div className="allwraper">
        <div
          className="allitems"
          style={{ backgroundImage: "url(/img/comic4.jpg)" }}
        >
          <div className="allsjuijd">
            <Sidebar />
          </div>
        </div>
        <div className="allitemsksksnew">
          <div className="paymentnotification">
            <div className="notificationcontenr">
              <Form>
                <Form.Group className="mb-3" >
                  <Form.Label>Depositor Name</Form.Label>
                  <Form.Control type="text" onChange={(event)=>{
                    setdepositor(event.target.value)
                  }}/>
                  <Form.Text className="text-danger">
                  Account name of sender
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" >
                  <Form.Label>Bank</Form.Label>
                  <Form.Control type="text" onChange={(event)=>{
                    setbank(event.target.value)
                  }}/>
                </Form.Group>


                <Form.Group className="mb-3">
                  <Form.Label>amount</Form.Label>
                  <Form.Control type="tel" onChange={(event)=>{
                    setamount(event.target.value)
                  }}/>
                </Form.Group>

                <Button
                  variant={loading ? "warning" : "primary"}
                  disabled={loading}
                  onClick={handlesend}
                  className="w-100"
                  type="submit"
                >
                {loading ?  <Spinner animation="grow" variant="primary" /> : "Send Notification"}  
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Paymentnotify;
