import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import "../Servicescomponent/Services.css";

import { PaystackButton } from "react-paystack";
import {
  Button,
  Form,

  Modal,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../Service/UserAuth";
import { database } from "../../firebase";
import { formatNumber } from "../../Service/Utility";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import { functions } from "../../firebase";
import { format } from "date-fns";
import Inloader from "../../Inloader"
const walleturl =
"https://us-central1-mazuflex-app.cloudfunctions.net/getuserwallet";


const finderurl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/Getcablenamefinder";
const curl =
  "https://us-central1-mazuflex-app.cloudfunctions.net/CustomerInformation";
const url =
  "https://us-central1-mazuflex-app.cloudfunctions.net/GetcableTVProvider";

function Cabletvsubescription() {
    const history = useHistory();
    const [provider, setprovider] = useState([]);
    const { unknow } = useAuth();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [arrangeTken , setarrangeToken] = useState("")
    const [showpayload, setShowpayload] = useState(false);
    const handleClosepayload = () => {
      setShowpayload(false);
      window.location.href ="/v2/electricity";
    };
    const handleShowpayload = () => setShowpayload(true);
    const [payload, setpayload] = useState([]);
    const [amountvalue, setamount] = useState(100);
    const [loading, setloading] = useState(false);
    const [phonea, setphone] = useState("");
    const [meternumber, setmeternumber] = useState("");
    const [act, setact] = useState("");
    const { userwallet } = useAuth();
    const [status, setstatus] =  useState ("")
    const [powerpayload, setpowerpayload] = useState("");
    const [errorpayload, seterrorpowerpayload]  =  useState("")
    const [response, setresponse] = useState("");
    const [awaiting, setwaiting] = useState(false)
    const [disable, setdisable]= useState(false)
    const [selectedcable, setpowerselected] = useState("");
    const [image, setimage] = useState(
      "https://i.ibb.co/HgkgGnh/images-removebg-preview.png"
    );
  
    const finderurl =
      "https://us-central1-mazuflex-app.cloudfunctions.net/Getnamefinder";
    const [selectdpower, setselectedpower] = useState();
    const baseURL =
      "https://us-central1-mazuflex-app.cloudfunctions.net/getpower";

    const buypowerUrl =
      "https://us-central1-mazuflex-app.cloudfunctions.net/buyPowerHandler";

    
    useEffect(() => {
      history.push('/login')
      axios.get(baseURL).then((response) => {
        if (response) {
          setprovider(response.data.data);
        }
      });
  
      const selectpowerobject = provider.find(
        (item) => item.service_type === selectedcable
      );
      setselectedpower(selectpowerobject);
    }, [selectedcable, meternumber, response]);

    const amount = parseFloat(amountvalue) +100 ;

  
    const handlechnage = (e) => {
      const selectedpower = e.target.value;
      setpowerselected(selectedpower);
      if (selectedpower === "abuja_electric_prepaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/3.jpg");
      } else if (selectedpower === "abuja_electric_postpaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/3.jpg");
      } else if (selectedpower === "jos_electric_prepaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/6.jpg");
      } else if (selectedpower === "jos_electric_prepaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/6.jpg");
      } else if (selectedpower === "eko_electric_postpaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/1.jpg");
      } else if (selectedpower === "eko_electric_prepaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/1.jpg");
      } else if (selectedpower === "ikeja_electric_prepaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/2.jpg");
      } else if (selectedpower === "ikeja_electric_postpaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/2.jpg");
      } else if (selectedpower === "kedco_electric_prepaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/4.jpg");
      } else if (selectedpower === "kedco_electric_postpaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/4.jpg");
      } else if (selectedpower === "portharcourt_electric_prepaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/5.jpg");
      } else if (selectedpower === "portharcourt_electric_postpaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/5.jpg");
      } else if (selectedpower === "kaduna_electric_prepaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/8.jpg");
      } else if (selectedpower === "kaduna_electric_postpaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/8.jpg");
      } else if (selectedpower === "ibadan_electric_prepaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/7.jpg");
      } else if (selectedpower === "ibadan_electric_postpaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/7.jpg");
      } else if (selectedpower === "enugu_electric_prepaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/9.jpg");
      } else if (selectedpower === "enugu_electric_postpaid") {
        setimage("https://cexpress.com.ng/assets/images/electricity/9.jpg");
      } else {
        setimage(
          "https://i.ibb.co/HgkgGnh/images-removebg-preview.png"
        );
      }
    };
    const date =   format(new Date(), "dd/MM/yyyy hh:mm a")
    const phone = phonea 

    const config = {
      reference: new Date().getTime().toString(),
      email: `mezuflex@gmail.com`,
      amount: amount * 100,
      publicKey: "pk_live_7c49d4df21fc6207a1ece0514092b34ad3f5bbee",
    };
    
    const handlePaystackSuccessAction = async (data) => {
    
      const verifyPaymentHandler = functions.httpsCallable(
        "verifyPaystackPayment"
      );
      const value = {
        ref: data.reference,
        status: data.success,
        firstname: "Customer",
        lastname: "Mezuflex",
        email: "juliuskingsley1100@gmail.com",
        userId: "iu4WWdVD3GeRe9373RpDm25zN4M2",
        phone: "08165459398",
        amount: amount,
      };
      setwaiting(true)
    await verifyPaymentHandler(value).then(async (result) => {
     
      if (result === null || result === undefined) {
        return (
          <>
            <Inloader />
          </>
        );
      }
      if (result.data.data.status === "success") {

        const selectedprovider = selectdpower.service_type;
        const ref = database.collection("_").doc().id;
     
      const url = "https://us-central1-mazuflex-app.cloudfunctions.net/SmsHandlernew"
    
       const data1 = {
          id : ref,
          company : selectedprovider, 
          customername: payload.name,
          address: "no value",
          userID : "iu4WWdVD3GeRe9373RpDm25zN4M2",
          AccountNumber : meternumber,
          walletbalance : userwallet.wallet,
          token : "no value", 
          debt : "no value", 
          amount: amount, 
          phone: phone,
          type : "Power", 
          errorpayload,
          status : "Network Error",
          created: firebase.firestore.FieldValue.serverTimestamp()
        }
        // make the payment for the user
        await axios
              .post(buypowerUrl, {
                service_type: selectedprovider,
                account_number: meternumber,
                amount: amountvalue,
                phone: phone,
                ref: ref,
              })
              .then(async (e) => {  
                setdisable(true)
                const raw = e.data.data;
                if (e.data.status === "success") {
                  handleClose();
                  setpowerpayload(e.data.data);
                  setstatus("success")
                  handleShowpayload();
                  const token = raw.creditToken
                  let chunks = [];
                  for(let i = 0; i < token.length; i+=4){
                    chunks.push(token.substr(i,4));
                  }
                  const arranToken =  chunks.join("-");
                  setarrangeToken(arranToken)
                  const data = {
                    id : ref,
                    company : selectedprovider, 
                    customername: payload.name,
                    address: payload.address,
                    userID : unknow.id,
                    AccountNumber: meternumber,
                    unit : raw.amountOfPower,
                    address : payload.address,
                    walletbalance : userwallet.wallet,
                    token : arranToken, 
                    debt : raw.rawOutput.debtAmount, 
                    amount: amount, 
                    phone: phone,
                    isToday:true,
                    status : "success",
                    payloadres:e.data.data,
                    created: firebase.firestore.FieldValue.serverTimestamp()
                  }   
                  await database.collection("Transaction").doc(data.id).set(data)
const message = `Dear Customer!! 
Your ${selectdpower.service_type} Token: ${powerpayload.creditToken }. 
Thank you for choosen Mezuflex`;
                  await axios.post(url, {message:message, recipient:phone})
                }else{
                  handleClose();
                  seterrorpowerpayload(e.data.data);
                  setstatus("error")
                  handleShowpayload();
                  await database.collection("Transaction").doc(data1.id).set(data1)
                }
                setdisable(false)
              })
              .catch((e) => {
                toast.error(e.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
        // Setpaymentpayload(result.data.data);
        // setshowvalue(true);
        handleClose();
      } else {
        // Setpayload(result.data.data);
        handleClose();
        handleShowpayload();
      }
    }).catch((e)=>{
      toast.error(e.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    });
  
  };

  const handlePaystackCloseAction = () => {
    history.goBack();
  };

  const componentProps = {
    ...config,
    text: loading ? "Proccessing request" : "Pay with Paystack",
    onSuccess: (data) => handlePaystackSuccessAction(data),
    onClose: () => handlePaystackCloseAction(),
  };


  const getmeterinfmation = async (e) => {
    e.preventDefault();

    if (!selectdpower || meternumber === " ") {
      toast.error("BAD FORMAT");
      return;
    }

    if (phonea === "" || amountvalue < 500){
    toast.error("Mobile Number not provider or Value less than required amount");
      return;
    }
    setloading(true);
    const selectedprovider = selectdpower.service_type;
    try {
      await axios
        .post(finderurl, {
          service_type: selectedprovider,
          account_number: meternumber,
        })
        .then((e) => {
          setpayload(e.data.data.user);
          setresponse("Everthing Looks Good 🤗");
          handleShow();
        })
        .catch((e) => {
          setpayload(e.message);
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      toast.error(e.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setloading(false);
  };


 

  return (
    <div>
    <ToastContainer />

    <Modal
      centered
      backdrop="static"
      show={showpayload}
      onHide={handleClosepayload}
      animation={false}
    >
      <Modal.Body>
        {status === "error" ?  <>

          <div className="headersjdjd bg-danger">
          <h1>ERROR</h1>
        </div>
        <p>Transaction Details</p>
        <div className="infocontainer">
          <div className="inforcontenr">
            <div className="infotittle">Company :</div>
            <div className="infodetails">
              {selectdpower ? selectdpower.service_type : ""}
            </div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Meter Number :</div>
            <div className="infodetails">{payload.accountNumber}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Customer Name :</div>
            <div className="infodetails">{payload.name}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Address :</div>
            <div className="infodetails">{payload.address}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Amount :</div>
            <div className="infodetails">&#8358; {formatNumber(amount)}</div>
          </div>
          <div className="inforcontenr">
          <div className="infotittle">Transaction Time :</div>
          <div className="infodetails">{date}</div>
        </div>

          <div className="inforcontenr">
            <div className="infotittle">Message :</div>
            <div className="infodetails">
              Transaction Error, Please contact support
            </div>
          </div>
          <div className="d-flex">
     <Button variant="danger" className="w-100 m-1 " disabled={disable} type="submit" onClick={handleClosepayload}>
        back
      </Button>

      <Button className="w-100 m-1" type="submit" disabled={disable}>
        Print
      </Button>
     </div>
        </div>
        </>  : <>
        <div className="headersjdjd bg-success">
          <h1>SUCCESS</h1>
        </div>
        <p>Transaction Details</p>
        <div className="infocontainer">
          <div className="inforcontenr">
            <div className="infotittle">Company :</div>
            <div className="infodetails">
              {selectdpower ? selectdpower.service_type : ""}
            </div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Meter Number :</div>
            <div className="infodetails">{payload.accountNumber}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Token :</div>
            <div className="infodetails">{arrangeTken}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Unit :</div>
            <div className="infodetails">{powerpayload.amountOfPower}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Debt Amount :</div>
            <div className="infodetails">
              {powerpayload ? powerpayload.rawOutput.debtAmount : ""}
            </div>
          </div>

           <div className="inforcontenr">
            <div className="infotittle">Tariff :</div>
            <div className="infodetails">
              {powerpayload ? powerpayload.rawOutput.tariff : ""}
            </div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Customer Name :</div>
            <div className="infodetails">{payload.name}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Address :</div>
            <div className="infodetails">{payload.address}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Amount :</div>
            <div className="infodetails">&#8358; {formatNumber(amount)}</div>
          </div>

          <div className="inforcontenr">
          <div className="infotittle">Transaction Time :</div>
          <div className="infodetails">{date}</div>
        </div>

          <div className="inforcontenr">
            <div className="infotittle">Message :</div>
            <div className="infodetails">
              Thanks for using mezuflex. vend with us next time.
            </div>
          </div>
     <div className="d-flex">
     <Button className="w-100 m-1" type="submit"  onClick={handleClosepayload}>
        back
      </Button>

      <Button className="w-100 m-1" type="submit">
        Print
      </Button>
     </div>
        </div>
        </>
}
      </Modal.Body>
    
    </Modal>

    <Modal
      centered
      backdrop="static"
      show={show}
      onHide={handleClose}
      animation={false}
    >
      <Modal.Body>
        <div className="headersjdjd">
        <h1>In-Progress</h1>
          </div>
          <p>Kindly confirm the information below before you proceed</p>
        <div className="infocontainer">
          <div className="inforcontenr">
            <div className="infotittle">Company :</div>
            <div className="infodetails">
              {selectdpower ? selectdpower.service_type : ""}
            </div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Meter Number :</div>
            <div className="infodetails">{payload.accountNumber}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Customer Name :</div>
            <div className="infodetails">{payload.name}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Address :</div>
            <div className="infodetails">{payload.address}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Amount :</div>
            <div className="infodetails">&#8358; {formatNumber(amountvalue)}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">charge :</div>
            <div className="infodetails">&#8358; {formatNumber(100)}</div>
          </div>

          <div className="inforcontenr bg-info">
          <div className="infotittle"> Total Amount :</div>
          <div className="infodetails"> &#8358; {amount} </div>
        </div>
        <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">{date}</div>
            </div>

         
        </div>
      </Modal.Body>

      <center className="info">{response}</center>
      <center> {awaiting ? <Spinner animation="border" variant="warning" /> : ""}</center>
        <div className="d-flex ddd">
        <PaystackButton {...componentProps}  />

          <button
            variant="danger"
            onClick={handleClose}
            className=" ms-1 w-50 cnsnddd"
            type="submit"
          >
            Back
          </button>
        </div>
      
    </Modal>

    <div className="allcablesyns">
      <div className="allcableitems">
        <div className="cableallre">
          <div className="imagesetuib">
            <img src={image} alt="" />
          </div>
          <div className="productsed">
            <h2>
              <center className="text-dark">VEND ELECTRICITY</center>
            </h2>
            <Form.Label className="text-dark my-3">Electricity</Form.Label>
            <Form.Select
              required
              aria-label="Floating label select example"
              value={selectedcable}
              onChange={handlechnage}
            >
              <option key="">Please Select Provider</option>
              {provider
                ? provider.map((selectedcable) => (
                    <option key={selectedcable.service_type}>
                      {selectedcable.service_type}
                    </option>
                  ))
                : ""}
            </Form.Select>

            <div className="optionshhd">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-dark my-3">
                  Enter Meter Number
                </Form.Label>
                <Form.Control
                  type="tel"
                  onChange={(e) => {
                    setmeternumber(e.target.value);
                  }}
                  placeholder="Meter Number"
                  required
                />
                <Form.Text className="text-muted">
                  {act ? act : "Verification Required."}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-dark my-2">Amount</Form.Label>
                <Form.Control
                  type="tel"
                  onChange={(event) => {
                    setamount(event.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-dark my-2">
                  Phone Number
                </Form.Label>
                <Form.Control
              
                  type="tel"
                  onChange={(event) => {
                    setphone(event.target.value);
                  }}
                />
              </Form.Group>
              <Button
                variant={loading ? "warning" : "primary"}
                disabled={loading}
                onClick={getmeterinfmation}
                className="w-100 my-2"
                type="submit"
              >
                Verify Account
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Cabletvsubescription;
