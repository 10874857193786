import { database } from "../firebase";
import { TimestampDate } from "timestamp-date";

const timestampDate = new TimestampDate();



export const getuser = (id , cd) => {
  const ref = database.doc(`users/${id}`);
  ref.onSnapshot((queryproduct) => {
    const user = queryproduct.data();
   
   cd(user);
  });
};


export const getuserwaletBalance = (cd) => {
  const ref = database.collection("wallet");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate (item));
    });

    cd(items);

    
  });
};




export const getApiTransaction = (id, lastDoc) => {

  return new Promise((resolve) => {
    let reference = database.collection("ApiTransaction").orderBy("created", "desc");
   
    if (id) {
        reference = reference.where("userID", "==", id);
      }
  
      if (lastDoc) {
        reference = reference.startAfter(lastDoc.__doc);
      }
    reference
      .limit(10)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });

        resolve(items);
      });
  });
};


