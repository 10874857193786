import React from 'react'
import NavbarAdmin from "./NavbarAdmin"
import "./Log.css"
import { useHistory } from 'react-router-dom'

function Managementpage() {
  const history =  useHistory()

  const handleNeco = ()=>{
    history.push("/neco/create")
  }

  const GetissueReport = ()=>{
    history.push("/get/issue")
  }

  const HanndleINcome = ()=>{
    history.push("/get/income")
  }
  return (
    <div>
        <NavbarAdmin/>
        <div className="managementcontener">
          <div className="manageentcontenr">
            <div className="creatnecopic" onClick={GetissueReport}>
              Get Issue Report 
            </div>

            <div className="creatnecopic" onClick={handleNeco}>
              New Exam Entry
            </div>
            <div className="creatnecopic" onClick={HanndleINcome}>
              Income Data
            </div>
            <div className="creatnecopic">
              More
            </div>
          </div>
        </div>
    </div>
  )
}

export default Managementpage