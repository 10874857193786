import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import { TbRuler2Off } from "react-icons/tb";
import Navbar from "./NavbarAdmin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import "./Log.css"
import { timeStamptoDate } from "../../../Service/Utility";

function CreateNeco() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [token, settoken] =  useState("")
  const [loading, setloading] = useState(false)
  const [message, setmessage] = useState("")
  const [collections, setcollections]  = useState([])
  
  useEffect(() => {
  axios.get("https://us-central1-mazuflex-app.cloudfunctions.net/createNecoHandler").then((e)=>{
    setcollections(e.data)
  })
  }, [])
  
  
  const handleUpload = async(e)=>{
    e.preventDefault()
    const data = {
      Token : token
    }
    setloading(true)
    await axios.post("https://us-central1-mazuflex-app.cloudfunctions.net/createNecoHandler", { data: data }).then((e)=>{
      toast.success(e.data.messeage, {
        position: toast.POSITION.TOP_RIGHT,
      }); 
     
    })
    setloading(false)
    settoken("")
  }







  return (
    <div>
      <ToastContainer />
      <Navbar />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Enter Token</Form.Label>
            <Form.Control type="Enter Token" maxLength={12} onChange={(event) =>{
              settoken(event.target.value)
            }} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          {loading ?   <Button variant="success" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading...
      </Button> : <Button variant="success" onClick={handleUpload}>Upload</Button>}
          
        </Modal.Footer>
      </Modal>
      <div className="necoconainr">
        <div className="necondond">
          <Button variant="info" onClick={handleShow}>
            NEW NECO ENTRY
          </Button>
        </div>
        <div className="necotablecontenr">
          <div className="necorwowjdjd">
          <div className="showtransactions">
            <div className="transactionprijhse">
              {collections.length === 0 ? (
                <>
                  <center>
                    {" "}
                    <Spinner animation="border" variant="warning" /> Fetching
                    Data
                  </center>
                </>
              ) : (
                <Table striped="columns">
                  <thead>
                    <tr className="bg-white">
                      <th>S/N</th>
                      <th className="hide">Data/Time</th>
                      <th>Token</th>
                      <th>Amount</th>
                      <th>Status</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {collections.map((itemDoc, id) => {
                     const item = timeStamptoDate(itemDoc);
                   
                      return (
                        <tr
                          key={id}
                          className="bg-light cuspointer"
                          // onClick={Togglemodal(item)}
                        >
                          <td >{id + 1}</td>
                          <td className="hide">
                            {item.created
                              ? format(item.created, "dd/MM/yyyy hh:mm a")
                              : ""}
                          </td>
                          <td >{item.token.join(') ')}</td>
                          <td >{item.amount}</td>
                          <td >{item.status === false ? "Active"   :  "Sold"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}

            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNeco;
